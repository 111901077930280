import * as PopoverPrimitive from '@radix-ui/react-popover';
import { keyframes } from '@stitches/react';
import { styled } from '../../stitches.config';

const slideUpAndFadeIn = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideUpAndFadeOut = keyframes({
  '0%': { opacity: 1, transform: 'translateY(0)' },
  '100%': { opacity: 0, transform: 'translateY(2px)' },
});

const slideRightAndFadeIn = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideRightAndFadeOut = keyframes({
  '0%': { opacity: 1, transform: 'translateX(0)' },
  '100%': { opacity: 0, transform: 'translateX(-2px)' },
});

const slideDownAndFadeIn = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideDownAndFadeOut = keyframes({
  '0%': { opacity: 1, transform: 'translateY(0)' },
  '100%': { opacity: 0, transform: 'translateY(-2px)' },
});

const slideLeftAndFadeIn = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideLeftAndFadeOut = keyframes({
  '0%': { opacity: 1, transform: 'translateX(0)' },
  '100%': { opacity: 0, transform: 'translateX(2px)' },
});

const StyledContent = styled(PopoverPrimitive.Content, {
  borderRadius: '$2',
  $$paddingX: '$space$3',
  $$paddingY: '$space$3',
  paddingLeft: '$$paddingX',
  paddingRight: '$$paddingX',
  paddingTop: '$$paddingY',
  paddingBottom: '$$paddingY',
  minWidth: 160,
  overflow: 'auto',
  outline: 0,
  backgroundColor: 'white',
  boxShadow: '$popover',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '$transitionDurations$2',
    animationTimingFunction: '$transitionTimings$2',
    willChange: 'transform, opacity',
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFadeIn },
      '&[data-side="right"]': { animationName: slideLeftAndFadeIn },
      '&[data-side="bottom"]': { animationName: slideUpAndFadeIn },
      '&[data-side="left"]': { animationName: slideRightAndFadeIn },
    },
    '&[data-state="closed"]': {
      '&[data-side="top"]': { animationName: slideDownAndFadeOut },
      '&[data-side="right"]': { animationName: slideLeftAndFadeOut },
      '&[data-side="bottom"]': { animationName: slideUpAndFadeOut },
      '&[data-side="left"]': { animationName: slideRightAndFadeOut },
    },
  },
  variants: {
    paddingY: {
      noPadding: {
        $$paddingY: 'unset',
      },
      1: {
        $$paddingY: '$space$1',
      },

      2: {
        $$paddingY: '$space$2',
      },
      3: {
        $$paddingY: '$space$3',
      },
    },
    paddingX: {
      noPadding: {
        $$paddingX: 'unset',
      },
      1: {
        $$paddingX: '$space$1',
      },

      2: {
        $$paddingX: '$space$2',
      },
      3: {
        $$paddingX: '$space$3',
      },
    },
  },
  '&:focus': {
    outline: 0,
  },
  '&:-webkit-direct-focus': {
    outlineColor: '-webkit-focus-ring-color',
    outlineStyle: 'auto',
    outlineWidth: '0px !important',
  },

  '& input:focus,select:focus,textarea:focus,button:focus': {
    outline: 'none',
  },
});

const StyledArrow = styled(PopoverPrimitive.Arrow, {
  fill: 'white',
});

const StyledClose = styled(PopoverPrimitive.Close, {
  all: 'unset',
  fontFamily: 'inherit',
  borderRadius: '100%',
  height: 16,
  width: 16,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$gray11',
  position: 'absolute',
  top: 8,
  right: 8,

  '&:hover': { backgroundColor: '$gray4' },
  '&:focus': { boxShadow: '0 0 0 2px $colors$gray7' },
});

// Exports
export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = PopoverPrimitive.Trigger;
export const PopoverContent = StyledContent;
export const PopoverArrow = StyledArrow;
export const PopoverClose = StyledClose;
export const PopoverAnchor = PopoverPrimitive.Anchor;
