/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable functional/prefer-readonly-type */
import { dynamic, enums, optional } from 'superstruct';
import { Context, Struct } from 'superstruct/lib/struct';

export function tsEnum<
  T extends { [key in keyof T]: T[key] },
  K extends Extract<keyof T, string>
>(
  tsEnum: T
): Struct<
  T,
  {
    [O in keyof K[][number]]: O;
  }
>;
export function tsEnum<
  T extends { [key in keyof T]: T[key] },
  K extends Extract<keyof T | '', string>
>(
  tsEnum: T,
  allowEmpty: true
): Struct<
  T,
  {
    [O in keyof K[][number]]: O;
  }
>;
export function tsEnum<
  T extends { [key in keyof T]: T[key] },
  K extends Extract<keyof T | '', string>
>(tsEnum: T, allowEmpty?: boolean) {
  const values = Object.values(tsEnum);

  if (allowEmpty) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return enums<K>([...values, ''] as any);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return enums<K>(values as any);
}

export function requiredWhen<T, S>(
  struct: Struct<T, S>,
  comparator: (value: unknown, ctx: Context) => boolean
): Struct<T | (T | undefined), null> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return dynamic((value, ctx): any =>
    comparator(value, ctx) ? struct : optional(struct)
  );
}
