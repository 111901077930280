import { Box, Button, styled } from '@mui/material';
import QRCode from 'qrcode.react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { FormTextField } from '../../../../components';
import { superstructResolver, FormFriendly } from '../../../../form';
import { MfaOptionsEnum } from '../MfaOptions';
import {
  MfaAuthAppFields,
  MfaAuthAppFormValidation,
} from './MfaAuthAppForm.validation';
import { MfaCodeSubmitProps } from '../../auth.interface';

interface Props {
  readonly onSubmit: ({
    code,
    setMfaPreference,
    redirect,
  }: MfaCodeSubmitProps) => Promise<void>;
  readonly setMfaPreference?: boolean;
  readonly isSubmitting: boolean;
  readonly onSkip?: () => void;
  readonly onGoBack?: () => void;
  readonly secret?: string;
  readonly username?: string;
  readonly issuer?: string;
  readonly login: boolean;
}

export const MfaAuthAppForm = ({
  onSubmit,
  onSkip,
  onGoBack,
  setMfaPreference,
  isSubmitting,
  secret,
  username,
  issuer,
  login,
}: Props): JSX.Element => {
  const { t } = useTranslation(['login']);
  const { handleSubmit, control } = useForm<FormFriendly<MfaAuthAppFields>>({
    resolver: superstructResolver(MfaAuthAppFormValidation),
    defaultValues: {
      code: '',
    },
  });

  return (
    <FormContainer
      onSubmit={handleSubmit((values) =>
        onSubmit({
          code: values.code,
          setMfaPreference:
            setMfaPreference !== undefined ? setMfaPreference : false,
          redirect: login,
        })
      )}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1rem',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {!login && (
          <QRCode
            value={`otpauth://totp/AWSCognito:${username}?secret=${secret}&issuer=${issuer}`}
          />
        )}
        <FormTextField
          fullWidth
          name="code"
          type="text"
          label={t('login:form.code')}
          control={control}
          t={t}
        />
        <Button
          color="primary"
          variant="contained"
          type="submit"
          fullWidth
          disabled={isSubmitting}>
          {t('login:form.confirm')}
        </Button>
        {!login && onSkip && (
          <Button
            color="primary"
            variant="text"
            onClick={onSkip}
            fullWidth
            disabled={isSubmitting}>
            {t('login:stage.mfaAuthApp.content.skip')}
          </Button>
        )}
        {(login || !(login && onSkip)) && (
          <Button color="primary" variant="text" onClick={onGoBack} fullWidth>
            {t('login:form.back')}
          </Button>
        )}
      </Box>
    </FormContainer>
  );
};

const FormContainer = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '> div': {
    marginBottom: theme.spacing(3),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));
