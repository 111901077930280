import { AXIOS_INSTANCE } from '@b7hio/api-lib/src/mutator/instance';
import { BondsmithUser } from '@b7hio/core-lib/src/features/auth/hooks';
import { Auth } from 'aws-amplify';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export function useAuthLogin(): [
  user: BondsmithUser | undefined,
  logout: () => Promise<void>,
  refreshUser: () => Promise<void>
] {
  const router = useRouter();
  const [user, setUser] = useState<BondsmithUser | undefined>();

  useEffect(() => {
    let active = true;

    const check = async () => {
      if (user) {
        return;
      }

      try {
        if (active) {
          const user: BondsmithUser = await Auth.currentAuthenticatedUser();

          if (!AXIOS_INSTANCE.defaults.headers.common.Authorization) {
            await setAuthHeaders();
          }

          setUser(user);

          // Moved setting authToken into an interceptor to fix issue with
          // timeouts after 15 minutes
          AXIOS_INSTANCE.interceptors.request.use(async (config) => {
            try {
              await setAuthHeaders();
              return config;
            } catch (err) {
              return err;
            }
          });
        }
      } catch (error) {
        await router.replace('/auth/login');
      }
    };

    check();

    return () => {
      active = false;
    };
  }, [user]);

  const setAuthHeaders = async () => {
    const data = await Auth.currentSession();
    const authToken = `Bearer ${data.getAccessToken().getJwtToken()}`;
    AXIOS_INSTANCE.defaults.headers.common.Authorization = authToken;
  };

  const handleLogout = async () => {
    await Auth.signOut();
    setUser(undefined);
  };

  const refreshUser = async () => {
    setUser(
      await Auth.currentAuthenticatedUser({
        bypassCache: true,
      })
    );
  };

  return [user, handleLogout, refreshUser];
}
