import { alpha, Switch } from '@mui/material';
import { styled } from '@stitches/react';
import { colorsHsla } from '../../theme/colors';
import { customShadow } from './CustomShadow';

export const MuiBrandSwitch = styled(Switch, {
  '& .MuiSwitch-switchBase.Mui-checked': {
    '& .MuiSwitch-thumb': {
      color: colorsHsla('brand9'),
      boxShadow: customShadow('brand9'),
    },
    '&:hover': {
      backgroundColor: alpha(colorsHsla('brand4'), 0.3),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: colorsHsla('brand7'),
  },
});
