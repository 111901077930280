import React from 'react';
import { globalCss } from '../../stitches.config';
import { normalize } from './normalize';

const globalStyles = globalCss(
  {
    '*, ::before, ::after': {
      boxSizing: 'border-box',
    },
  },
  {
    // @ts-expect-error REVIEW
    body: {
      fontFamily: '$sans',
      lineHeight: 1.5,
      color: '$hiContrast',
    },
  },
  ...normalize
);

export const Global: React.FC = () => {
  globalStyles();
  return null;
};
