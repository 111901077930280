import { constVoid, pipe } from 'fp-ts/function';
import * as RA from 'fp-ts/ReadonlyArray';
import type { CSS } from '../../stitches.config';
import { $colors, styled } from '../../stitches.config';

const StepperWrapper = styled('div', {
  alignItems: 'flex-start',
  display: 'flex',
});

const StepWrapper = styled('div', {
  flex: 1,
});

const StepContent = styled('div', {
  display: 'flex',
  fill: '$colors$primary',
  flexShrink: 0,
  flexDirection: 'column',
  paddingRight: 0,
  '& > *': {
    color: 'white',
    fontSize: '$fontSizes$3',
  },
  transition: 'all 350s linear',
});

const arrowGap = '15px';

const StepHolder = styled('div', {
  alignItems: 'center',
  background: `transparent linear-gradient(180deg, ${$colors(
    'navyBlueGradient2Start'
  )} 0%, ${$colors('navyBlueGradient2End')} 100%) 0% 0% no-repeat padding-box;`,
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  flexDirection: 'column',
  opacity: 1,
  padding: '$2',

  variants: {
    placement: {
      start: {
        clipPath: `polygon(0% 0%, calc(100% - ${arrowGap}) 0%, 100% 50%, calc(100% - ${arrowGap}) 100%, 0% 100%)`,
      },
      middle: {
        clipPath: `polygon(${arrowGap} 50%, 0% 0%, calc(100% - ${arrowGap}) 0%, 100% 50%, calc(100% - ${arrowGap}) 100%, 0% 100%)`,
      },
      end: {
        clipPath: `polygon(${arrowGap} 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%)`,
      },
    },
    variant: {
      complete: {
        color: $colors('brand11'),
        background: $colors('brand4'),
      },
      active: {
        color: $colors('white'),
        background: $colors('navyBlue8'),
      },
      inactive: {
        color: $colors('navyBlue9'),
        background: $colors('gray3'),
      },
    },
  },
  defaultVariants: {
    variant: 'inactive',
    placement: 'middle',
  },
});

const TextHolder = styled('span', {
  fill: '$loContrast',
  // TODO Font size should probably be defined by design system rules
  // HACK The size has been hacked to fit the New Client panel screen
  $$fontWeight: '$fontWeights$2',
  $$fontSize: '0.75rem',
  fontSize: '$$fontSize',
  fontWeight: '$$fontWeight',
  variants: {
    variant: {
      header: {
        $$fontWeight: '$fontWeights$2',
        $$fontSize: '0.75rem',
      },
      subHeader: {
        $$fontWeight: '$fontWeights$2',
        $$fontSize: '0.875rem',
      },
    },
  },
});

type HorizontalArrowStep = {
  readonly onClick?: (e: {
    readonly step: number;
    readonly state: StepState;
    readonly placement: StepPlacement;
  }) => void;
  readonly label?: string;
  // TODO Tentative Content component for customisable labels
};

export interface HorizontalArrowStepperProps {
  readonly activeStep: number;
  readonly steps: readonly HorizontalArrowStep[];
  readonly css?: CSS;
}

export const HorizontalArrowStepper = ({
  activeStep,
  steps,
  css,
}: HorizontalArrowStepperProps) => {
  const endStep = steps.length - 1;
  return (
    // TODO Fix monorepo dependencies mess. core build seems to be breaking
    // because of this under a different tsconfig
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <StepperWrapper css={css as unknown as any}>
      {pipe(
        steps,
        RA.mapWithIndex((index, { label }) => (
          <HorizontalArrowStep
            key={index}
            placement={
              index === 0 ? 'start' : index === endStep ? 'end' : 'middle'
            }
            step={index + 1}
            state={
              index === activeStep
                ? 'active'
                : index < activeStep
                ? 'complete'
                : 'inactive'
            }
            label={label}
          />
        ))
      )}
    </StepperWrapper>
  );
};

export const HorizontalArrowStepLabel = styled('span', {
  textAlign: 'center',
  marginTop: '$3',
  display: 'block',
});

type StepState = 'active' | 'inactive' | 'complete';
type StepPlacement = 'start' | 'middle' | 'end';

export interface HorizontalArrowStepProps extends HorizontalArrowStep {
  readonly step: number;
  readonly state: StepState;
  readonly placement: StepPlacement;
}

export const HorizontalArrowStep = ({
  state,
  step,
  label,
  placement,
  onClick = constVoid,
}: HorizontalArrowStepProps): JSX.Element => (
  <StepWrapper onClick={() => onClick({ step, state, placement })}>
    <StepContent>
      <StepHolder variant={state} placement={placement}>
        <TextHolder variant={'header'}>{`STEP ${step}`}</TextHolder>
        <TextHolder variant="subHeader">{label ?? <br />}</TextHolder>
      </StepHolder>
    </StepContent>
  </StepWrapper>
);
