import { colorsHsla } from '@b7hio/007/src/theme/colors';
import ChevronRight from '@mui/icons-material/ChevronRight';
import {
  Autocomplete,
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, { useReducer } from 'react';
import { useQuery } from 'react-query';
import { CustomMuiTextFieldCss } from '../../utils/CustomMuiTextFieldCss';
import { findAddresses, retrieveAddress } from './loqate/client';
import { Address, FindItem } from './loqate/types';
import { initialState, postcodeLookupSlice } from './PostcodeLookup.state';

const actions = postcodeLookupSlice.actions;

type Props = {
  readonly onSelectAddress: (address: Address) => void;
  readonly helperText?: string;
  readonly error?: boolean;
  readonly label?: string;
  readonly customMuiTextField?: boolean;
};

export const PostcodeLookup: React.FC<Props> = ({
  onSelectAddress,
  helperText,
  error,
  label,
  customMuiTextField,
}) => {
  const [state, dispatch] = useReducer(
    postcodeLookupSlice.reducer,
    initialState
  );
  const { isLoading, data } = useQuery(
    ['postcodeSearch', state.query],
    () =>
      findAddresses({
        text: state.container ? '' : state.query,
        countries: ['GBR'],
        language: 'en-GB',
        containerId: state.container ? state.query : undefined,
      }),
    {
      enabled: state.query.length > 1,
    }
  );

  const handleChange = async (item: FindItem) => {
    if (item.Type === 'Address') {
      dispatch(actions.reset());
      const address = await retrieveAddress(item.Id);
      onSelectAddress(address);
      return;
    }

    dispatch(actions.searchByContainer(item.Id));
  };

  return (
    <Autocomplete
      loading={isLoading}
      open={state.open}
      onClose={() => dispatch(actions.reset())}
      disableCloseOnSelect
      inputValue={state.inputValue}
      options={data || []}
      value={undefined}
      disableClearable
      filterOptions={!state.container ? (options) => options : undefined}
      getOptionLabel={(option) => `${option.Text} ${option.Description}`}
      isOptionEqualToValue={(o1, o2) => o1.Id === o2.Id}
      onChange={(_, input) => handleChange(input)}
      onInputChange={(_, value, reason) => {
        if (reason === 'reset') {
          return;
        }

        dispatch(actions.searchByText(value));
      }}
      noOptionsText={
        isLoading
          ? 'Loading...'
          : state.inputValue.length < 2
          ? ''
          : 'No addresses found'
      }
      renderOption={(props, option) => {
        return (
          <Box component="li" {...props}>
            <Typography sx={{ fontWeight: 600 }}>{option.Text}</Typography>
            <Typography sx={{ color: 'text.secondary', flexGrow: 1 }}>
              &nbsp;
              {option.Description}
            </Typography>
            {option.Type !== 'Address' && (
              <ChevronRight
                sx={{ color: 'text.secondary', justifySelf: 'flex-end' }}
              />
            )}
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
        sx={customMuiTextField ? CustomMuiTextFieldCss : {} }
          {...params}
          label={label || 'Address'}
          error={error}
          helperText={helperText}
          variant={customMuiTextField ? 'filled' : 'outlined'}
          InputProps={{
            ...params.InputProps,
            endAdornment: isLoading && (
              <InputAdornment
                position="end"
                sx={{
                  position: 'absolute',
                  right: 16,
                  top: '50%',
                }}>
                <CircularProgress color="inherit" size={20} />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};
