import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useReducer, useState } from 'react';
import {
  authSlice,
  AuthStage,
  initialState as authInitialState,
} from '../Auth.state';
import { MfaOptionsEnum } from '../components';
import {
  CreateNewPasswordSubmitProps,
  FinishSetupSubmitProps,
  LoginSubmitProps,
  MfaAskSubmitProps,
  MfaCodeSubmitProps,
  MfaLoginAskProps,
  MfaOptionsSubmitProps,
  MfaRemoveAuthMethodSubmitProps,
  MfaRemoveOptionSubmit,
  MfaSmsNumberSubmitProps,
} from '../auth.interface';

export type BondsmithUser = CognitoUser & {
  attributes: {
    email: string;
    email_verified: boolean;
    family_name: string;
    given_name: string;
    phone_number: string;
    phone_number_verified: boolean;
    sub: string;
    'custom:b7h:user_type': 'OPS' | 'BANK';
    'custom:b7h:user_roles': string;
  };
};

export function useAuth() {
  const { t } = useTranslation(['login']);
  const router = useRouter();

  const [cognitoUser, setCognitoUser] = React.useState<BondsmithUser | any>(
    undefined
  );
  const [error, setError] = React.useState<string>();
  const [state, dispatch] = useReducer(authSlice.reducer, authInitialState);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string | undefined>(undefined);
  const [userPassword, setUserPassword] = useState<string | undefined>(
    undefined
  );

  /**
   * Handles the setting of Cognito User state in this file
   * // TODO: This should be deprecated after streamlining the auth management by migrating `useShellLogin` to this file
   * @param user - The Bondsmith User object which we get from Cognito
   */
  const handleSetCognitoUser = (user: CognitoUser) => {
    setCognitoUser(user);
  };

  /**
   * Handles the login process
   * @param email - The email address of the user attempting to log in
   * @param password - The password of the user attempting to log in
   * @param redirect - Optional parameter; decide if you want the user to redirect to the dashboard if possible (`true`), or return a user object (`false`). Default: `true`
   */
  const handleLoginSubmit = async ({
    email,
    password,
    redirect = true,
  }: LoginSubmitProps) => {
    try {
      setError('');
      setSubmitting(true);
      const user = await Auth.signIn(email, password);

      if (user.challengeName) {
        setCognitoUser(user);

        // To be used by Cognito resubmit
        setUserEmail(email);
        setUserPassword(password);

        // TODO: Implement feature-flag
        const mfaMethods = [MfaOptionsEnum.Sms, MfaOptionsEnum.AuthApp];

        dispatch(
          authSlice.actions.authChallenge({
            user: {
              ...user,
              challengeName:
                user.challengeName === 'NEW_PASSWORD_REQUIRED'
                  ? AuthStage.CreateNewPassword
                  : user.challengeName === MfaOptionsEnum.Sms
                  ? AuthStage.MfaLoginSms
                  : user.challengeName === MfaOptionsEnum.AuthApp
                  ? AuthStage.MfaLoginAuthApp
                  : AuthStage.MfaLoginAsk,
            },
            mfaUserMethodsAvailable: mfaMethods
              ? (mfaMethods as MfaOptionsEnum[])
              : [],
            mfaUserSelectedLoginMethod:
              user.challengeName === MfaOptionsEnum.Sms
                ? MfaOptionsEnum.Sms
                : user.challengeName === MfaOptionsEnum.AuthApp
                ? MfaOptionsEnum.AuthApp
                : undefined,
          })
        );
        return;
      }

      if (redirect) await router.push('/');

      return user;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.code === 'NotAuthorizedException') {
        setError(t('login:error.invalidUsernamePassword'));
      }

      if (e.code === 'UserNotConfirmedException') {
        setError(t('login:error.userNotConfirmed'));
      }

      if (e.code === 'UserNotFoundException') {
        setError(t('login:error.userNotFound'));
      }

      if (e.code === 'PasswordResetRequiredException') {
        await router.push('/auth/forgot?reason=resetRequired');
      }

      // eslint-disable-next-line no-console
      console.error(e);
      return e;
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Handles the process of finalising the creation of new password for Cognito user
   * @param password - The new password the Cognito user will assume
   */
  const handleCreateNewPasswordSubmit = async ({
    password,
  }: CreateNewPasswordSubmitProps) => {
    try {
      setError('');
      setSubmitting(true);

      // You need to get the new password and required attributes from the UI inputs
      // and then trigger the following function with a button click
      // For example, the email and phone_number are required attributes
      await Auth.completeNewPassword(
        cognitoUser, // the Cognito User Object
        password // the new password
      );

      dispatch(
        authSlice.actions.authChallenge({
          user: {
            ...cognitoUser,
            challengeName: AuthStage.MfaAsk,
          },
        })
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.name === 'InvalidPasswordException') {
        setError(e.message);
        return;
      }

      if (e.name === 'NotAuthorizedException') {
        setError(e.message);
        return;
      }

      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Handles the user decision to setup MFA during their onboarding process
   * @param setup - If `true` then move to next AuthStage to present user with MFA options. If `false`, skip to dashboard.
   */
  const handleMfaAskSubmit = async ({ setup }: MfaAskSubmitProps) => {
    try {
      setError('');
      setSubmitting(true);

      if (setup) {
        dispatch(
          authSlice.actions.authChallenge({
            user: {
              ...cognitoUser,
              challengeName: AuthStage.MfaOptions,
            },
          })
        );
      } else {
        setSubmitting(false);
        await router.push('/');
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Handles the submission of chosen options for setting up MFA. A user can select either/or/both MFA options.
   * @param options - An array of MFA options user wants to set up.
   * TODO: Add info about userMfaMethods
   */
  const handleMfaOptionsSubmit = async ({
    options,
    userMfaMethods,
  }: MfaOptionsSubmitProps) => {
    try {
      setError('');
      setSubmitting(true);

      // Prepare a secret for next optional step of Auth App setup
      const mfaSecret = await Auth.setupTOTP(cognitoUser);

      dispatch(
        authSlice.actions.authChallenge({
          user: {
            ...cognitoUser,
            challengeName: options.includes(MfaOptionsEnum.Sms)
              ? AuthStage.MfaSmsNumber
              : AuthStage.MfaAuthApp,
          },
          mfaSetupOptions: options,
          mfaUserMethodsAvailable: userMfaMethods,
          mfaSecret,
        })
      );

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Update the user attribute "phone_number" in Cognito and send an SMS to verify. Same method used to resend SMS code
   * @param phoneNumber - The mobile number we want to set for the user in Cognito
   * @param resend - Optional parameter; decide whether to use the function to resend an SMS code
   */
  const handleMfaSmsNumberSubmit = async ({
    phoneNumber,
    resend = false,
  }: MfaSmsNumberSubmitProps) => {
    try {
      setError('');
      setSubmitting(true);

      const mobileNumber = phoneNumber.replace(/\s/g, '');

      await Auth.updateUserAttributes(cognitoUser, {
        phone_number: mobileNumber,
      });

      dispatch(
        authSlice.actions.authChallenge({
          user: {
            ...cognitoUser,
            challengeName: AuthStage.MfaSmsCode,
          },
          phoneNumber: mobileNumber,
          mfaUserMethodsAvailable: state.mfaUserMethodsAvailable,
          lastResubmit: resend ? new Date() : undefined,
        })
      );
    } catch (e: any) {
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Handles the confirmation of SMS code
   * @param code - The one-time SMS code user receives
   * @param setMfaPreference - Optional parameter that decides whether you want to explicitly enable this MFA method in Cognito (default: `true`)
   */
  const handleMfaSmsCodeSubmit = async ({
    code,
    setMfaPreference = true,
  }: MfaCodeSubmitProps) => {
    try {
      setError('');
      setSubmitting(true);

      await Auth.verifyUserAttributeSubmit(cognitoUser, 'phone_number', code);

      if (setMfaPreference) {
        cognitoUser.setUserMfaPreference(
          {
            PreferredMfa: false,
            Enabled: true,
          },
          {
            PreferredMfa: false,
            Enabled:
              state.mfaSetupOptions?.includes(MfaOptionsEnum.AuthApp) ||
              state.mfaUserMethodsAvailable?.includes(MfaOptionsEnum.AuthApp),
          },
          (err: any, result: any) => {
            console.error(err, result);
          }
        );
      }

      dispatch(
        authSlice.actions.authChallenge({
          user: {
            ...cognitoUser,
            challengeName: state.mfaSetupOptions?.includes(
              MfaOptionsEnum.AuthApp
            )
              ? AuthStage.MfaAuthApp
              : AuthStage.MfaSuccess,
          },
          mfaSecret: state.mfaSecret,
          mfaSetupOptions: state.mfaSetupOptions,
        })
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Resends the SMS verification code for an existing mobile number, not when setting a new one!
   */
  const handleMfaSmsCodeResend = async () => {
    try {
      setError('');
      setSubmitting(true);
      await Auth.verifyUserAttribute(cognitoUser, 'phone_number');

      dispatch(
        authSlice.actions.authChallenge({
          user: {
            ...cognitoUser,
            challengeName: AuthStage.MfaSmsCode,
          },
          lastResubmit: new Date(),
        })
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Handles the setup of MFA Authentication App method
   * @param code - The one-time 60-second rotating code users gets on their auth app
   * @param setMfaPreference - Optional parameter that decides whether you want to explicitly enable this MFA method in Cognito (default: `true`)
   */
  const handleMfaAuthAppSubmit = async ({
    code,
    setMfaPreference = true,
  }: MfaCodeSubmitProps) => {
    try {
      setSubmitting(true);
      await Auth.verifyTotpToken(cognitoUser, code);

      if (setMfaPreference) {
        cognitoUser.setUserMfaPreference(
          {
            PreferredMfa: false,
            Enabled:
              state.mfaSetupOptions?.includes(MfaOptionsEnum.Sms) ||
              state.mfaUserMethodsAvailable?.includes(MfaOptionsEnum.Sms),
          },
          {
            PreferredMfa: false,
            Enabled: true,
          },
          (err: any, result: any) => {
            console.error(err, result);
            if (err) {
              // TODO: Display an alert - snackbar
            }
          }
        );
      }

      dispatch(
        authSlice.actions.authChallenge({
          user: {
            ...cognitoUser,
            challengeName: AuthStage.MfaSuccess,
          },
        })
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Handles the MFA options presented during login for users who have already set up MFA
   * @param value - The MFA option the user selected to log in (SMS or Auth App)
   * @param resend - If true then SMS code will be resent. Set to `true` if you want to resend an SMS code for log in
   */
  const handleMfaLoginAsk = async ({
    value,
    resend = false,
  }: MfaLoginAskProps) => {
    try {
      setSubmitting(true);
      setError('');
      const callDispatch = (mfaUserSelectedLoginMethod: MfaOptionsEnum) => {
        dispatch(
          authSlice.actions.authChallenge({
            user: {
              ...userDetails,
              challengeName:
                value === MfaOptionsEnum.Sms
                  ? AuthStage.MfaLoginSms
                  : AuthStage.MfaLoginAuthApp,
            },
            lastResubmit:
              mfaUserSelectedLoginMethod === MfaOptionsEnum.Sms
                ? resend
                  ? new Date()
                  : undefined
                : undefined,
            mfaUserSelectedLoginMethod,
          })
        );
      };

      const userDetails = await Auth.signIn(
        userEmail ?? '',
        userPassword ?? ''
      );
      setCognitoUser(userDetails);

      if (userDetails.challengeName === 'SELECT_MFA_TYPE') {
        userDetails.sendMFASelectionAnswer(value, {
          onSuccess: () => {
            console.log('success');
          },
          onFailure: (err: any) => {
            console.error(err);
            // TODO: Display an alert - snackbar
          },
          mfaRequired: () => {
            callDispatch(value);
          },
          totpRequired: () => {
            callDispatch(value);
          },
        });
      } else {
        callDispatch(value);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Handles the submission and processing of the one-time code to confirm user MFA (and redirect to dashboard, if set to `true`)
   * @param code - The MFA one-time SMS or Authenticator code (compatible with both!)
   * @param redirect - Optional parameter to decide whether you want to redirect user or not. Set `true` if you use it for logging into the dashboard
   */
  const handleMfaLoginCodeSubmit = async ({
    code,
    redirect = true,
  }: MfaCodeSubmitProps) => {
    try {
      setError('');
      setSubmitting(true);

      const user = await Auth.confirmSignIn(
        cognitoUser,
        code,
        state.mfaUserSelectedLoginMethod === MfaOptionsEnum.Sms
          ? MfaOptionsEnum.Sms
          : MfaOptionsEnum.AuthApp
      );

      setCognitoUser(user);

      if (redirect) await router.push('/');

      return user;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * A function that resends the SMS code - used ONLY for Login actions because it relies on existing Cognito phone number
   */
  const handleMfaLoginSmsCodeResend = async () => {
    try {
      setError('');
      setSubmitting(true);
      const user = await Auth.signIn(userEmail ?? '', userPassword ?? '');

      dispatch(
        authSlice.actions.authChallenge({
          user: {
            ...user,
            challengeName: AuthStage.MfaLoginSms,
          },
          phoneNumber: user.challengeParam.CODE_DELIVERY_DESTINATION ?? '',
          lastResubmit: new Date(),
          mfaUserSelectedLoginMethod: state.mfaUserSelectedLoginMethod,
        })
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Handles the initiation of the steps for removing an MFA method. Use this to set the AuthStage to MFA_REMOVE_ASK.
   * @param mfaUserMethodToDisable - required parameter to specify which user mfa method you want to remove
   */
  const handleMfaRemoveOptionSubmit = async ({
    mfaUserMethodToDisable,
  }: MfaRemoveOptionSubmit) => {
    try {
      dispatch(
        authSlice.actions.authChallenge({
          user: {
            ...cognitoUser,
            challengeName: AuthStage.MfaRemoveAsk,
          },
          mfaUserMethodToDisable,
        })
      );
    } catch (e: any) {
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Handles the confirmation of intent to remove MFA method. It is used as the next step after user confirms they really want to remove MFA.
   * Use this method as a chain to `handleMfaRemoveOptionSubmit` to derive `state.mfaUserMethodToDisable`
   */
  const handleMfaRemoveAskSubmit = async () => {
    try {
      setError('');
      setSubmitting(true);

      dispatch(
        authSlice.actions.authChallenge({
          user: {
            ...cognitoUser,
            challengeName: AuthStage.MfaRemoveConfirm,
          },
          mfaUserMethodToDisable: state.mfaUserMethodToDisable,
        })
      );
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Handles the final processing of removing an MFA method
   * TODO: A good idea would be to move auth state from `useShellLogin` to this file, so we don't need to pass `userMfaMethods`
   * @param userMfaMethods - It is required to be passed because we want to ensure there is an active MFA
   * @param password - The user's password to provide final confirmation of their intent to remove the MFA method
   */
  const handleMfaRemoveAuthMethodSubmit = async ({
    userMfaMethods,
    password,
  }: MfaRemoveAuthMethodSubmitProps) => {
    try {
      setError('');
      setSubmitting(true);

      const user = await Auth.signIn(
        state?.user?.attributes?.email ?? '',
        password
      );

      // If user removed SMS MFA, delete phone number
      if (state.mfaUserMethodToDisable === MfaOptionsEnum.Sms) {
        await Auth.deleteUserAttributes(user, ['phone_number']);
      }

      user.setUserMfaPreference(
        {
          PreferredMfa: false,
          Enabled:
            state.mfaUserMethodToDisable === MfaOptionsEnum.Sms
              ? false
              : userMfaMethods.includes(MfaOptionsEnum.Sms),
        },
        {
          PreferredMfa: false,
          Enabled:
            state.mfaUserMethodToDisable === MfaOptionsEnum.AuthApp
              ? false
              : userMfaMethods.includes(MfaOptionsEnum.AuthApp),
        },
        (err: any, result: any) => {
          console.error(err, result);
        }
      );

      dispatch(
        authSlice.actions.authChallenge({
          user: {
            ...user,
            challengeName: AuthStage.MfaSuccess,
          },
          mfaUserMethodToDisable: undefined,
          mfaUserMethodsAvailable: userMfaMethods.filter(
            (m) => m !== state.mfaUserMethodToDisable
          ),
        })
      );
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * Handles the transition to a specific stage with optional explicit parameters
   * @param stage - The stage you want to transition to (check Auth.state.tsx)
   * @param payload - Optional parameter; pass a custom `AuthChallengePayload` payload
   */
  const handleGoToStage = (stage: AuthStage, payload?: any) => {
    dispatch(authSlice.actions.goToStage({ stage, ...payload }));
  };

  /**
   * Fetches the user roles, parses the string and returns an array of roles.
   * @param user - The Bondsmith User object which we get from Cognito
   */
  const getUserRoles = (user: BondsmithUser) => {
    return JSON.parse(user.attributes['custom:b7h:user_roles']);
  };

  return {
    state,
    cognitoUser,
    error,
    submitting,
    handleLoginSubmit,
    handleCreateNewPasswordSubmit,
    handleMfaAskSubmit,
    handleMfaOptionsSubmit,
    handleMfaSmsNumberSubmit,
    handleMfaSmsCodeSubmit,
    handleMfaSmsCodeResend,
    handleMfaAuthAppSubmit,
    handleMfaLoginAsk,
    handleMfaLoginCodeSubmit,
    handleMfaLoginSmsCodeResend,
    handleMfaRemoveAskSubmit,
    handleMfaRemoveAuthMethodSubmit,
    handleMfaRemoveOptionSubmit,
    handleGoToStage,
    getUserRoles,
    handleSetCognitoUser,
  };
}
