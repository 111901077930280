import type { AlertProps } from '@mui/material';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface SnackbarMessage {
  readonly key: string;
  readonly message: string;
  readonly autoHideDuration?: number | null;
  readonly severity: AlertProps['severity'];
  readonly async?: Promise<string>;
}

export interface SnackbarMessageState extends Omit<SnackbarMessage, 'async'> {
  readonly inProgress: boolean;
  readonly width: number;
}

export type SnackbarMessageOptionalKey = Omit<SnackbarMessage, 'key'> & {
  key?: string;
};

interface SnackbarStackState {
  readonly open: boolean;
  readonly messages: readonly SnackbarMessageState[];
}

export const initialState: SnackbarStackState = {
  open: false,
  messages: [],
};

// eslint-disable-next-line functional/no-class
export class SnackbarErrorMessage extends Error {
  constructor(message: string, public readonly originalError: Error) {
    super(message);
  }
}

const calculateWidth = (message: string) => message.length * 7.3;

export const snackbarStackSlice = createSlice({
  name: 'snackbarStack',
  initialState,
  reducers: {
    open(state) {
      state.open = true;
    },
    close(state) {
      state.open = false;
    },
    addItem(state, action: PayloadAction<SnackbarMessage>) {
      state.messages.push({
        ...action.payload,
        inProgress: Boolean(action.payload.async),
        width: calculateWidth(action.payload.message),
      });

      if (!state.open && state.messages.length === 1) {
        state.open = true;
      }
    },
    completeItem(
      state,
      action: PayloadAction<
        Pick<SnackbarMessage, 'key' | 'message' | 'severity'>
      >
    ) {
      const message = state.messages.find((m) => m.key === action.payload.key);

      if (!message) {
        return;
      }

      message.inProgress = false;

      message.severity = action.payload.severity;

      message.message = action.payload.message;

      message.width = calculateWidth(action.payload.message);
    },
    removeItem(state, action: PayloadAction<string>) {
      const messageIdx = state.messages.findIndex(
        (message) => message.key === action.payload
      );

      if (messageIdx > 0) {
        state.messages.splice(messageIdx, 1);
      }
    },
    pop(state) {
      state.messages.splice(0, 1);

      if (state.messages.length > 0) {
        state.open = true;
      }
    },
  },
});
