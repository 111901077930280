import type { Infer } from 'superstruct';
import { object, string } from 'superstruct';
import { notEmpty } from '../../../../form';

export const LoginFormValidation = object({
  email: notEmpty(string()),
  password: notEmpty(string()),
});

export type LoginFormFields = Infer<typeof LoginFormValidation>;
