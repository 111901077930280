import { createTheme, responsiveFontSizes } from '@mui/material';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';

export const googleFontsLinkHref =
  'https://fonts.googleapis.com/css2?family=Inter:wght@800&family=Roboto:wght@300;400;500;700&display=swap';

export const googleFontsHash =
  'sha384-XwiGLYJJn/O/KjzzXH0VkeuviBUPOIRixR+z1aEUBRSUAJi2I5/Vkaw90JyMdjQh';

const sansBackupFontFamily = [
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];

const robotoFontFamily = ['Roboto', ...sansBackupFontFamily].join(',');
const interFontFamily = ['Inter', ...sansBackupFontFamily].join(',');

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    readonly paperWell: string;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    readonly pageHeader: {
      readonly main: string;
      readonly background: string;
    };
    readonly loginArea: {
      readonly main: string;
      readonly background: string;
    };
    readonly navigationBar: {
      readonly main: string;
      readonly background: string;
      readonly secondary: string;
    };
    readonly notificationsList: {
      readonly main: string;
      readonly logo: string;
      readonly message: string;
    };
    readonly clientForm: {
      readonly borderColor: string;
      readonly buttonRadius: string;
      readonly buttonBackground: string;
      readonly secondary: string;
      readonly divider: string;
    };
    readonly orderForm: {
      readonly background: string;
      readonly main: string;
    };

    readonly gradients: {
      readonly boxHeader: string;
      readonly lighterBoxHeader: string;
      readonly lightestBoxHeader: string;
    };
    readonly dividers: {
      readonly main: string;
    };
    readonly signs: {
      readonly fontSize: string;
      readonly positive: {
        readonly main: string;
        readonly background: string;
      };
      readonly negative: {
        readonly main: string;
        readonly background: string;
      };
    };
  }

  interface PaletteOptions {
    readonly pageHeader: {
      readonly main: string;
      readonly background: string;
    };
    readonly loginArea: {
      readonly main: string;
      readonly background: string;
    };
    readonly navigationBar: {
      readonly main: string;
      readonly background: string;
      readonly secondary: string;
    };
    readonly notificationsList: {
      readonly main: string;
      readonly logo: string;
      readonly message: string;
    };
    readonly clientForm: {
      readonly borderColor: string;
      readonly buttonRadius: string;
      readonly buttonBackground: string;
      readonly secondary: string;
      readonly divider: string;
    };
    readonly orderForm: {
      readonly background: string;
      readonly main: string;
    };
    readonly gradients: {
      readonly boxHeader: string;
      readonly lighterBoxHeader: string;
      readonly lightestBoxHeader: string;
    };
    readonly dividers: {
      readonly main: string;
    };
    readonly signs: {
      readonly fontSize: string;
      readonly positive: {
        readonly main: string;
        readonly background: string;
      };
      readonly negative: {
        readonly main: string;
        readonly background: string;
      };
    };
  }
}

declare module '@mui/material/styles/createMixins' {
  interface Mixins {
    readonly logoUrl?: string;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    readonly pageHeader: true;
  }
}

export const bondsmithTheme = responsiveFontSizes(
  createTheme({
    palette: {
      notificationsList: {
        main: 'black',
        message: '#9E9E9E',
        logo: '#DB74E9',
      },
      signs: {
        fontSize: '10px',
        positive: {
          main: '#71CB94',
          background: '#F3FDF7',
        },
        negative: {
          main: '#CE6B85',
          background: '#FBF1F3',
        },
      },
      background: {
        default: '#EAEEFF',
        paperWell: '#F7F7F7',
      },
      primary: {
        main: '#3C54D9',
      },
      secondary: {
        main: '#FCCB88',
      },
      // action: {
      //   active: '#D0D4FF',
      //   hover: '#D0D4FF',
      // },
      pageHeader: {
        main: '#FFFFFF',
        background:
          'transparent linear-gradient(0deg, #374ADB 0%, #263295 61%, #1C256E 100%) 0% 0% no-repeat padding-box',
      },
      loginArea: {
        main: '#FFFFFF',
        background:
          'transparent linear-gradient(180deg, #3749CE 0%, #2F3FB2 42%, #1e2772 100%) 0% 0% no-repeat padding-box',
      },
      clientForm: {
        borderColor: '#9E9E9E',
        buttonRadius: '4px',
        buttonBackground: '#374adb',
        secondary: '#757575',
        divider: '#E0E0E0',
      },
      orderForm: {
        background: '#2E3BAA',
        main: '#FFFFFF',
      },
      navigationBar: {
        main: 'white',
        background: '#303869',
        secondary: '#252D5C',
      },
      gradients: {
        boxHeader:
          'linear-gradient(90deg, rgb(55, 73, 206) 0%, rgb(47, 63, 178) 42%, rgb(30, 39, 114) 100%) 0% 0% no-repeat padding-box padding-box transparent',
        lighterBoxHeader:
          'linear-gradient(270deg, rgba(55,74,219,1) 68%, rgba(88,105,230,1) 100%) 0% 0% no-repeat padding-box padding-box transparent',
        lightestBoxHeader:
          'linear-gradient(270deg, rgba(100, 130, 240, 1) 10%, rgba(35, 50, 231, 0.31) 100%) 0% 0% no-repeat padding-box padding-box transparent',
      },
      dividers: {
        main: '#E3E3E3',
      },
    },
    shape: {
      borderRadius: 8,
    },
    typography: {
      fontFamily: robotoFontFamily,
      h1: {
        fontWeight: 800,
        fontFamily: interFontFamily,
      },
      h2: {
        fontWeight: 800,
        fontFamily: interFontFamily,
      },
      h3: {
        fontWeight: 800,
        fontFamily: interFontFamily,
      },
      h4: {
        fontWeight: 800,
        fontFamily: interFontFamily,
      },
      body2: {
        fontWeight: 'normal',
      },
    },
    mixins: {},
    components: {
      MuiDataGrid: {
        defaultProps: {
          density: 'compact'
        }
      },
      MuiAutocomplete: {
        defaultProps: {
          openOnFocus: true,
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            // '&.Mui-selected': {
            //   borderBottom: `2px solid #3C54D9`,
            //   '&>*': {
            //     color: 'blue',
            //   },
            // },
          },
        },
      },
      // MuiList: {
      //   defaultProps: {
      //     sx: {
      //       px: 1,
      //     },
      //   },
      // },
      // MuiListItem: {
      //   defaultProps: {
      //     sx: {
      //       borderRadius: (theme) => `${theme.shape.borderRadius}px`,
      //     },
      //   },
      // },
    },
  })
);
