import { Box, Button, styled } from '@mui/material';
import QRCode from 'qrcode.react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { UseFormSetError } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { FormTextField } from '../../../../components';
import { superstructResolver, FormFriendly } from '../../../../form';
import { MfaSetupFields, MfaSetupValidation } from './MfaSetupForm.validation';

interface Props {
  readonly secret?: string;
  readonly username?: string;
  readonly issuer?: string;
  readonly onSubmit: (
    values: MfaSetupFields,
    setError: UseFormSetError<MfaSetupFields>
  ) => Promise<void>;
}

export const MfaSetupForm: React.FC<Props> = ({
  secret,
  username,
  issuer,
  onSubmit,
}) => {
  const { t } = useTranslation(['login']);
  const {
    handleSubmit,
    control,
    setError,
    formState: { isSubmitting },
  } = useForm<FormFriendly<MfaSetupFields>>({
    resolver: superstructResolver(MfaSetupValidation),
  });

  return (
    <FormContainer
      onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
      {secret && (
        <QRCode
          value={`otpauth://totp/AWSCognito:${username}?secret=${secret}&issuer=${issuer}`}
        />
      )}
      <FormTextField
        fullWidth
        name="code"
        label={t('login:form.code')}
        control={control}
        t={t}
      />
      <Box sx={{ mt: 1, mb: 2 }} />
      <Button
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
        disabled={isSubmitting}>
        {t('login:form.submit')}
      </Button>
    </FormContainer>
  );
};

const FormContainer = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '> div': {
    marginBottom: theme.spacing(3),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));
