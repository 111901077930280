import type { AlertProps } from '@mui/material';
import React, { useContext } from 'react';
import { SnackbarMessageOptionalKey } from './snackbar-pack-state';

interface SnackbarPackContext {
  enqueueSnackbar(options: SnackbarMessageOptionalKey): string;

  enqueueSnackbar(message: string, severity: AlertProps['severity']): string;
  readonly closeSnackbar: (id: string) => void;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const SnackbarPackContext = React.createContext<SnackbarPackContext>();

export function useSnackbar() {
  return useContext(SnackbarPackContext);
}
