import { Box, Button, styled } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { UseFormSetError } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { FormTextField } from '../../../../components';
import { superstructResolver, FormFriendly } from '../../../../form';
import {
  ForgotPasswordFields,
  ForgotPasswordValidation,
} from './ForgotPasswordForm.validation';

interface Props {
  readonly onSubmit: (
    values: ForgotPasswordFields,
    setError: UseFormSetError<ForgotPasswordFields>
  ) => Promise<void>;
}

export const ForgotPasswordForm: React.FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation(['login']);
  const {
    handleSubmit,
    control,
    setError,
    formState: { isSubmitting, errors, submitCount },
  } = useForm<FormFriendly<ForgotPasswordFields>>({
    resolver: superstructResolver(ForgotPasswordValidation, { coerce: true }),
  });
  const hasSubmitted = submitCount > 0;

  return (
    <FormContainer
      onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
      <FormTextField
        fullWidth
        type="email"
        name="email"
        label={t('login:form.email')}
        control={control}
        t={t}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={
            isSubmitting || (hasSubmitted && Object.keys(errors).length > 0)
          }>
          {t('login:form.reset')}
        </Button>
      </Box>
    </FormContainer>
  );
};

const FormContainer = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '> div': {
    marginBottom: theme.spacing(3),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));
