import { styled } from '@b7hio/007/src/stitches.config';
import React from 'react';
import { LoadingSpinnerImage } from './LoadingSpinnerImage';

export function LoadingSpinner() {
  return (
    <PageWrapper>
      <LoadingSpinnerImage />
      <StyledParagraph>Loading...</StyledParagraph>
    </PageWrapper>
  );
}

const PageWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '$5',
});

const StyledParagraph = styled('p', {
  color: '$navyBlue10',
  fontSize: '$2',
});
