import { Alert, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import {
  CreateNewPasswordSubmitProps,
  LoginSubmitProps,
  MfaAskSubmitProps,
  MfaCodeSubmitProps,
  MfaLoginAskProps,
  MfaLoginCodeSubmitProps,
  MfaOptionsSubmitProps,
  MfaSmsNumberSubmitProps,
} from './auth.interface';
import { AuthStage, AuthState } from './Auth.state';
import {
  LoginForm,
  MfaOptionsLogin,
  MfaOptionsEnum,
  MfaOptionsSetup,
  MfaSmsCodeForm,
  MfaAskForm,
  MfaSmsNumberForm,
  MfaSuccess,
  MfaAuthAppForm,
  CreateNewPasswordForm,
} from './components';
import { BondsmithUser } from './hooks';

interface Props {
  state: AuthState;
  cognitoUser?: BondsmithUser;
  error?: string;
  submitting: boolean;
  onLoginSubmit: ({
    email,
    password,
    redirect,
  }: LoginSubmitProps) => Promise<void>;
  onCreateNewPasswordSubmit: ({
    password,
  }: CreateNewPasswordSubmitProps) => Promise<void>;
  onMfaAskSubmit: ({ setup }: MfaAskSubmitProps) => Promise<void>;
  onMfaOptionsSubmit: ({ options }: MfaOptionsSubmitProps) => Promise<void>;
  onMfaSmsNumberSubmit: ({
    phoneNumber,
    resend,
  }: MfaSmsNumberSubmitProps) => Promise<void>;
  onMfaSmsCodeSubmit: ({
    code,
    setMfaPreference,
  }: MfaCodeSubmitProps) => Promise<void>;
  onMfaSmsCodeResend: () => Promise<void>;
  onMfaAuthAppSubmit: ({
    code,
    setMfaPreference,
  }: MfaCodeSubmitProps) => Promise<void>;
  onMfaLoginAsk: ({ value, resend }: MfaLoginAskProps) => Promise<void>;
  onMfaLoginCodeSubmit: ({
    code,
    redirect,
  }: MfaLoginCodeSubmitProps) => Promise<void>;
  onMfaLoginSmsCodeResend: () => Promise<void>;
  onGoToStage: (stage: AuthStage) => void;
}

export default function AuthPage({
  state,
  cognitoUser,
  error,
  submitting,
  onLoginSubmit,
  onCreateNewPasswordSubmit,
  onMfaAskSubmit,
  onMfaOptionsSubmit,
  onMfaSmsNumberSubmit,
  onMfaSmsCodeSubmit,
  onMfaAuthAppSubmit,
  onMfaLoginAsk,
  onMfaLoginCodeSubmit,
  onGoToStage,
}: Props) {
  const { t } = useTranslation(['login']);
  const router = useRouter();

  // Email and tempPass query parameters are used to prefill the login form
  // TODO: Updating `u` to `email` will require a change to email templates in Cognito
  // TODO: Deprecate `u` after a few days after this change is deployed to production, because old links will suddenly cease to work otherwise
  const { u, email, tempPass } = router.query;

  const AlertError = error && (
    <Alert color="error" sx={{ mb: 4 }}>
      {t(error as never) || error}
    </Alert>
  );

  return (
    <>
      <NextSeo title={t('login:page.title')} />

      {state.stage === AuthStage.Initial && (
        <>
          <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
            {t('login:stage.login.title')}
          </Typography>
          {AlertError}
          {/* Casting to string because we know there will be either a string or undefined value */}
          <LoginForm
            onSubmit={onLoginSubmit}
            isSubmitting={submitting}
            forceEmail={email ? (email as string) : (u as string)}
            forcePassword={tempPass as string}
          />
        </>
      )}
      {state.stage === AuthStage.CreateNewPassword &&
        cognitoUser !== undefined && (
          <>
            <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
              {t('login:stage.createNewPassword.title')}
            </Typography>
            {AlertError}
            <CreateNewPasswordForm
              onSubmit={onCreateNewPasswordSubmit}
              isSubmitting={submitting}
              onGoBack={() => onGoToStage(AuthStage.Initial)}
            />
          </>
        )}

      {state.stage === AuthStage.MfaAsk && cognitoUser !== undefined && (
        <>
          <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
            {t('login:stage.mfaAsk.title')}
          </Typography>
          {AlertError}
          <MfaAskForm onSubmit={onMfaAskSubmit} />
        </>
      )}

      {state.stage === AuthStage.MfaOptions && cognitoUser !== undefined && (
        <>
          <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
            {t('login:stage.mfaOptions.title')}
          </Typography>
          {AlertError}
          <MfaOptionsSetup
            onSubmit={onMfaOptionsSubmit}
            onGoBack={() => onGoToStage(AuthStage.MfaAsk)}
          />
        </>
      )}

      {state.stage === AuthStage.MfaSmsNumber && cognitoUser !== undefined && (
        <>
          <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
            {t('login:stage.mfaSmsNumber.title')}
          </Typography>
          {AlertError}
          <MfaSmsNumberForm
            isSubmitting={submitting}
            onSubmit={onMfaSmsNumberSubmit}
            onGoBack={() => onGoToStage(AuthStage.MfaOptions)}
          />
        </>
      )}

      {state.stage === AuthStage.MfaSmsCode && cognitoUser !== undefined && (
        <>
          <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
            {t('login:stage.mfaSmsCode.title')}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {t('login:stage.mfaSmsCode.content.weHaveSent', {
              phoneNumber: state.phoneNumber,
            })}
          </Typography>
          {AlertError}
          <MfaSmsCodeForm
            lastResubmit={state.lastResubmit}
            isSubmitting={submitting}
            setMfaPreference={true}
            onSubmit={onMfaSmsCodeSubmit}
            onResend={() =>
              onMfaSmsNumberSubmit({
                phoneNumber: state?.phoneNumber ?? '',
                resend: true,
              })
            }
            onGoBack={() => onGoToStage(AuthStage.MfaSmsNumber)}
          />
        </>
      )}

      {state.stage === AuthStage.MfaAuthApp && cognitoUser !== undefined && (
        <>
          <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
            {t('login:stage.mfaAuthApp.title')}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {t('login:stage.mfaAuthApp.content.instructions')}
          </Typography>
          <MfaAuthAppForm
            username={cognitoUser.getUsername()}
            secret={state.mfaSecret}
            issuer="bondsmith"
            login={false}
            isSubmitting={submitting}
            setMfaPreference={true}
            onSubmit={onMfaAuthAppSubmit}
            onSkip={() => onGoToStage(AuthStage.MfaSuccess)}
          />
        </>
      )}

      {/* Login MFA */}
      {state.stage === AuthStage.MfaLoginAsk && state.mfaUserMethodsAvailable && (
        <>
          <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
            {t('login:stage.mfaOptions.title')}
          </Typography>
          <MfaOptionsLogin
            mfaMethods={state.mfaUserMethodsAvailable}
            onSubmit={onMfaLoginAsk}
            onGoBack={() => onGoToStage(AuthStage.Initial)}
          />
        </>
      )}

      {state.stage === AuthStage.MfaLoginSms && (
        <>
          <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
            {t('login:stage.mfaSmsCode.title')}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {t('login:stage.mfaSmsCode.content.weHaveSent', {
              phoneNumber: state.phoneNumber,
            })}
          </Typography>

          <MfaSmsCodeForm
            onSubmit={({ code }) =>
              onMfaLoginCodeSubmit({ code, redirect: true })
            }
            onGoBack={() =>
              onGoToStage(
                state?.mfaUserMethodsAvailable?.includes(MfaOptionsEnum.AuthApp)
                  ? AuthStage.MfaLoginAsk
                  : AuthStage.Initial
              )
            }
            onResend={() =>
              onMfaLoginAsk({ value: MfaOptionsEnum.Sms, resend: true })
            }
            lastResubmit={state.lastResubmit}
            isSubmitting={submitting}
          />
        </>
      )}

      {state.stage === AuthStage.MfaLoginAuthApp && (
        <>
          <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
            {t('login:stage.mfaAuthApp.titleLogin')}
          </Typography>
          <MfaAuthAppForm
            onSubmit={({ code }) =>
              onMfaLoginCodeSubmit({ code, redirect: true })
            }
            login={true}
            onGoBack={() => onGoToStage(AuthStage.MfaLoginAsk)}
            isSubmitting={submitting}
          />
        </>
      )}

      {state.stage === AuthStage.MfaSuccess && <MfaSuccess />}
    </>
  );
}
