import type {
  AutocompleteProps as MuiAutocompleteProps,
  AutocompleteRenderInputParams,
  InputProps,
} from '@mui/material';
import { Autocomplete as MuiAutocomplete, TextField } from '@mui/material';
import type { ChipTypeMap } from '@mui/material/Chip';
import React from 'react';
import type { UseControllerProps } from 'react-hook-form';
import { useController } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';
import type { TFunction } from 'react-i18next';
import { getFieldErrors } from '../../../form';
import { CustomMuiTextFieldCss } from '../../../utils/CustomMuiTextFieldCss';

interface AutocompleteProps<
  FV extends FieldValues,
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
> extends Omit<
      MuiAutocompleteProps<
        T,
        Multiple,
        DisableClearable,
        FreeSolo,
        ChipComponent
      >,
      'defaultValue' | 'onChange' | 'renderInput'
    >,
    UseControllerProps<FV> {
  readonly label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly t: TFunction<any>;
  readonly InputProps?: (
    params: AutocompleteRenderInputParams['InputProps']
  ) => InputProps;
  readonly customMuiTextField?: boolean;
}

export function FormAutocomplete<
  TFieldValues extends FieldValues,
  Options,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(
  props: AutocompleteProps<
    TFieldValues,
    Options,
    Multiple,
    DisableClearable,
    FreeSolo
  >
) {
  const { label, t, options, InputProps, customMuiTextField, ...other } = props;
  const {
    field: { ref, ...field },
    formState,
  } = useController(props);
  const { error, helperText } = getFieldErrors(field.name, formState, t);

  const findOptionByValue = (val: string) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options.find((option: any) => option?.value === val || option === val);
  let value: any = field.value;

  if (typeof options[0] === 'object' && typeof value !== 'object') {
    value = findOptionByValue(value);
  }

  if (other.multiple) {
    if (!value) {
      value = [];
    }

    value = value.map(findOptionByValue);
  }

  return (
    <MuiAutocomplete
      {...other}
      options={options}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(_: unknown, opts: any) => {
        if (other.multiple) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          field.onChange(opts.map((option: any) => option.value ?? option));
        } else {
          field.onChange(opts?.value || opts);
        }
      }}
      value={value || (other.multiple ? [] : null)}
      disabled={other.disabled || formState.isSubmitting}
      renderInput={(params) => (
        <TextField
          sx={customMuiTextField ? CustomMuiTextFieldCss : {}}
          {...params}
          label={label}
          inputRef={ref}
          error={error}
          helperText={helperText}
          InputProps={InputProps?.(params.InputProps) || params.InputProps}
          variant={customMuiTextField ? 'filled' : 'outlined'}
        />
      )}
    />
  );
}
