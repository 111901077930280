import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GB from 'date-fns/locale/en-GB';
import { DateTime } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';
import type { DayClickEventHandler } from 'react-day-picker';
// I'm not entirely sure why, however, when compiling an app it's unable to find the declaration file for
//  this lib and says it cannot find `useIMask` in the module.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useIMask } from 'react-imask';
import { styled } from '../../stitches.config';
import { Calendar } from '../Calendar';
import { IconButton } from '../IconButton';
import { Popover, PopoverAnchor, PopoverContent } from '../Popover';
import { TextField } from '../TextField/TextField';

type TextFieldProps = Omit<
  React.ComponentProps<typeof TextField>,
  'placeholder' | 'inputRef' | 'value' | 'onChange' | 'endAdornment'
>;

type Props = {
  readonly onChange: (value: string) => void;
} & TextFieldProps;

export const DatePicker: React.FC<Props> = ({ onChange, ...props }) => {
  const isMounted = useRef(false);
  const { ref, maskRef } = useIMask({
    mask: Date,
    pattern: 'd{/}`m{/}`Y',
    lazy: true,
    format: (date: DateTime) => {
      return date.toLocaleString(DateTime.DATE_SHORT);
    },
    parse: (str: string) => {
      return DateTime.fromFormat(str, 'dd/MM/yyyy');
    },
  });
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);
  const parsedDate = DateTime.fromFormat(value, 'dd/MM/yyyy');
  const dateValue = parsedDate.isValid ? parsedDate : undefined;

  const handleToggleOpen = () => setOpen((o) => !o);
  const handleDayClick: DayClickEventHandler = (date) => {
    const newValue = DateTime.fromJSDate(date).toLocaleString(
      DateTime.DATE_SHORT
    );

    maskRef.current.value = newValue;
    maskRef.current.updateValue();
    setValue(newValue);
    setOpen(false);
  };

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    onChange(dateValue?.toLocaleString(DateTime.DATE_SHORT) ?? '');
  }, [dateValue]);

  return (
    <Popover open={open} onOpenChange={handleToggleOpen}>
      <PopoverAnchor asChild>
        <TextField
          {...props}
          placeholder="dd/mm/yyyy"
          inputRef={ref}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          endAdornment={
            <IconButton static onClick={handleToggleOpen}>
              <FontAwesomeIcon icon={['fas', 'calendar']} />
            </IconButton>
          }
        />
      </PopoverAnchor>
      <PopoverContent sideOffset={8}>
        <StyledCalendar
          locale={GB}
          mode="single"
          selected={dateValue?.toJSDate()}
          month={dateValue?.toJSDate()}
          onDayClick={handleDayClick}
        />
      </PopoverContent>
    </Popover>
  );
};

const StyledCalendar = styled(Calendar, {
  margin: '-$3',
});
