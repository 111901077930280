import { Box, Button, styled } from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { superstructResolver, FormFriendly } from '../../../../form';
import {
  MfaSmsNumberFields,
  MfaSmsNumberFormValidation,
} from './MfaSmsNumberForm.validation';
import { MfaSmsNumberSubmitProps } from '../../auth.interface';

interface Props {
  readonly onSubmit: ({
    phoneNumber,
    resend,
  }: MfaSmsNumberSubmitProps) => Promise<void>;
  readonly resend?: boolean;
  readonly isSubmitting: boolean;
  readonly onGoBack: () => void;
}

export const MfaSmsNumberForm = ({
  onSubmit,
  resend,
  isSubmitting,
  onGoBack,
}: Props): JSX.Element => {
  const { t } = useTranslation(['login']);
  const { handleSubmit, control, setValue, formState } = useForm<
    FormFriendly<MfaSmsNumberFields>
  >({
    resolver: superstructResolver(MfaSmsNumberFormValidation),
    defaultValues: {
      phoneNumber: '',
    },
  });

  const handlePhoneNumberChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string
  ) => {
    setValue('phoneNumber', e.toString());
  };

  return (
    <FormContainer
      onSubmit={handleSubmit((values) =>
        onSubmit({
          phoneNumber: values.phoneNumber,
          resend: resend !== undefined ? resend : false,
        })
      )}>
      <Controller
        render={({ field }) => (
          <MuiPhoneNumber
            {...field}
            sx={{
              '& .MuiPhoneNumber-flagButton > svg': {
                width: '1.8rem',
              },
            }}
            defaultCountry="gb"
            variant="outlined"
            label={t('login:form.phoneNumber')}
            onlyCountries={['gb']}
            error={!!formState.errors.phoneNumber?.message}
            onChange={handlePhoneNumberChange}
          />
        )}
        control={control}
        name="phoneNumber"
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1rem',
          justifyContent: 'center',
        }}>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={isSubmitting}>
          {t('login:form.confirm')}
        </Button>
        <Button
          color="primary"
          variant="text"
          disabled={isSubmitting}
          onClick={onGoBack}>
          {t('login:form.back')}
        </Button>
      </Box>
    </FormContainer>
  );
};

const FormContainer = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '> div': {
    marginBottom: theme.spacing(3),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));
