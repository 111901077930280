import type * as Stitches from '@stitches/react';
import { createStitches } from '@stitches/react';
import { colors } from './theme/colors';
import type { Colors } from './theme/colors';

const sansBackupFontFamily = [
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];

const robotoFontFamily = ['Roboto', ...sansBackupFontFamily].join(',');
const interFontFamily = ['Inter', ...sansBackupFontFamily].join(',');

/**
 * Produce a string reference to the css variable as defined by `@stitches`
 * @param color Name of the color
 * @returns Formatted reference to a defined css variable
 */
export function $colors<C extends keyof Colors>(
  color: C
): `$colors$${C & string}` {
  return `$colors$${color}`;
}

export const { styled, globalCss, config } = createStitches({
  prefix: 'b7h',
  utils: {
    marginX: (value: string) => ({
      marginLeft: value,
      marginRight: value,
    }),
    marginY: (value: string) => ({
      marginTop: value,
      marginBottom: value,
    }),
    paddingX: (value: string) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    paddingY: (value: string) => ({
      paddingTop: value,
      paddingBottom: value,
    }),

    // A property for applying width/height together
    size: (value: string) => ({
      width: value,
      height: value,
    }),
    linearGradient: (value: string) => ({
      backgroundImage: `linear-gradient(${value})`,
    }),
  },

  media: {
    xs: '(max-width: 400px)',
    sm: '(max-width: 600px)',
    md: '(max-width: 900px)',
    lg: '(max-width: 1200px)',
    xl: '(max-width: 1536px)',
  },
  theme: {
    colors: {
      paperBg: '#ffffff',
      hiContrast: '$gray12',
      loContrast: 'white',
      primary: '$brand9',
      ...colors,
    },
    fonts: {
      sansHeader: interFontFamily,
      sans: robotoFontFamily,
    },
    // based on: https://type-scale.com/?size=16&scale=1.250&text=A%20Visual%20Type%20Scale&font=Overpass&fontweight=400&bodyfont=body_font_default&bodyfontweight=400&lineheight=1.75&backgroundcolor=%23ffffff&fontcolor=%23000000&preview=false
    fontSizes: {
      1: '1rem',
      2: '1.25rem',
      3: '1.563rem',
      4: '1.953rem',
      5: '2.441rem',
      6: '3.052rem',
      14: '0.875rem',
    },
    space: {
      1: '4px',
      2: '8px',
      3: '16px',
      4: '32px',
      5: '64px',
      6: '128px',
    },
    sizes: {
      1: '4px',
      2: '8px',
      3: '16px',
      4: '32px',
      5: '64px',
      6: '128px',
    },
    radii: {
      1: '4px',
      2: '8px',
      3: '16px',
      round: '9999px',
    },
    fontWeights: {
      1: 'lighter',
      2: 'normal',
      3: '600',
      4: 'bold',
      5: '500',
    },
    lineHeights: {},
    letterSpacings: {
      1: '0.029em',
    },
    borderWidths: {
      1: '1px',
      2: '2px',
    },
    borderStyles: {
      1: 'solid',
      2: 'none',
    },
    borders: {
      divider: '$borderWidths$1 $borderStyles$1 $colors$gray6',
      thickDivider: '$borderWidths$2 $borderStyles$1 $colors$gray6',
    },
    shadows: {
      panel: `0px 3px 8px ${$colors('shadow3')}`,
      dialog: `0px 5px 30px ${$colors('shadow2')}`,
      textField: '',
      header: '0px 6px 12px $colors$gray15',
      roundedButton: '0px 4px 12px',
      roundedButtonHover: '0px 6px 12px',
      containedButton: '0px 1px 3px',
      containedButtonHover: '0px 2px 8px',
      iconInner: '0px 0px 6px 0px $colors$gray7 inset',
      card: '0px 3px 4px $colors$gray8',
      popover: `0px 3px 8px ${$colors('shadow3')}`,
    },
    gradients: {
      blueGradient:
        'linear-gradient(90deg, $colors$brandGradientStart 0%, $colors$brandGradientEnd 100%)',
      navyGradient:
        'linear-gradient(90deg, $colors$navyBlueGradientStart 0%, $colors$navyBlueGradientEnd 100%)',
      plumGradient:
        'linear-gradient(90deg, $colors$plumGradientStart 0%, $colors$plumGradientEnd 100%)',
      headerBlueGradient:
        'linear-gradient(90deg, $colors$brandGradientStart 55%, $colors$brandGradientEnd 100%)',
      buttonBlueGradient:
        'linear-gradient(180deg, $colors$brandGradientStart 0%, $colors$brandGradientEnd 100%)',
      buttonDarkBlueGradient:
        'linear-gradient(180deg, $colors$navyBlueGradientStart 0%, $colors$navyBlue10 100%)',
    },
    zIndices: {},
    transitions: {
      1: '$transitionDurations$1 $transitionTimings$1',
      2: '$transitionDurations$2 $transitionTimings$1',
      3: '$transitionDurations$3 $transitionTimings$1',
    },
    transitionDurations: {
      1: '100ms',
      2: '300ms',
      3: '400ms',
    },
    transitionTimings: {
      1: 'cubic-bezier(0.65, 0, 0.35, 1)',
    },
  },
});

export type CSS = Stitches.CSS<typeof config>;
