import CloseOutlined from '@mui/icons-material/CloseOutlined';
import type { DialogProps } from '@mui/material';
import { Box, Dialog, Divider, IconButton, Typography } from '@mui/material';
import React from 'react';

type Props = {
  readonly id: string;
  readonly open?: boolean;
  readonly title: string;
  readonly maxWidth?: DialogProps['maxWidth'];
  readonly fullWidth?: boolean;
  readonly onClose: (event: object, reason: string) => void;
  readonly skipClose?: boolean;
  readonly onCloseEnd?: () => void;
};

export const FeatureDialog: React.FC<Props> = ({
  id,
  open,
  title,
  maxWidth,
  fullWidth,
  onClose,
  skipClose = false,
  onCloseEnd,
  children,
}) => {
  return (
    <Dialog
      data-testid={id}
      open={open || false}
      onClose={(e) => onClose(e, skipClose ? 'skip' : 'check')}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      PaperProps={{
        sx: {
          p: 2,
          width:
            maxWidth || fullWidth
              ? undefined
              : { xs: '100vw', md: '50vw', lg: '40vw' },
          display: 'grid',
          gridGap: (theme) => theme.spacing(2),
          gridTemplateAreas: '"title close" "divider divider" "body body"',
          gridTemplateColumns: '1fr 40px',
          gridAutoRows: 'min-content min-content 1fr',
        },
      }}
      TransitionProps={{
        addEndListener: () => {
          if (!open && onCloseEnd) {
            onCloseEnd();
          }
        },
      }}>
      <IconButton
        aria-label="close"
        onClick={(e) => onClose(e, skipClose ? 'skip' : 'check')}
        sx={{
          gridArea: 'close',
        }}>
        <CloseOutlined />
      </IconButton>
      <Typography
        variant="h5"
        sx={{ gridArea: 'title', alignItems: 'center', display: 'flex' }}>
        {title}
      </Typography>
      <Divider sx={{ mx: -2, gridArea: 'divider' }} />
      <Box sx={{ gridArea: 'body', overflow: 'auto', m: -2, p: 2 }}>
        {children}
      </Box>
    </Dialog>
  );
};
