import { styled } from '../../stitches.config';

export const Button = styled('button', {
  $$textColor: '$colors$loContrast',
  $$bgColor: '$colors$primary',
  $$outline: 'none',
  $$outlineColor: 'none',
  $$borderRadius: '$radii$1',
  $$shadow: 'none',
  $$fontWeight: '$2',
  $$outlineWidth: '1px',
  $$fontSize: '$1',
  $$padding: '$space$2 $space$3',
  // Interactive
  $$hoverBgColor: '$colors$brand10',
  $$hoverShadow: 'none',
  $$hoverOutline: 'none',
  $$hoverTextColor: '$colors$loContrast',
  $$hoverOutlineWidth: 'unset',
  // Active
  $$activeBgColor: '$colors$brand11',
  $$activeShadow: 'none',
  // Disabled
  $$disabledBgColor: '$colors$gray5',
  $$disabledTextColor: '$colors$gray10',

  border: 'none',
  color: '$$textColor',
  padding: '$$padding',
  background: '$$bgColor',
  outline: '$$outline',
  outlineColor: '$$outlineColor',
  outlineStyle: '$$outlineStyle',
  borderRadius: '$$borderRadius',
  whiteSpace: 'nowrap',
  transition:
    'box-shadow $transitions$1, background $transitions$1, transform $transitions$1, outline $transitions$1',
  fontWeight: '$$fontWeight',
  fontSize: '$$fontSize',
  boxShadow: '$$shadow',
  cursor: 'pointer',
  textShadow: '0.5px 0 0 currentColor',
  textTransform: '$$textTransform',
  letterSpacing: '$letterSpacings$1',

  // Interactive
  '&:hover, &:focus': {
    background: '$$hoverBgColor',
    boxShadow: '$$hoverShadow',
    outlineColor: '$$hoverOutline',
    color: '$$hoverTextColor',
    outlineWidth: '$$hoverOutlineWidth',
  },

  '&:active': {
    background: '$$activeBgColor',
    boxShadow: '$$activeShadow',
    transform: 'translateY(0.07rem)',
    outlineColor: '$$hoverOutline',
    color: '$$hoverTextColor',
  },
  '&:disabled': {
    pointerEvents: 'none',
    background: '$$disabledBgColor',
    color: '$$disabledTextColor',
  },

  variants: {
    variant: {
      outlined: {
        $$outlineStyle: 'auto',
        $$bgColor: 'transparent',
        $$textColor: '$colors$brand9',
        $$outline: '$$outlineWidth $borderStyles$1',
        $$outlineColor: '$colors$brand7',

        // Hover
        $$hoverOutline: '$colors$brand8',
        $$hoverBgColor: 'transparent',
        $$hoverTextColor: '$colors$brand11',
        $$hoverOutlineWidth: '2px',

        // Active
        $$activeBgColor: '$colors$brand3',
        '&:disabled': {
          $$outlineStyle: 'solid',
          $$outline: '$$outlineWidth $borderStyles$1',
          $$bgColor: 'transparent',
          $$textColor: '$colors$gray9',
          $$outlineColor: '$colors$gray8',
        },
      },
      contained: {
        $$bgColor: '$colors$primary',
        $$shadow: '$shadows$containedButton $colors$brandContainedButton',
        // Hover
        $$hoverBgColor: '$colors$brand10',
        $$hoverShadow:
          '$shadows$containedButtonHover $colors$brandContainedButtonHover',
        // Active
        $$activeBgColor: '$colors$brand11',
        $$activeShadow:
          '$shadows$containedButton $colors$brandContainedButtonActive',
        '&:disabled': {
          $$shadow: 'transparent',
          // Hover
          $$hoverShadow: 'transparent',
          // Active
          $$activeShadow: 'transparent',
        },
      },
      text: {
        $$bgColor: 'transparent',
        $$textColor: '$colors$brand10',
        // Hover
        $$hoverBgColor: '$colors$brand3',
        $$hoverTextColor: '$colors$brand11',
        // Active
        $$activeBgColor: '$colors$brand4',
        '&:disabled': {
          $$bgColor: 'transparent',
          $$textColor: '$colors$gray9',
        },
      },
      tonal: {
        $$bgColor: '$colors$brand3',
        $$textColor: '$colors$brand10',
        // Hover
        $$hoverBgColor: '$colors$brand4',
        $$hoverTextColor: '$colors$brand10',
        // Active
        $$activeBgColor: '$colors$brand5',
        '&:disabled': {
          $$bgColor: '$colors$gray5',
          $$textColor: '$colors$gray10',
        },
      },
      navbar: {
        $$bgColor: '$colors$navyBlue1',
        $$textColor: '$colors$navyBlue8',
        // Hover
        $$hoverBgColor: '$colors$brand3',
        $$hoverTextColor: '$colors$brand10',
        // Active
        $$activeBgColor: '$colors$brand4',
      },
      special: {
        $$bgColor: 'transparent',
        $$textColor: '$colors$navyBlue8',
        // Hover
        $$hoverTextColor: '$colors$navyBlue9',
        $$hoverBgColor: 'transparent',
        // Active
        $$activeBgColor: 'transparent',
      },
      inverted: {
        $$bgColor: '$colors$loContrast',
        $$textColor: '$colors$primary',
        $$shadow: 'none',
        // Hover
        $$hoverBgColor: '$colors$brand1',
        $$hoverShadow: '0px 2px 8px $colors$brandShadow',
        $$hoverTextColor: '$colors$brand10',
        // Active
        $$activeBgColor: '$colors$brand2',
        $$activeShadow: '0px 2px 8px $colors$brandShadow',
      },
      underline: {
        $$bgColor: 'transparent',
        $$textColor: '$colors$gray11',
        borderBottom: '$borderWidths$2 $borderStyles$1 $gray6',
        $$borderRadius: '0',
        // Hover
        $$hoverBgColor: 'transparent',
        $$hoverTextColor: '$colors$primary',
        '&:hover ': {
          borderBottom: '$borderWidths$2 $borderStyles$1 currentColor',
        },
        // Active
        $$activeBgColor: '$colors$brand3',
        '&:active ': {
          $$hoverTextColor: '$colors$brand11',
          borderBottom: '$borderWidths$2 $borderStyles$1 currentColor',
        },
        // Selected
        '&:selected': {
          $$bgColor: 'transparent',
          $$textColor: '$colors$primary',
          borderBottom: '$borderWidths$2 $borderStyles$1 currentColor',
        },
      },
    },
    color: {
      brand: {
        $$textColor: '$colors$brand10',
        $$hoverTextColor: '$colors$navyBlue10',
      },
      red: {
        $$bgColor: '$colors$red9',
        $$textColor: '$colors$loContrast',

        $$hoverBgColor: '$colors$red10',
        $$hoverTextColor: '$colors$loContrast',
        $$activeBgColor: '$colors$red11',
      },
      loContrast: {
        $$textColor: '$colors$loContrast',
      },
      success: {
        $$bgColor: '$colors$tonalSuccess',
        $$textColor: '$colors$green10',

        $$hoverBgColor: '$colors$green5',
        $$hoverTextColor: '$colors$green11',
        $$activeBgColor: '$colors$green6',
      },
      gray: {
        $$textColor: '$colors$gray11',
      },
      gradient: {
        $$bgColor: '$gradients$buttonBlueGradient',
        $$textColor: '$colors$loContrast',

        $$hoverBgColor: '$gradients$buttonDarkBlueGradient',
        $$hoverTextColor: '$colors$loContrast',
        $$activeBgColor: '$gradients$buttonDarkBlueGradient',
      },
      navyBlue: {
        $$bgColor: '$colors$navyBlue11',
        $$textColor: '$colors$loContrast',

        $$hoverBgColor: '$colors$navyBlueGradientEnd',
        $$hoverTextColor: '$colors$loContrast',
        $$activeBgColor: '$colors$navyBlueGradientEnd',
      },
    },
    semiBold: {
      true: {
        textShadow: '0.5px 0 0 currentColor',
      },
    },
    bold: {
      true: {
        $$fontWeight: '$fontWeights$5',
      },
    },
    fullWidth: {
      true: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
      },
    },
    small: {
      true: {
        $$fontSize: '13px',
        $$padding: '$space$2 $space$3',
      },
    },
    medium: {
      true: {
        $$fontSize: '14px',
      },
    },
    upperCase: {
      true: {
        $$textTransform: 'uppercase',
      },
      false: {
        $$textTransform: 'unset',
      },
    },
    large: {
      true: {
        $$fontSize: '15px',
        //TODO: check if using certain px is correct way in that case
        $$padding: '13px 23px',
      },
    },
    xLarge: {
      true: {
        $$fontSize: '$1',
        $$padding: '$space$3 $space$4',
      },
    },
    rounded: {
      true: {
        $$borderRadius: '$radii$round',
      },
    },
    startIcon: {
      true: {
        display: 'inline-flex',
        gap: '$2',
        alignItems: 'center',
        justifyContent: 'center',
        '& > svg': {
          fontSize: '$1',
          fill: 'currentColor',
        },
      },
      false: {
        '& svg': {
          display: 'none',
        },
      },
    },
  },
  compoundVariants: [
    {
      rounded: 'true',
      variant: 'navbar',
      startIcon: true,
      css: {
        $$padding:
          '$space$1 calc($space$3 + $space$1) $space$1 calc($space$3 - $space$1)',
        $$shadow: 'none',
        // Hover
        $$hoverShadow: 'none',
        // Active
        $$activeShadow: 'none',
      },
    },

    {
      variant: 'tonal',
      color: 'success',
      css: {
        $$shadow: 'none',
        // Hover
        $$hoverShadow: 'none',
        // Active
        $$activeShadow: 'none',
      },
    },

    {
      variant: 'text',
      color: 'gray',
      css: {
        $$hoverBgColor: 'transparent',
        $$hoverTextColor: '$colors$gray12',
        $$activeBgColor: 'transparent',
      },
    },
    {
      variant: 'inverted',
      rounded: 'true',
      css: {
        $$textColor: '$colors$primary',
        $$hoverShadow: '0px 2px 8px $colors$gray9',
        $$hoverTextColor: '$colors$brand10',
        // Active
        $$activeShadow: '0px 2px 8px $colors$gray9',
      },
    },
  ],
  defaultVariants: {
    variant: 'contained',
  },
});
