import { Box, Button, styled } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React from 'react';

interface MfaRemoveAskProps {
  onConfirm: () => Promise<void>;
  onCancel: () => void;
}

export const MfaRemoveAsk = ({ onConfirm, onCancel }: MfaRemoveAskProps) => {
  const { t } = useTranslation(['login']);

  return (
    <FormContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '1rem',
          justifyContent: 'space-between',
        }}>
        <Button color="primary" variant="outlined" fullWidth onClick={onCancel}>
          {t('login:form.cancel')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={onConfirm}>
          {t('login:form.confirm')}
        </Button>
      </Box>
    </FormContainer>
  );
};

const FormContainer = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '> div': {
    marginBottom: theme.spacing(3),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));
