import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group';
import { styled } from '../../stitches.config';

export const ButtonGroup = styled(ToggleGroupPrimitive.Root, {
  $$bgColor: '$colors$loContrast',
  $$border: '1px solid $colors$gray6',
  display: 'inline-flex',
  backgroundColor: '$$bgColor',
  border: '$$border',
  borderRadius: '$round',
  variants: {
    variant: {
      contained: {},
      tonal: {
        $$bgColor: '$colors$brand3',
        $$border: 'none',
      },
    },
  },
});

export const ButtonGroupItem = styled(ToggleGroupPrimitive.Item, {
  $$bgColor: '$colors$loContrast',
  $$bgColorOn: '$colors$primary',
  $$boxShadowOn: '$shadows$roundedButton',
  $$textColorOn: '$colors$loContrast',
  display: 'flex',
  alignItems: 'center',
  fontSize: 'calc($1 - 2px)',
  fontWeight: '$$fontWeight',
  justifyContent: 'center',
  margin: '$1',
  minHeight: '$4',
  borderRadius: '$round',
  background: '$$bgColor',
  padding: '$1 $3',
  border: 'none',
  outline: 'none',
  whiteSpace: 'nowrap',
  textShadow: '0.5px 0 0 currentColor',
  letterSpacing: '$letterSpacings$1',
  cursor: 'pointer',
  color: '$primary',
  transition:
    'box-shadow $transitions$1, background $transitions$1, transform $transitions$1, outline $transitions$1',
  '&:hover': {
    color: '$colors$brand11',
  },
  '&:focus': {
    background: 'colors$brand10',
    outline: 'none',
    border: 'none',
    boxShadow: 'unset',
  },
  '&[data-state=on]': {
    backgroundColor: '$$bgColorOn',
    boxShadow: '$$boxShadowOn',
    color: '$$textColorOn',
  },
  variants: {
    variant: {
      contained: {},
      tonal: {
        $$bgColor: '$colors$brand3',
        $$bgColorOn: '$colors$loContrast',
        $$boxShadowOn: '$shadows$containedButton $colors$gray7',
        $$textColorOn: '$colors$primary',
      },
    },
  },
  defaultVariants: {
    variant: 'contained',
  },
});
