import LoopOutlined from '@mui/icons-material/LoopOutlined';
import { Alert, Box, Button, styled, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormTextField } from '../../../../components';
import { superstructResolver, FormFriendly } from '../../../../form';
import { MfaCodeSubmitProps } from '../../auth.interface';
import {
  MfaSmsCodeFields,
  MfaSmsCodeFormValidation,
} from './MfaSmsCodeForm.validation';

interface Props {
  readonly onSubmit: ({
    code,
    setMfaPreference,
    redirect,
  }: MfaCodeSubmitProps) => Promise<void>;
  readonly setMfaPreference?: boolean;
  readonly onGoBack: () => void;
  readonly onResend: () => void;
  readonly isSubmitting: boolean;
  readonly lastResubmit?: Date;
}

export const MfaSmsCodeForm = ({
  onSubmit,
  setMfaPreference,
  onGoBack,
  onResend,
  lastResubmit,
  isSubmitting,
}: Props): JSX.Element => {
  const { t } = useTranslation(['login']);
  const { handleSubmit, control } = useForm<FormFriendly<MfaSmsCodeFields>>({
    resolver: superstructResolver(MfaSmsCodeFormValidation),
    defaultValues: {
      code: '',
    },
  });

  const [secondsRemaining, setSecondsRemaining] = useState(
    lastResubmit
      ? lastResubmit.getTime() + 10000 > new Date().getTime()
        ? 60
        : 0
      : 0
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSecondsRemaining((n) => n - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setSecondsRemaining(
      lastResubmit
        ? lastResubmit.getTime() + 10000 > new Date().getTime()
          ? 60
          : 0
        : 0
    );
  }, [lastResubmit]);

  return (
    <FormContainer
      onSubmit={handleSubmit((values) =>
        onSubmit({
          code: values.code,
          setMfaPreference:
            setMfaPreference !== undefined ? setMfaPreference : false,
        })
      )}>
      <FormTextField
        fullWidth
        name="code"
        type="text"
        label={t('login:form.code')}
        control={control}
        t={t}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1rem',
          justifyContent: 'center',
        }}>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={isSubmitting}>
          {t('login:form.confirm')}
        </Button>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
          }}>
          <Typography variant="body1">
            {t('login:stage.mfaSmsCode.content.didntReceiveCode')}
          </Typography>
          <Button
            color="primary"
            variant="text"
            startIcon={<LoopOutlined />}
            disabled={secondsRemaining > 0 || isSubmitting}
            onClick={onResend}>
            {t('login:stage.mfaSmsCode.content.resendIt')}
          </Button>
        </Box>
        {secondsRemaining > 0 && (
          <Alert severity="info">
            {t('login:stage.mfaSmsCode.content.resendItTime', {
              time:
                secondsRemaining < 10
                  ? `0${secondsRemaining}`
                  : secondsRemaining,
            })}
          </Alert>
        )}

        <Button
          color="primary"
          variant="text"
          disabled={isSubmitting}
          onClick={onGoBack}>
          {t('login:form.back')}
        </Button>
      </Box>
    </FormContainer>
  );
};

const FormContainer = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '> div': {
    marginBottom: theme.spacing(3),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));
