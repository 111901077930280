import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface State {
  readonly open: boolean;
  readonly container: boolean;
  readonly query: string;
  readonly inputValue: string;
}

export const initialState: State = {
  open: false,
  container: false,
  query: '',
  inputValue: '',
};

export const postcodeLookupSlice = createSlice({
  name: 'postcodeLookup',
  initialState,
  reducers: {
    searchByText(state, action: PayloadAction<string>) {
      if (!state.container) {
        state.query = action.payload;
      }

      state.inputValue = action.payload;

      if (!state.open) {
        state.open = true;
      }
    },
    searchByContainer(state, action: PayloadAction<string>) {
      state.container = true;

      state.inputValue = '';

      state.query = action.payload;
    },
    reset(state) {
      Object.assign(state, initialState);
    },
  },
});
