import { colorsHsla } from '@b7hio/007/src/theme/colors';
import { Alert, CircularProgress, Snackbar, Typography } from '@mui/material';
import type { AnyAction } from '@reduxjs/toolkit';
import React, { useEffect, useReducer } from 'react';
import {
  initialState,
  SnackbarErrorMessage,
  snackbarStackSlice,
} from './snackbar-pack-state';

export interface SnackbarPackRef {
  readonly dispatch: React.Dispatch<AnyAction>;
}

export const SnackbarPack = React.forwardRef<SnackbarPackRef | undefined>(
  (props, ref) => {
    const [state, rawDispatch] = useReducer(
      snackbarStackSlice.reducer,
      initialState
    );

    const dispatch: typeof rawDispatch = (action) => {
      if (
        snackbarStackSlice.actions.addItem.match(action) &&
        action.payload.async
      ) {
        action.payload.async
          .then((newMessage) => {
            rawDispatch(
              snackbarStackSlice.actions.completeItem({
                key: action.payload.key,
                message: newMessage,
                severity: 'success',
              })
            );
          })
          .catch((error: SnackbarErrorMessage) => {
            rawDispatch(
              snackbarStackSlice.actions.completeItem({
                key: action.payload.key,
                message: error.message,
                severity: 'error',
              })
            );

            throw error.originalError ?? error;
          });
      }

      rawDispatch(action);
    };

    useEffect(() => {
      if (typeof ref !== 'function' && ref) {
        ref.current = { dispatch };
      }
    }, [dispatch]);

    const handleClose = (
      event: React.SyntheticEvent | MouseEvent,
      reason?: string
    ) => {
      if (reason === 'clickaway') {
        return;
      }
      dispatch(snackbarStackSlice.actions.close());
    };

    const handleExited = () => {
      dispatch(snackbarStackSlice.actions.pop());
    };

    const message = state.messages[0];

    return (
      <Snackbar
        open={state.open}
        autoHideDuration={
          message?.inProgress ? undefined : message?.autoHideDuration ?? 7000
        }
        onClose={handleClose}
        // TransitionComponent={Slide}
        TransitionProps={{
          onExited: handleExited,
        }}>
        <Alert
          onClose={handleClose}
          severity={message?.severity}
          variant="filled"
          elevation={6}
          icon={
            message?.inProgress ? (
              <CircularProgress size="22px" color="inherit" />
            ) : undefined
          }
          sx={{
            width: '100%',
            '&.MuiAlert-filledSuccess': {
              background: colorsHsla('green3'),
              color: colorsHsla('green11'),
            },
            '&.MuiAlert-filledInfo': {
              background: colorsHsla('blue3'),
              color: colorsHsla('blue11'),
            },
            '&.MuiAlert-filledWarning': {
              background: colorsHsla('yellow3'),
              color: colorsHsla('yellow11'),
            },
            '&.MuiAlert-filledError': {
              background: colorsHsla('red3'),
              color: colorsHsla('red11'),
            },
            boxShadow: ' 0px 1px 2px hsla(202, 24%, 9%, 0.3)',
            transition: (theme) =>
              `${theme.transitions.create('all')} !important`,
          }}>
          <Typography
            variant="inherit"
            sx={{
              width: message?.width,
              transition: (theme) => theme.transitions.create('width'),
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}>
            {message?.message}
          </Typography>
        </Alert>
      </Snackbar>
    );
  }
);
