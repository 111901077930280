import type { Infer } from 'superstruct';
import { object, string } from 'superstruct';
import { notEmpty } from '../../../../form';

export const MfaPasswordConfirmFormValidation = object({
  password: notEmpty(string()),
});

export type MfaPasswordConfirmFields = Infer<
  typeof MfaPasswordConfirmFormValidation
>;
