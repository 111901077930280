import { colorsHsla } from '@b7hio/007/src/theme/colors';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  FilledInput,
} from '@mui/material';
import React from 'react';
import type { UseControllerProps } from 'react-hook-form';
import { useController } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';
import type { TFunction } from 'react-i18next';
import type { IMaskInputProps } from 'react-imask';
import { IMaskInput } from 'react-imask';
import { getFieldErrors } from '../../../form';
import { CustomMuiTextFieldCss } from '../../../utils/CustomMuiTextFieldCss';

export interface MaskedTextFieldProps<T extends FieldValues = FieldValues>
  extends UseControllerProps<T> {
  readonly label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly inputProps: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly t: TFunction<any>;
  readonly disabled?: boolean;
  readonly readOnly?: boolean;
  readonly helperText?: string;
  readonly customMuiTextField?: boolean;
  readonly getErrorPath?: (type: string) => string | undefined;
}

export const MaskedTextField = <T extends FieldValues>({
  t,
  label,
  inputProps,
  disabled,
  readOnly,
  helperText,
  getErrorPath,
  customMuiTextField,
  ...props
}: MaskedTextFieldProps<T>): JSX.Element => {
  const {
    field: { ref, ...field },
    formState,
  } = useController(props);
  const { error, helperText: errorHelperText } = getFieldErrors(
    field.name,
    formState,
    t,
    getErrorPath
  );

  return (
    <FormControl
      error={error}
      variant={customMuiTextField ? 'filled' : 'outlined'}
      disabled={disabled}
      sx={customMuiTextField ? CustomMuiTextFieldCss : {}}>
      <InputLabel
        sx={{
          '&.Mui-focused': {
            color: customMuiTextField ? colorsHsla('brand9') : '',
          },
        }}
        htmlFor={field.name}>
        {label}
      </InputLabel>
      {customMuiTextField ? (
        <FilledInput
          id={field.name}
          {...field}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          inputComponent={TextMask as any}
          inputProps={
            {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              lazy: !Boolean((formState.touchedFields as any)[field.name]),
              ...inputProps,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any
          }
          value={field.value}
          disabled={formState.isSubmitting || disabled}
          readOnly={readOnly}
          inputRef={ref}
        />
      ) : (
        <OutlinedInput
          id={field.name}
          {...field}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          inputComponent={TextMask as any}
          inputProps={
            {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              lazy: !Boolean((formState.touchedFields as any)[field.name]),
              ...inputProps,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any
          }
          value={field.value}
          disabled={formState.isSubmitting || disabled}
          readOnly={readOnly}
          inputRef={ref}
          label={label}
        />
      )}
      {!error && helperText && <FormHelperText>{helperText}</FormHelperText>}
      {error && <FormHelperText>{errorHelperText}</FormHelperText>}
    </FormControl>
  );
};

type CustomProps = IMaskInputProps & {
  readonly onChange: (event: {
    readonly target: { readonly name: string; readonly value: string };
  }) => void;
  readonly name: string;
};

const TextMask = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMask(props, ref) {
    const { onChange, ...other } = props;

    return (
      <IMaskInput
        unmask={true}
        {...other}
        inputRef={ref}
        onAccept={(value) => {
          onChange({ target: { name: props.name, value } });
        }}
        overwrite
      />
    );
  }
);
