import { toNestError, validateFieldsNatively } from '@hookform/resolvers';
import type {
  FieldError,
  FieldErrors,
  ResolverOptions,
  ResolverResult,
} from 'react-hook-form';
import { validate, Struct, StructError } from 'superstruct';

const parseErrorSchema = (error: StructError) =>
  error.failures().reduce<Record<string, FieldError>>((previous, err) => {
    previous[err.path.join('.')] = {
      message: err.message,
      type: `${err.type}${err.refinement ? `.${err.refinement}` : '.default'}`,
    };

    return previous;
  }, {});

export const superstructResolver: Resolver =
  (schema, resolverOptions) => (values, _, options) => {
    const result = validate(values, schema, resolverOptions);

    if (result[0]) {
      return {
        values: {},
        errors: toNestError(
          parseErrorSchema(result[0]),
          options
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ) as FieldErrors<any>,
      };
    }

    options.shouldUseNativeValidation && validateFieldsNatively({}, options);

    return {
      values: result[1],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      errors: {} as FieldErrors<any>,
    };
  };

type Options = Parameters<typeof validate>[2];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Resolver = <T extends Struct<any, any>, TFieldValues, TContext>(
  schema: T,
  factoryOptions?: Options
) => (
  values: unknown,
  context: TContext | undefined,
  options: ResolverOptions<TFieldValues>
) => ResolverResult<TFieldValues>;
