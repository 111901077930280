import type { Infer } from 'superstruct';
import { object, refine, string } from 'superstruct';
import { minSize, notEmpty } from '../../../../form';

export const ForgotPasswordCompleteValidation = object({
  code: notEmpty(string()),
  password: minSize(string(), 10, 'passwordRequirement'),
  passwordConfirm: refine(
    string(),
    'passwordComparer',
    (value, ctx) => ctx.branch[0].password === value
  ),
});

export type ForgotPasswordCompleteFields = Infer<
  typeof ForgotPasswordCompleteValidation
>;
