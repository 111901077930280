import '@b7hio/007/src/icons';
import { styled } from '@b7hio/007/src/stitches.config';
import { useTranslation } from 'next-i18next';
import { Logo } from '../Logo';
import { ErrorIllustration } from './ErrorIllustration';

export const ErrorPage404 = (): JSX.Element => {
  const { t } = useTranslation(['common']);

  return (
    <>
      <StyledWrapper>
        <MessageWrapper>
          <Logo variant="primary" sx={{ height: '50px' }} />
          <StyledHeading>{t('common:errors.404.title')}</StyledHeading>
          <StyledMessage>
            {t('common:errors.404.msg')}
            {'.'}
          </StyledMessage>
        </MessageWrapper>
        <ErrorIllustration />
      </StyledWrapper>
    </>
  );
};

const StyledWrapper = styled('div', {
  padding: '$3',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$3',
  '@md': {
    flexDirection: 'column',
  },
});

const MessageWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  '@md': {
    alignItems: 'center',
  },
});

const StyledHeading = styled('h2', {
  color: '$navyBlue10',
});

const StyledMessage = styled('h4', {});
