import type { CognitoUser } from 'amazon-cognito-identity-js';
import { Amplify } from 'aws-amplify';
import getConfig from 'next/config';

/* This file configures AWS Amplify so that we can use if for cognito authentication */
const { publicRuntimeConfig } = getConfig();

const identityPoolId =
  publicRuntimeConfig.NEXT_PUBLIC_COGNITO_IDENTITY_POOL_ID ||
  process.env.NEXT_PUBLIC_COGNITO_IDENTITY_POOL_ID ||
  '';
const userPoolId =
  publicRuntimeConfig.NEXT_PUBLIC_COGNITO_USER_POOL_ID ||
  process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID ||
  '';
const userPoolWebClientId =
  publicRuntimeConfig.NEXT_PUBLIC_COGNITO_APP_CLIENT_ID ||
  process.env.NEXT_PUBLIC_COGNITO_APP_CLIENT_ID ||
  '';
const region = userPoolId.split('_')[0];
const bucketName =
  publicRuntimeConfig.NEXT_PUBLIC_PUBLIC_ASSETS_BUCKET_NAME ||
  process.env.NEXT_PUBLIC_PUBLIC_ASSETS_BUCKET_NAME ||
  '';

Amplify.configure({
  Auth: {
    identityPoolId,
    region,
    userPoolId,
    userPoolWebClientId,
  },
  Storage: {
    AWSS3: {
      bucket: bucketName,
      customPrefix: {
        public: 'portal/',
      },
      region: 'eu-west-2',
      identityPoolId: identityPoolId,
    },
  },
  ssr: true,
});

export type BondsmithUser = CognitoUser & {
  attributes: {
    email: string;
    email_verified: boolean;
    family_name: string;
    given_name: string;
    phone_number: string;
    phone_number_verified: boolean;
    sub: string;
  };
};
