import { Box, Button, styled } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { UseFormSetError } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { FormTextField } from '../../../../components';
import { superstructResolver, FormFriendly } from '../../../../form';
import {
  ForgotPasswordCompleteFields,
  ForgotPasswordCompleteValidation,
} from './ForgotPasswordCompleteForm.validation';

interface Props {
  readonly onSubmit: (
    values: ForgotPasswordCompleteFields,
    setError: UseFormSetError<ForgotPasswordCompleteFields>
  ) => Promise<void>;
}

export const ForgotPasswordCompleteForm = ({
  onSubmit,
}: Props): JSX.Element => {
  const { t } = useTranslation(['login']);
  const {
    handleSubmit,
    setError,
    control,
    watch,
    formState: { isSubmitting },
  } = useForm<FormFriendly<ForgotPasswordCompleteFields>>({
    resolver: superstructResolver(ForgotPasswordCompleteValidation),
    defaultValues: {
      password: '',
      code: '',
      passwordConfirm: '',
    },
  });

  const newPassword = watch('password');

  return (
    <FormContainer
      onSubmit={handleSubmit((values) => onSubmit(values, setError))}>
      <FormTextField
        fullWidth
        label={t('login:form.code')}
        name="code"
        control={control}
        t={t}
      />
      <FormTextField
        fullWidth
        type="password"
        label={t('login:form.password')}
        name="password"
        control={control}
        t={t}
      />
      <FormTextField
        fullWidth
        type="password"
        label={t('login:form.passwordConfirm')}
        name="passwordConfirm"
        control={control}
        t={t}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={isSubmitting}>
          {t('login:form.submit')}
        </Button>
      </Box>
    </FormContainer>
  );
};

const FormContainer = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '> div': {
    marginBottom: theme.spacing(3),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));
