import type { Infer } from 'superstruct';
import { enums, object, string } from 'superstruct';
import { notEmpty } from '../../../../form';
import { MfaOptionsEnum } from '../MfaOptions';

export const MfaAuthAppFormValidation = object({
  code: notEmpty(string()),
});

export type MfaAuthAppFields = Infer<typeof MfaAuthAppFormValidation>;
