import TaskAlt from '@mui/icons-material/TaskAlt';
import { Box, Button, styled, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface MfaSuccessProps {
  readonly redirect?: string;
  readonly onAcknowledge?: () => void;
}

export const MfaSuccess = ({
  redirect,
  onAcknowledge,
}: MfaSuccessProps): JSX.Element => {
  const { t } = useTranslation(['login']);
  const router = useRouter();

  useEffect(() => {
    if (redirect) {
      setTimeout(() => {
        router.push('/');
      }, 3000);
    }
  }, []);

  return (
    <FormContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <TaskAlt height={64} width={64} color="success" />
        <Typography variant="h5" sx={{ mb: 2, mt: 2 }}>
          {t('login:stage.mfaSuccess.title')}
        </Typography>
        {redirect && (
          <Button variant="text" disabled>
            {t('login:stage.mfaSuccess.content.redirecting')}
          </Button>
        )}

        {!redirect && onAcknowledge && (
          <Button variant="text">
            {t('login:stage.mfaSuccess.content.acknowledge')}
          </Button>
        )}
      </Box>
    </FormContainer>
  );
};

const FormContainer = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '> div': {
    marginBottom: theme.spacing(3),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));
