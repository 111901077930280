import { Box, Button, CircularProgress, styled } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormTextField } from '../../../../components';
import { FormFriendly, superstructResolver } from '../../../../form';
import { LoginSubmitProps } from '../../auth.interface';
import { LoginFormFields, LoginFormValidation } from './LoginForm.validation';

interface Props {
  onSubmit: ({ email, password, redirect }: LoginSubmitProps) => Promise<void>;
  forceEmail?: string;
  forcePassword?: string;
  isSubmitting: boolean;
  redirect?: boolean;
}

export const LoginForm = ({
  onSubmit,
  forceEmail,
  forcePassword,
  isSubmitting,
  redirect,
}: Props) => {
  const { t } = useTranslation(['login']);
  const { handleSubmit, control } = useForm<FormFriendly<LoginFormFields>>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: superstructResolver(LoginFormValidation),
  });

  useEffect(() => {
    if (forceEmail && forcePassword) {
      onSubmit({
        email: forceEmail,
        password: forcePassword,
        redirect: redirect !== undefined ? redirect : true,
      });
    }
  }, [forceEmail, forcePassword]);

  return isSubmitting ? (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <CircularProgress />
    </Box>
  ) : (
    <FormContainer
      onSubmit={handleSubmit((values) =>
        onSubmit({
          email: values.email,
          password: values.password,
          redirect: redirect !== undefined ? redirect : true,
        })
      )}>
      <FormTextField
        fullWidth
        type="email"
        name="email"
        label={t('login:form.email')}
        control={control}
        t={t}
      />
      <FormTextField
        fullWidth
        name="password"
        type="password"
        label={t('login:form.password')}
        control={control}
        t={t}
      />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'max-content 1fr max-content',
          gridTemplateAreas: '"forgot . login"',
        }}>
        <Link href="/auth/forgot" passHref>
          <Button variant="text" sx={{ gridArea: 'forgot' }}>
            {t('login:form.forgotPassword')}
          </Button>
        </Link>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          sx={{ gridArea: 'login' }}
          disabled={isSubmitting}>
          {t('login:form.login')}
        </Button>
      </Box>
    </FormContainer>
  );
};

const FormContainer = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '> div': {
    marginBottom: theme.spacing(3),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));
