import { SnackbarPackProvider } from '@b7hio/core-lib';
import { bondsmithTheme } from '@b7hio/core-lib/theme';
import { css, Global } from '@emotion/react';
import { LocalizationProvider } from '@mui/lab';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { FC, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeContext } from './themes/theme.context';

const queryClient = new QueryClient();

/**
 * Global AppProviders (emotion, nextauth, etc...)
 */
export const AppProviders: FC = (props) => {
  const [theme, setTheme] = useState(bondsmithTheme);

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} locale="en-GB">
      <ThemeContext.Provider value={setTheme}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Global
            styles={css`
              .MuiDataGrid-iconButtonContainer {
                display: none;
              }

              .MuiDataGrid-columnHeader--sorted
                .MuiDataGrid-iconButtonContainer {
                display: inherit;
              }
            `}
          />
          <QueryClientProvider client={queryClient}>
            <SnackbarPackProvider>{props.children}</SnackbarPackProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </ThemeContext.Provider>
    </LocalizationProvider>
  );
};
