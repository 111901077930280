import { IconButton, Box } from '@b7hio/007/src/components';
import '@b7hio/007/src/icons';
import { styled } from '@b7hio/007/src/stitches.config';
import { colorsHsla } from '@b7hio/007/src/theme/colors';
import Notifications from '@mui/icons-material/Notifications';
import { Badge, Divider } from '@mui/material';
import Menu from '@mui/material/Menu';
import { keyframes } from '@stitches/react';
import * as React from 'react';
import { NotificationsListItem } from './NotificationsListItem';

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: '400px' },
});
const slideUp = keyframes({
  from: { height: '400px' },
  to: { height: 0 },
});

export type Notification = {
  readonly time: string;
  readonly notification: string;
  readonly message: string;
  readonly notificationType: 'Messages' | 'Payments' | 'Alerts';
  readonly color?: 'plum' | 'primary' | 'gray';
};

export type NotificationListProps = {
  readonly data: readonly Notification[];
  readonly color: 'plum' | 'primary' | 'gray';
};

export const NotificationsList: React.FC<NotificationListProps> = ({
  data,
  color,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledWrapper>
      <IconButton
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        variant={'noShape'}
        navbar={true}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <Badge badgeContent={data.length} color="error" variant="dot">
          <Notifications sx={{ fill: colorsHsla('navyBlue9') }} />
        </Badge>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          // 'aria-expanded': `${open}`,
        }}
        PaperProps={{
          'aria-expanded': `${open}`,
          style: {
            maxHeight: '400px',
          },
          sx: {
            zIndex: '100',
            width: {
              xs: '100%',
              sm: '400px',
            },
            maxWidth: {
              xs: 'unset',
              sm: 'calc(100% - 32px)',
            },
            marginTop: {
              xs: '60px',
              sm: '20px',
            },
            position: {
              xs: 'unset',
              sm: 'absolute',
            },
            borderTop: {
              xs: '1px solid lightgrey',
              sm: 'unset',
            },
            borderTopLeftRadius: {
              xs: 'unset',
              sm: '8px',
            },
            borderTopRightRadius: {
              xs: 'unset',
              sm: '8px',
            },
            borderBottomLeftRadius: {
              xs: '16px',
              sm: '4px',
            },
            borderBottomRightRadius: {
              xs: '16px',
              sm: '4px',
            },
            boxShadow: {
              xs: 'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;',
              sm: '',
            },
            animation: {
              xs: `${slideDown} 250ms linear`,
              sm: 'unset',
            },

            '&[aria-expanded="false"]': {
              animation: `${slideUp} 250ms linear`,
            },
            // '&::before': {
            //   content: {
            //     xs: '""',
            //     sm: 'unset',
            //   },
            //   position: 'absolute',
            //   zIndex: -100,
            //   height: '100%',
            //   width: '100%',
            //   background: 'rgba(0, 0, 0, 0.21)',
            // },
          },
        }}>
        {data.map((props, index) => (
          <Box key={index}>
            <NotificationsListItemWrapper
            //onClick={handleClose}
            >
              <NotificationsListItem
                color={color}
                time={props.time}
                notification={props.notification}
                message={props.message}
                notificationType={props.notificationType}
              />
            </NotificationsListItemWrapper>
            <Divider />
          </Box>
        ))}
      </Menu>
    </StyledWrapper>
  );
};

const StyledWrapper = styled('div', {
  marginX: '$3',
});
const NotificationsListItemWrapper = styled('div', {
  padding: '$2',
  maxWidth: 'unset',
});
