module.exports = (value, format, lng) => {
  if (lng === 'en') {
    // eslint-disable-next-line no-param-reassign
    lng = 'en-GB';
  }

  if (format === 'date' || format === 'dateTime') {
    /** @type {Intl.DateTimeFormatOptions} **/
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    };

    if (format === 'dateTime') {
      options.hour = '2-digit';

      options.minute = '2-digit';

      options.hour12 = false;

      options.timeZone = 'UTC';
    }

    return new Intl.DateTimeFormat(lng, options).format(value);
  }

  if (format === 'currency') {
    if (!value || !value.currency || (!value.amount && value.amount !== 0)) {
      return '[[invalid value given]]';
    }

    return new Intl.NumberFormat(lng, {
      style: 'currency',
      currency: value.currency,
      maximumFractionDigits: 2,
    }).format(value.amount);
  }

  if (format === 'shorthandnumber') {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'K' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'B' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const magnitude = lookup
      .reverse()
      .find((item) => value.amount >= item.value);

    return (
      '£' +
      (magnitude
        ? (value.amount / magnitude.value).toFixed(0).replace(rx, '$1') +
          magnitude.symbol
        : '0')
    );
  }

  if (format === 'number') {
    if (!value.amount && value.amount !== 0) {
      return '[[invalid value given]]';
    }

    return new Intl.NumberFormat(lng).format(value.amount);
  }

  return value;
};
