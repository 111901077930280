import React, { useContext } from 'react';
import { Subject } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EventContext = React.createContext(new Subject<any>());

export function useEventContext<T>() {
  return useContext(EventContext) as Subject<T>;
}

interface Props<T> {
  readonly subject: T;
  readonly children: React.ReactNode;
}

export function EventContextProvider<T>({ children, subject }: Props<T>) {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <EventContext.Provider value={subject as any}>
      {children}
    </EventContext.Provider>
  );
}

export interface EventAction<A extends string, P> {
  readonly action: A;
  readonly payload: P;
}
