import { DayPicker } from 'react-day-picker';
import { styled } from '../../stitches.config';

export const Calendar = styled(DayPicker, {
  $$cellsize: '32px',
  $$accentColor: '$colors$brand9',
  $$accentColorDark: '$colors$brand10',
  $$backgroundDark: '$colors$brand12',
  $$outline: '2px solid $$accentColor',
  $$outlineSelected: '2px solid rgba(0, 0, 0, 0.75)',

  // Interactive
  $$hoverBgColor: '$colors$brand4',
  $$hoverShadow: '0 0px 4px 3px $colors$brand4',
  $$focusBgColor: '$colors$brand9',

  '.rdp': { margin: '1em' },
  '.rdp-vhidden': {
    border: '0',
    clip: 'rect(1px, 1px, 1px, 1px)',
    height: '1px',
    overflow: 'hidden',
    padding: '0',
    position: 'absolute',
    top: '0',
    width: '1px',
  },
  '.rdp-button_reset': {
    appearance: 'none',
    position: 'relative',
    margin: '0',
    padding: '0',
    cursor: 'default',
    color: 'inherit',
    outline: 'none',
    background: 'none',
    font: 'inherit',
    MozAppearance: 'none',
    WebkitAppearance: 'none',
  },
  '.rdp-button': {
    border: '2px solid transparent',
    background: 'inherit',
    borderRadius: '$round',

    width: '$$cellsize',
    maxWidth: '$$cellsize',
    height: '$$cellsize',
    '& [disabled]': { opacity: 0.25 },
    '&:not([disabled])': { cursor: 'pointer' },
    '&:focus:not([disabled]), .rdp-button:active:not([disabled])': {
      //TODO:
      color: '$loContrast',
      border: 'none',
      background: '$colors$brand9',
      '&:hover': {
        background: '$colors$brand9',
      },
    },
    '&:hover:not([disabled])': {
      background: '$$hoverBgColor',
    },
  },
  '.rdp-month, .rdp-caption_first, .rdp-caption_last': {
    boxShadow: '$card',
    borderRadius: '$2',
  },
  '.rdp-months': { display: 'flex' },
  '.rdp-month': {
    margin: '0 1em',
    '&:first-child': { marginLeft: '0' },
    '&:last-child': { marginRight: '0' },
  },
  '.rdp-table': {
    margin: '0',
    maxWidth: 'calc($$cellsize) * 7',
    borderCollapse: 'collapse',
  },
  '.rdp-with_weeknumber .rdp-table': {
    maxWidth: 'calc($$cellsize) * 8',
    borderCollapse: 'collapse',
  },
  '.rdp-caption': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '$3',
    textAlign: 'left',
    color: '$loContrast',
    borderTopLeftRadius: '$2',
    borderTopRightRadius: '$2',
    background: '$gradients$blueGradient',
  },
  '.rdp-multiple_months .rdp-caption': {
    position: 'relative',
    display: 'block',
    textAlign: 'center',
  },
  '.rdp-month,.rdp-caption_first,.rdp-caption_last': {},
  '.rdp-caption_dropdowns': { position: 'relative', display: 'inline-flex' },
  '.rdp-caption_label': {
    position: 'relative',
    zIndex: 1,
    display: 'inline-flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    color: 'currentColor',
    border: ['0', '2px solid transparent'],
    fontFamily: 'inherit',
    fontSize: '$2',
    fontWeight: '$2',
  },
  '.rdp-nav': { whiteSpace: 'nowrap', display: 'flex', gap: '$4' },
  '.rdp-multiple_months .rdp-caption_first .rdp-nav': {
    position: 'absolute',
    top: '50%',
    left: '0',
    transform: 'translateY(-50%)',
  },
  '.rdp-multiple_months .rdp-caption_last .rdp-nav': {
    position: 'absolute',
    top: '50%',
    right: '0',
    transform: 'translateY(-50%)',
  },
  '.rdp-nav_button': {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    size: '$$cellsize',
    borderRadius: '100%',
    '&:hover': {
      $$hoverBgColor: '$colors$primary',
    },
    '&:focus:not([disabled])': {
      color: 'currentColor',
      border: 'none',
      background: 'unset',
      '&:hover': {
        color: '$loContrast',
      },
    },
  },
  '.rdp-dropdown_year, .rdp-dropdown_month': {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
  },
  '.rdp-dropdown': {
    appearance: 'none',
    position: 'absolute',
    zIndex: 2,
    top: '0',
    bottom: '0',
    left: '0',
    width: '100%',
    margin: '0',
    padding: '0',
    cursor: 'inherit',
    opacity: 0,
    border: 'none',
    // background: 'transparent',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    '& [disabled]': { opacity: 'unset', color: 'unset' },
    '&:focus:not([disabled]) + .rdp-caption_label, .rdp-dropdown:active:not([disabled]) + .rdp-caption_label':
      {
        border: 'none',
        borderRadius: '6px',
        background: '$$focusBgColor',
      },
  },
  '.rdp-dropdown_icon': { margin: '0 0 0 5px' },
  '.rdp-head': { border: '0' },
  '.rdp-head_row, .rdp-row': { height: '100%' },
  '.rdp-head_cell': {
    verticalAlign: 'middle',
    fontSize: '$1',
    fontWeight: '$3',
    textAlign: 'center',
    height: ['100%', '$$cellsize'],
    paddingY: '$2',
    color: '$primary',
  },
  '.rdp-tbody': { border: '0' },
  '.rdp-foot': { margin: '0.5em' },
  '.rdp-cell': {
    width: '$$cellsize',
    height: ['100%', '$$cellsize'],
    padding: '0 $2',
    textAlign: 'center',
  },
  '.rdp-weeknumber': {
    fontSize: '0.75em',
    '& .rdp-day': {
      display: 'flex',
      overflow: 'hidden',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
      width: '$$cellsize',
      maxWidth: '$$cellsize',
      height: '$$cellsize',
      margin: '0',
      border: '2px solid transparent',
      borderRadius: '100%',
    },
  },

  '.rdp-day_today:not(.rdp-day_outside)': { fontWeight: 'bold' },
  '.rdp-day_selected': {
    '&:not([disabled]), &:focus:not([disabled]), &:active:not([disabled])': {
      color: '$brand10',
    },
    '&:hover:not([disabled])': {
      color: '$loContrast',
    },
    '.rdp-day_selected:focus:not([disabled])': {
      border: 'none',
    },
  },
  ".rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end)": {
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
  },
  ".rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start)": {
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
  },
  ".rdp[dir='rtl'] .rdp-day_range_start:not(.rdp-day_range_end)": {
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
  },
  ".rdp[dir='rtl'] .rdp-day_range_end:not(.rdp-day_range_start)": {
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
  },
  '.rdp-day_range_end.rdp-day_range_start': { borderRadius: '100%' },
  '.rdp-day_range_middle': { borderRadius: '0' },
});
