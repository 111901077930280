import { coerce, number, refine, string, Struct } from 'superstruct';

export const isNumber = (input: string | undefined) =>
  input ? parseInt(input) > 0 : false;

export function notEmpty<
  T extends
    | string
    | number
    | Date
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | readonly any[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | ReadonlyMap<any, any>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | ReadonlySet<any>,
  S
>(struct: Struct<T, S>): Struct<T, S> {
  return minSize(struct, 1, 'notEmpty');
}

export function minSize<
  T extends
    | string
    | number
    | Date
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | readonly any[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | ReadonlyMap<any, any>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | ReadonlySet<any>,
  S
>(struct: Struct<T, S>, min: number, name = 'minSize'): Struct<T, S> {
  const expected = `Expected a ${struct.type}`;
  const of = `of at least \`${min}\``;

  return refine(struct, name, (value) => {
    if (typeof value === 'number' || value instanceof Date) {
      return min <= value || `${expected} ${of} but received \`${value}\``;
    } else if (value instanceof Map || value instanceof Set) {
      const { size } = value;
      return (
        min <= size ||
        `${expected} with a size ${of} but received one with a size of \`${size}\``
      );
    }
    const { length } = value as string | readonly unknown[];
    return (
      min <= length ||
      `${expected} with a length ${of} but received one with a length of \`${length}\``
    );
  });
}

export const stringNumber = () =>
  coerce(number(), string(), (value) =>
    value ? parseFloat(value) : undefined
  );

export const stringPercentageNumber = () =>
  coerce(number(), string(), (value) =>
    value ? parseFloat(value) / 100 : undefined
  );
