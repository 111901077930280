import { Box, Button, styled } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FormTextField } from '../../../../components';
import { FormFriendly, superstructResolver } from '../../../../form';
import {
  MfaPasswordConfirmFields,
  MfaPasswordConfirmFormValidation,
} from './MfaPasswordConfirm.validation';

interface MfaPasswordConfirmProps {
  nextStep: (password: string) => Promise<void>;
  isSubmitting: boolean;
}

export const MfaPasswordConfirm = ({
  nextStep,
  isSubmitting,
}: MfaPasswordConfirmProps) => {
  const { t } = useTranslation(['login']);

  const { handleSubmit, control } = useForm<
    FormFriendly<MfaPasswordConfirmFields>
  >({
    resolver: superstructResolver(MfaPasswordConfirmFormValidation),
    defaultValues: {
      password: '',
    },
  });

  return (
    <FormContainer
      onSubmit={handleSubmit((values) => nextStep(values.password))}>
      <FormTextField
        fullWidth
        name="password"
        type="password"
        label={t('login:form.passwordConfirm')}
        control={control}
        t={t}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          disabled={isSubmitting}>
          {t('login:form.confirm')}
        </Button>
      </Box>
    </FormContainer>
  );
};

const FormContainer = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '> div': {
    marginBottom: theme.spacing(3),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));
