const path = require('path');
const HttpBackend = require('i18next-http-backend/cjs');
const { defaultLocale, locales, ...config } = require('./i18next.config');

module.exports = {
  i18n: {
    defaultLocale,
    locales,
    use: process.browser ? [HttpBackend] : [],
  },
  localePath: path.resolve('./public/locales'),
  ...config,
};
