import type { Infer } from 'superstruct';
import { object, refine, string } from 'superstruct';
import { minSize } from '../../../../form';

export const CreateNewPasswordValidation = object({
  password: minSize(string(), 10, 'passwordRequirement'),
  passwordConfirm: refine(
    string(),
    'comparer',
    (value, ctx) => ctx.branch[0].password === value
  ),
});

export type CreateNewPasswordFields = Infer<typeof CreateNewPasswordValidation>;
