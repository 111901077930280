import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { styled } from '../../stitches.config';
import { IconButton } from '../IconButton';

const BadgeWrapper = styled('div', {
  $$bgColor: '$colors$gray3',
  $$textColor: '$colors$gray12',
  $$borderColor: 'currentColor',
  $$padding: '$space$2',
  $$borderRadius: '$radii$round',
  $$width: 'max-content',
  $$fontWeight: '$fontWeights$3',
  display: 'inline-flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$1',
  backgroundColor: '$$bgColor',
  color: '$$textColor',
  fontSize: '0.8125rem',
  fontWeight: '$$fontWeight',
  width: '$$width',
  borderWidth: '$1',
  borderStyle: '$1',
  borderColor: '$$borderColor',
  borderRadius: '$$borderRadius',
  padding: '$$padding',
  variants: {
    variant: {
      outlined: {
        $$bgColor: 'transparent',
      },
      contained: {
        $$bgColor: '$$textColor',
      },
      tonal: {
        $$bgColor: '$$textColor',
        $$fontWeight: '$fontWeights$5',
      },
      tonalDark: {
        $$bgColor: '$$textColor',
        $$fontWeight: '$fontWeights$5',
      },
    },
    shape: {
      square: { $$borderRadius: '$radii$1', $$padding: '$sizes$1 $sizes$3' },
    },
    color: {
      error: {
        $$textColor: '$colors$error',
      },
      success: {
        $$textColor: '$colors$success',
      },
      info: {
        $$textColor: '$colors$info',
      },
      warning: {
        $$textColor: '$colors$yellow8',
      },
      disabled: {
        $$textColor: '$colors$gray8',
      },
    },
    size: {
      small: {
        $$padding: '$space$1 $space$2',
        // $$padding: '6px 6px',
      },
      medium: {
        $$padding: '$space$2',
      },
    },
    startIcon: {
      true: {
        display: 'inline-flex',
        gap: '$2',
        alignItems: 'center',
        justifyContent: 'center',
        '& > svg': {
          fontSize: 'inherit',
          fill: 'currentColor',
        },
      },
      false: {
        '& svg': {
          display: 'none',
        },
      },
    },
  },
  compoundVariants: [
    {
      color: 'info',
      variant: 'contained',
      css: {
        $$bgColor: '$colors$info',
        $$textColor: '$colors$loContrast',
      },
    },
    {
      color: 'warning',
      variant: 'contained',
      css: {
        $$bgColor: '$colors$warning',
        $$textColor: '$colors$yellow12',
        $$borderColor: '$$bgColor',
      },
    },
    {
      color: 'error',
      variant: 'contained',
      css: {
        $$bgColor: '$colors$error',
        $$textColor: '$colors$loContrast',
      },
    },
    {
      color: 'success',
      variant: 'contained',
      css: {
        $$bgColor: '$colors$success',
        $$textColor: '$colors$loContrast',
      },
    },
    {
      color: 'success',
      variant: 'tonal',
      css: {
        $$bgColor: '$colors$green15',
        $$textColor: '$colors$green11',
        $$borderColor: '$$bgColor',
      },
    },
    {
      color: 'error',
      variant: 'tonal',
      css: {
        $$bgColor: '$colors$tonalError',
        $$textColor: '$colors$red10',
        $$borderColor: '$$bgColor',
      },
    },
    {
      color: 'info',
      variant: 'tonal',
      css: {
        $$bgColor: '$colors$tonalInfo',
        $$textColor: '$colors$blue10',
        $$borderColor: '$$bgColor',
      },
    },
    {
      color: 'warning',
      variant: 'tonal',
      css: {
        $$bgColor: '$colors$tonalWarning',
        $$textColor: '$colors$yellow11',
        $$borderColor: '$$bgColor',
      },
    },

    {
      color: 'disabled',
      variant: 'tonal',
      css: {
        $$bgColor: '$colors$gray5',
        $$textColor: '$colors$gray11',
        $$borderColor: '$$bgColor',
      },
    },
    {
      color: 'error',
      variant: 'tonalDark',
      css: {
        $$bgColor: '$colors$red13',
        $$textColor: '$colors$white',
        $$borderColor: '$$bgColor',
      },
    },

    {
      color: 'disabled',
      variant: 'tonalDark',
      css: {
        $$bgColor: '$colors$gray9',
        $$textColor: '$colors$white',
        $$borderColor: '$$bgColor',
      },
    },
  ],
  defaultVariants: {
    size: 'medium',
  },
});

interface Props {
  readonly onDelete?: React.MouseEventHandler<HTMLButtonElement>;
  readonly color?: 'error' | 'success' | 'info' | 'warning' | 'disabled';
  readonly size?: 'small' | 'medium';
  readonly variant?: 'outlined' | 'contained' | 'tonal' | 'tonalDark';
  readonly label?: string | React.ReactNode;
  readonly shape?: 'square';
  readonly startIcon?: boolean;
}

export const Badge = ({
  color,
  onDelete,
  size,
  variant,
  label,
  shape,
  startIcon,
}: Props): JSX.Element => {
  return (
    <BadgeWrapper
      variant={variant}
      color={color}
      size={size}
      shape={shape}
      startIcon={startIcon}>
      {label}
      {onDelete && (
        <IconButton
          css={{ height: '1rem', width: '1rem', padding: '0' }}
          color="gray"
          onClick={onDelete}
          static>
          <FontAwesomeIcon icon={['fas', 'xmark']} fixedWidth />
        </IconButton>
      )}
    </BadgeWrapper>
  );
};
