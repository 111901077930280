import { useId } from '@radix-ui/react-id';
import React, { useEffect, useRef, useState } from 'react';
import { styled } from '../../stitches.config';
import useForkRef from '../../utils/useForkRef';

// Inspired by @mui's TextField

const Label = styled('label', {
  $$labelColor: '$colors$gray10',
  $$focusLabelColor: '$colors$primary',

  position: 'absolute',
  display: 'block',
  fontSize: '$1',
  color: '$$labelColor',
  // ml: 'calc(-$1 + 4px)',
  top: 0,
  left: 0,
  whiteSpace: 'nowrap',
  transition: 'color $1, transform $1',
  transform: 'translate(calc(.5rem + $borderWidths$1), 1.05rem) scale(1)',
  transformOrigin: 'top left',
  pointerEvents: 'none',

  // Interactivity
  '&[data-focus=true]': {
    color: '$$focusLabelColor',
  },

  '&[data-focus=true], &[data-shrink=true]': {
    transform: 'translate(calc(.5rem + $borderWidths$1), $space$1) scale(0.70)',
    fontWeight: '$fontWeights$3',
  },

  variants: {
    small: {
      true: {
        transform: 'translate(calc(.5rem + $borderWidths$1), .75rem) scale(1)',
      },
    },
    xSmall: {
      true: {
        transform: 'translate(calc(.5rem + $borderWidths$1), .4rem) scale(1)',
      },
    },
    error: {
      true: {
        $$labelColor: '$colors$error',
        $$focusLabelColor: '$colors$error',
      },
    },
  },
});

const InputField = styled('input', {
  $$paddingTop: '1.625rem',
  $$paddingRight: '0',
  $$paddingBottom: '0.625rem',
  $$paddingLeft: '0',
  $$padding: '$$paddingTop $$paddingRight $$paddingBottom $$paddingLeft',
  display: 'inline-block',
  height: '100%',
  flex: 1,
  padding: '$$padding',

  background: 'transparent',
  borderRadius: 'inherit',
  fontFamily: '$sans',
  fontSize: '1rem',
  border: '0',
  outline: 'none',

  '&::placeholder': {
    transition: 'opacity $1',
    opacity: 0,
    color: '$gray10',
  },

  '&[data-shrink=true]::placeholder': {
    opacity: 1,
  },

  variants: {
    small: {
      true: {
        $$paddingTop: '0.5rem',
        $$paddingBottom: '1.1rem',
      },
    },
    xSmall: {
      true: {
        $$paddingTop: '0.5rem',
        $$paddingBottom: '0.5rem',
      },
    },
  },
});

const Root = styled('div', {
  $$borderColor: '$colors$gray6',
  $$hoverBorderColor: '$colors$brand8',
  $$focusBorderColor: '$colors$brand9',
  // $$focusShadow: '0 0 4px 2px $colors$brand4',
  $$minWidth: 'calc(calc(.85rem * 2) + 20ch)',
  $$paddingLeft: '0.5rem',
  $$bgColor: 'transparent',
  $$borderWidth: '$borderWidths$1',
  display: 'inline-flex',
  position: 'relative',
  flexDirection: 'row',
  verticalAlign: 'top',
  borderRadius: '$2',
  width: 'min-content',
  paddingLeft: '$$paddingLeft',
  paddingRight: '0.5rem',

  // flexWrap: 'wrap',
  gap: '$1',

  background: '$$bgColor',
  minHeight: '2rem',
  transition: 'borderColor $1, box-shadow $1',
  borderStyle: 'solid',
  borderColor: '$$borderColor',
  borderWidth: '$$borderWidth',
  minWidth: '$$minWidth',

  cursor: 'text',

  // Interactive States
  '&:hover': {
    borderColor: '$$hoverBorderColor',
  },
  '&[data-focus=true]': {
    borderColor: '$$focusBorderColor',
    $$borderWidth: '$borderWidths$2',
    // boxShadow: '$$focusShadow',
  },

  '&[data-focus="true"]': {
    '& > label': {
      "&[class*='xSmall'][class*='true']": {
        display: 'none',
      },
    },
    '& > input': {
      // paddingTop: '0.85rem',
      // paddingBottom: '0.85rem',
      "&[class*='xSmall'][class*='true']": {
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
      },
    },
  },
  '&[data-focus="false"][data-filled="true"]': {
    '& > label': {
      "&[class*='xSmall'][class*='true']": {
        display: 'none',
      },
    },
  },

  variants: {
    variant: {
      tonal: {
        $$bgColor: '$colors$brand2',
      },
    },
    noBorder: {
      true: {
        $$borderWidth: '0',
      },
      false: {
        $$borderWidth: '$borderWidths$1',
      },
    },
    fullWidth: {
      true: {
        display: 'flex',
        width: '100%',
      },
    },
    error: {
      true: {
        $$borderColor: '$colors$red6',
        $$hoverBorderColor: '$colors$red8',
        $$focusBorderColor: '$colors$red7',
        // $$focusShadow: '0 0 4px 2px $colors$red4',
      },
    },
    endAdornment: {
      true: {
        // TODO: Implement me
        // $$paddingRight: 'calc(0.425rem + 26px)',
      },
    },
    startAdornment: {
      true: {
        // TODO: Implement me
        '& > label': {
          marginLeft: '1.5rem',
        },
      },
    },
  },

  // compoundVariants: [
  //   {
  //     small: true,
  //     endAdornment: true,
  //     css: {
  //       // TODO: Implement me
  //     },
  //   },
  // ],
});

const Adornment = styled('div', {
  display: 'flex',
  // height: '0.01em',
  // maxHeight: '2em',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  variants: {
    position: {
      start: {
        // alignSelf: 'start',
        // position: 'absolute',
        // right: '0.425rem',
        // top: 'calc(50% - 14px)',
      },
      end: {
        // alignSelf: 'end',
        // position: 'absolute',
        // right: '0.425rem',
        // top: 'calc(50% - 14px)',
      },
    },
  },
});

type Props = {
  readonly label: React.ReactNode;
  readonly LabelProps?: React.HTMLAttributes<HTMLLabelElement>;
  readonly RootProps?: React.HTMLAttributes<HTMLDivElement>;
  readonly fullWidth?: boolean;
  readonly noBorder?: boolean;
  readonly error?: boolean;
  readonly small?: boolean;
  readonly xSmall?: boolean;
  readonly shrinkLabel?: boolean;
  readonly endAdornment?: React.ReactNode;
  readonly startAdornment?: React.ReactNode;
  readonly variant?: 'tonal';
  /**
   * The id of the `input` element.
   */
  readonly id?: string;

  /**
   * [Attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes) applied to the `input` element.
   * @default {}
   */
  readonly inputProps?: React.InputHTMLAttributes<HTMLInputElement> & {
    readonly ref?: React.Ref<HTMLInputElement>;
  };
  /**
   * Pass a ref to the `input` element.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly inputRef?: React.Ref<any>;

  readonly ref?: React.Ref<HTMLDivElement>;

  // Events
  /**
   * Callback fired when the `input` is blurred.
   *
   * Notice that the first argument (event) might be undefined.
   */
  readonly onBlur?: React.FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  /**
   * Callback fired when the value is changed.
   *
   * @param {React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value` (string).
   */
  readonly onChange?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  readonly onFocus?: React.FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  readonly onKeyDown?: React.KeyboardEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  readonly onKeyUp?: React.KeyboardEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  readonly onClick?: React.MouseEventHandler<HTMLDivElement>;
  /**
   * The short hint displayed in the `input` before the user enters a value.
   */
  readonly placeholder?: string;
  /**
   * It prevents the user from changing the value of the field
   * (not from interacting with the field).
   */
  readonly readOnly?: boolean;
  /**
   * If `true`, the `input` element is required.
   * The prop defaults to the value (`false`) inherited from the parent FormControl component.
   */
  readonly required?: boolean;
  /**
   * Type of the `input` element. It should be [a valid HTML5 input type](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types).
   * @default 'text'
   */
  readonly type?: string;
  /**
   * The value of the `input` element, required for a controlled component.
   */
  readonly value?: string;
};

export const TextField: React.FC<Props> = React.forwardRef<
  HTMLDivElement,
  Props
>(
  (
    {
      id: givenId,
      label,
      placeholder,
      fullWidth,
      noBorder,
      error,
      small,
      xSmall,
      shrinkLabel: shouldShrinkLabel,
      // rootRef,
      inputProps: inputPropsProp = {},
      inputRef: inputRefProp,
      endAdornment,
      startAdornment,
      variant,
      RootProps,
      LabelProps,
      onBlur,
      onFocus,
      onChange,
      onClick,
      children,
      type = 'text',
      value: valueProp,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ...props
    },
    ref
  ) => {
    const value =
      inputPropsProp.value != null ? inputPropsProp.value : valueProp;
    const { current: isControlled } = React.useRef(value != null);

    const inputRef = useRef<HTMLInputElement>();
    const handleInputRefWarning = React.useCallback((instance) => {
      if (process.env.NODE_ENV !== 'production') {
        if (instance && instance.nodeName !== 'INPUT' && !instance.focus) {
          // eslint-disable-next-line no-console
          console.error(
            [
              '007: You have provided a `inputComponent` to the input component',
              'that does not correctly handle the `ref` prop.',
              'Make sure the `ref` prop is called with a HTMLInputElement.',
            ].join('\n')
          );
        }
      }
    }, []);
    const handleInputPropsRefProp = useForkRef(
      inputPropsProp.ref,
      handleInputRefWarning
    );
    const handleInputRefProp = useForkRef(
      inputRefProp,
      handleInputPropsRefProp
    );
    const handleInputRef = useForkRef(inputRef, handleInputRefProp);

    const id = useId(givenId);
    const [focus, setFocus] = useState(false);
    // @ts-expect-error TODO case for number?
    const [filled, setFilled] = useState(() => (value?.length ?? 0) > 0);
    const shrinkLabel = filled || focus || shouldShrinkLabel;

    const checkDirty = React.useCallback(
      (obj: { readonly value?: unknown; readonly defaultValue?: unknown }) => {
        if (
          (hasValue(obj.value) && obj.value !== '') ||
          (hasValue(obj.defaultValue) && obj.defaultValue !== '')
        ) {
          setFilled(true);
        } else {
          setFilled(false);
        }
      },
      [setFilled]
    );

    useEffect(() => {
      if (isControlled) {
        checkDirty({ value });
      }
    }, [value, checkDirty, isControlled]);

    const handleClick: React.MouseEventHandler<HTMLInputElement> = (event) => {
      if (inputRef.current && event.currentTarget === event.target) {
        inputRef.current.focus();
      }

      if (onClick) {
        onClick(event);
      }
    };

    const handleFocus: React.FocusEventHandler<HTMLInputElement> = (
      event,
      ...args
    ) => {
      setFocus(true);

      inputPropsProp?.onFocus?.(event, ...args);
      onFocus?.(event, ...args);
    };

    const handleBlur: React.FocusEventHandler<HTMLInputElement> = (
      event,
      ...args
    ) => {
      setFocus(false);

      inputPropsProp?.onBlur?.(event, ...args);
      onBlur?.(event, ...args);
    };

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (
      event,
      ...args
    ) => {
      if (!isControlled) {
        const element = event.target || inputRef.current;
        if (element == null) {
          throw new Error(
            '007: Expected valid input target. ' +
              'Did you use a custom `inputComponent` and forget to forward refs? ' +
              'See https://mui.com/r/input-component-ref-interface for more info.'
          );
        }

        checkDirty({
          value: element.value,
        });
      }

      inputPropsProp?.onChange?.(event, ...args);
      onChange?.(event, ...args);
    };

    return (
      <Root
        /* TODO: implement correct types to props */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {...(RootProps as any)}
        fullWidth={fullWidth}
        noBorder={noBorder}
        ref={ref}
        onClick={handleClick}
        error={error}
        endAdornment={Boolean(endAdornment)}
        startAdornment={Boolean(startAdornment)}
        variant={variant}
        data-focus={focus}
        data-filled={filled}>
        {children}
        {startAdornment && (
          <Adornment position="start">{startAdornment}</Adornment>
        )}
        <InputField
          type={type}
          id={id}
          placeholder={placeholder}
          value={value}
          small={small}
          xSmall={xSmall}
          /* TODO: implement correct types to props */
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...(inputPropsProp as any)}
          ref={handleInputRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          data-shrink={shrinkLabel}
        />
        {endAdornment && <Adornment position="end">{endAdornment}</Adornment>}
        <Label
          /* TODO: implement correct types to props */
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...(LabelProps as any)}
          htmlFor={id}
          small={small}
          xSmall={xSmall}
          error={error}
          data-focus={focus}
          data-shrink={shrinkLabel}>
          {label}
        </Label>
      </Root>
    );
  }
);

TextField.toString = Root.toString;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function hasValue(value: any) {
  return value != null && !(Array.isArray(value) && value.length === 0);
}
