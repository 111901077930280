import type { FieldValues, FormState } from 'react-hook-form';
import { FieldPath } from 'react-hook-form/dist/types/utils';
import type { Fallback, Namespace, TFunction } from 'react-i18next';
import { get } from '../utils';

export function getFieldErrors<
  T extends Namespace<F>,
  F extends Fallback<string>,
  FV extends FieldValues,
  FN extends FieldPath<FV> = FieldPath<FV>
>(
  fieldName: FN,
  formState: FormState<FV>,
  t: TFunction<T>,
  getErrorPath?: (type: string) => string | undefined
): {
  readonly helperText: string | false;
  readonly error: boolean;
  readonly disabled: boolean;
} {
  const { isSubmitting, touchedFields, errors, submitCount } = formState;
  const hasSubmitted = submitCount > 0;

  // console.log(
  //   fieldName,
  //   get(touchedFields, fieldName),
  //   (get(errors, fieldName) as any)?.message
  // );

  // Only show the error if the field has been interacted with, or the form has
  //  been submitted.
  const canShowError =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (get(touchedFields, fieldName as any) || hasSubmitted) &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get(errors, fieldName as any);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fieldErrors: any | readonly any[] = get(errors, fieldName as any);
  const type = Array.isArray(fieldErrors)
    ? fieldErrors[0].type
    : fieldErrors?.type;

  const error =
    canShowError &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ((getErrorPath?.(type) as any) ??
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      t(`common:validation.${type as any}` as any));

  return {
    error: canShowError && Boolean(error),
    helperText: canShowError && error,
    disabled: isSubmitting,
  };
}
