import { colorsHsla } from '@b7hio/007/src/theme/colors';
import * as React from 'react';

export const CustomMuiTextFieldCss = {
  '& div.Mui-error': {
    border: `2px solid ${colorsHsla('error')} !important`,
  },
  '& .MuiFilledInput-root': {
    background: 'none !important',
    border: `1px solid ${colorsHsla('gray7')}`,
    borderRadius: '4px',
    '&:hover': {
      border: `1px solid ${colorsHsla('brand9')}`,
    },
  },
  '& .MuiFilledInput-root:before, .MuiFilledInput-root:after': {
    display: 'none !important',
  },
  '& label': {
    color: colorsHsla('gray19'),
  },
  '& div.Mui-focused': {
    border: `2px solid ${colorsHsla('brand9')} !important`,
  },
  '& label.Mui-focused': {
    color: colorsHsla('brand9'),
  },
};
