import { Box, Typography } from '@mui/material';
import React from 'react';
import { bondsmithTheme } from '../../theme';
import { BeanbagImage } from './BeanbagImage';
import { useTranslation } from 'react-i18next';

export const ErrorPage = () => {

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        p: 4,
        background: bondsmithTheme.palette.gradients.boxHeader,
      }}>
      <Box
        sx={{
          pt: 4,
          display: 'grid',
          gridTemplateAreas: '" . message . . . ."',
          gridTemplateColumns: '1fr max(1fr, max-content) repeat(4, 1fr)',
        }}>
        <Box
          sx={{
            gridArea: 'message',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Typography
            variant="h4"
            sx={{ color: bondsmithTheme.palette.loginArea.main }}>
            Whoops, an error occurred!
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <BeanbagImage />
      </Box>
    </Box>
  );
};
