import { styled } from '@b7hio/007/src/stitches.config';
import * as React from 'react';

export const Highlighter = ({ text = '', highlight = '' }) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }
  const regex = new RegExp(`(${highlight})`, 'gi');
  const parts = text.split(regex);

  return (
    <span>
      {parts.filter(String).map((part, i) => {
        return regex.test(part) ? (
          <Mark key={i}>{part}</Mark>
        ) : (
          <span key={i}>{part}</span>
        );
      })}
    </span>
  );
};

const Mark = styled('mark', {
  background: 'transparent',
  fontWeight: '$fontWeights$5',
});
