import * as DialogPrimitive from '@radix-ui/react-dialog';
import type * as DialogPrimitiveTypes from '@radix-ui/react-dialog';
import { keyframes } from '@stitches/react';
import React from 'react';
import { styled } from '../../stitches.config';

const fadeIn = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
});

const fadeOut = keyframes({
  from: { opacity: 1 },
  to: { opacity: 0 },
});

const moveIn = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translate(-50%, -48%) scale(.96)',
    boxShadow: 'none',
  },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

const moveOut = keyframes({
  '0%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
  '100%': {
    opacity: 0,
    transform: 'translate(-50%, -48%) scale(.96)',
    boxShadow: 'none',
  },
});

const StyledOverlay = styled(DialogPrimitive.Overlay, {
  backgroundColor: '$blackA9',
  opacity: 0.7,
  position: 'fixed',
  inset: 0,
  '&[data-state="open"]': {
    animation: `${fadeIn} $transitions$2`,
  },
  '&[data-state="closed"]': {
    animation: `${fadeOut} $transitions$2`,
  },
});

type DialogProps = DialogPrimitiveTypes.DialogProps;

export const Dialog: React.FC<DialogProps> = ({ children, ...props }) => {
  return (
    <DialogPrimitive.Root {...props}>
      <StyledOverlay />
      {children}
    </DialogPrimitive.Root>
  );
};

const StyledContent = styled(DialogPrimitive.Content, {
  $$maxWidth: '512px',
  $$width: '90vw',
  $$padding: '$3',
  position: 'fixed',
  borderRadius: '$2',
  backgroundColor: '$loContrast',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '$$width',
  maxWidth: '$$maxWidth',
  padding: '$$padding',
  boxShadow: '$dialog',

  '&[data-state="open"]': {
    animation: `${moveIn} $transitions$2`,
  },

  '&[data-state="closed"]': {
    animation: `${moveOut} $transitions$2`,
  },
});

export const DialogClose = DialogPrimitive.Close;
export const StyledCloseButton = styled(DialogPrimitive.Close, {
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  padding: '$1',
  position: 'fixed',
  top: 25,
  right: 20,
  fontSize: '$3',
  color: '#687076',
});

export const DialogContent = React.forwardRef<
  HTMLDivElement,
  DialogPrimitive.DialogContentProps
>(({ children, ...props }, forwardedRef) => (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  <StyledContent {...(props as any)} ref={forwardedRef}>
    {children}
    {/* <StyledCloseButton>
      <FontAwesomeIcon icon={['fas', 'xmark']} />
    </StyledCloseButton> */}
  </StyledContent>
));

export const DialogTrigger = DialogPrimitive.Trigger;
