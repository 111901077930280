import { DatePicker as MuiDatePicker } from '@mui/lab';
import { DateTime } from 'luxon';
import React, { useRef } from 'react';
import { useController } from 'react-hook-form';
import type { UseControllerProps } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';
import type { TFunction } from 'react-i18next';
import { useFieldErrors } from '../../../form';
import { TextField } from '@mui/material';
import { CustomMuiTextFieldCss } from '../../../utils/CustomMuiTextFieldCss';

export interface DatePickerProps<T> extends UseControllerProps<T> {
  readonly label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly t: TFunction<any>;
  readonly disableOpenPicker?: boolean;
  readonly getErrorPath?: (type: string) => string | undefined;
  // InputProps: any;
  readonly size?: 'small' | 'medium' | undefined;
  readonly maskFormat?: string;
  readonly disablePast?: boolean;
  readonly maxDate?: DateTime;
  readonly minDate?: DateTime;
  readonly shouldDisableDate?: (day: DateTime) => boolean;
  readonly customMuiTextField?: boolean;
  readonly disabled?: boolean;
}

export const FormDatePicker = <T extends FieldValues>({
  t,
  label,
  disableOpenPicker,
  disablePast,
  getErrorPath,
  maskFormat,
  size,
  minDate,
  maxDate,
  shouldDisableDate,
  customMuiTextField,
  disabled,
  ...props
}: DatePickerProps<T>): JSX.Element => {
  const {
    field: { ref, ...field },
    formState,
  } = useController(props);
  // TODO review null fallback
  const value = field.value
    ? DateTime.fromISO(field.value)
    : field.value ?? null;

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <MuiDatePicker<DateTime>
      disablePast={disablePast ?? false}
      label={label}
      mask={maskFormat}
      disableOpenPicker={disableOpenPicker}
      minDate={minDate}
      maxDate={maxDate}
      shouldDisableDate={shouldDisableDate ?? undefined}
      renderInput={(params) => (
        <TextField
          sx={customMuiTextField ? CustomMuiTextFieldCss : {}}
          {...params}
          inputProps={{
            ...params.inputProps,
            placeholder: 'DD/MM/YYYY'
          }}
          {...useFieldErrors(field.name, formState, t, getErrorPath)}
          id={field.name}
          name={field.name}
          data-testid={field.name}
          onBlur={field.onBlur}
          ref={ref}
          size={size ? size : 'medium'}
          variant={customMuiTextField ? 'filled' : 'outlined'}
        />
      )}
      {...field}
      value={value}
      disabled={
        disabled ? disabled || formState.isSubmitting : formState.isSubmitting
      }
      // Tied to the focus/blur hack
      inputRef={inputRef}
      onClose={() => {
        // HACK to try and retrigger validation when the dialog from the DatePicker window closes.
        // No other side-effect is available to do this
        setTimeout(() => {
          inputRef.current?.focus();
          inputRef.current?.blur();
        }, 0);
      }}
      onChange={(date) => {
        field.onChange(date?.toISODate());
      }}
    />
  );
};
