import { Box } from '@b7hio/007/src/components';
import { styled } from '@b7hio/007/src/stitches.config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import * as React from 'react';

interface NotificationsListItem {
  readonly time: string;
  readonly notification: string;
  readonly message: string;
  readonly notificationType: 'Messages' | 'Payments' | 'Alerts';
  readonly color?: 'plum' | 'primary' | 'gray';
  readonly showType?: boolean;
  readonly showMessage?: boolean;
}

export const NotificationsListItem: React.FC<NotificationsListItem> = ({
  time,
  notification,
  message,
  notificationType,
  color,
  showType = true,
  showMessage = true,
}) => {
  return (
    <StyledNotificationListItem>
      <NotificationListItemHeader>
        {notificationType === 'Alerts' && (
          <StyledIcon
            color={color}
            icon={['fas', 'bell']}
            data-testid="Alerts-icon"
          />
        )}
        {notificationType === 'Messages' && (
          <StyledIcon
            color={color}
            icon={['fas', 'envelope']}
            data-testid="Messages-icon"
          />
        )}
        {notificationType === 'Payments' && (
          <StyledIcon
            color={color}
            icon={['fas', 'money-check-dollar']}
            data-testid="Payments-icon"
          />
        )}
        {showType && (
          <Typography
            variant="body2"
            sx={{
              color: (theme) => theme.palette.notificationsList.message,
            }}>
            {notificationType}
          </Typography>
        )}
        <Typography
          variant="body2"
          sx={{ color: (theme) => theme.palette.notificationsList.message }}>
          {time}
        </Typography>
      </NotificationListItemHeader>
      <Box>
        <StyledTypography variant="subtitle2">{notification}</StyledTypography>
      </Box>
      {showMessage && (
        <Box>
          <Typography
            variant="body2"
            sx={{
              color: (theme) => theme.palette.notificationsList.message,
              width: '250px',
            }}>
            {message}
          </Typography>
        </Box>
      )}
    </StyledNotificationListItem>
  );
};

const StyledNotificationListItem = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '$1',
  paddingY: '$2',
});

const NotificationListItemHeader = styled('div', {
  display: 'flex',
  gap: '$3',
  alignItems: 'center',
});

const StyledIcon = styled(FontAwesomeIcon, {
  $$color: '$colors$primary',
  color: '$$color',
  height: '18px',
  width: '18px',
  variants: {
    color: {
      plum: {
        $$color: '$colors$plum9',
      },
      gray: {
        $$color: '$colors$gray9',
      },
      primary: {
        $$color: '$colors$primary',
      },
    },
  },
});

const StyledTypography = styled(Typography, {
  color: '$navyBlue10',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  minWidth: '300px',
  fontSize: '$1',
});
