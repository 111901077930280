import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { keyframes } from '@stitches/react';
import React from 'react';
import { styled } from '../../stitches.config';

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
});

const slideUp = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
});

export const VerticalStepper = styled(AccordionPrimitive.Root, {
  display: 'flex',
  flexDirection: 'column',
});

const DividerWrapper = styled('div', {
  marginLeft: '12px',
  flex: '1 1 auto',
});
const StepDivider = styled('span', {
  $$background: '$colors$gray9',
  display: 'block',
  borderLeftStyle: '$borderStyles$1',
  borderLeftWidth: '$borderWidths$1',
  borderColor: '$$background',
  minHeight: '24px',
});
const StepNumber = styled('span', {
  flexShrink: 0,
  paddingRight: '$2',
  display: 'flex',
  alignItems: 'center',
  fill: '$colors$primary',
  '& > *': {
    color: '$colors$primary',
    fontSize: '$fontSizes$3',
  },
});

const NumberHolder = styled('svg', {
  userSelect: 'none',
  width: '1em',
  height: '1em',
  display: 'inline-block',
  fill: '$$fillColor',
  flexShrink: 0,
  fontSize: '$fontSizes$3',
  variants: {
    variant: {
      active: {
        $$fillColor: '$colors$primary',
      },
      inactive: {
        $$fillColor: '$colors$gray11',
      },
    },
  },
  defaultVariants: {
    variant: 'inactive',
  },
});

const TextHolder = styled('text', {
  fill: '$loContrast',
  fontSize: '0.75rem',
});

const StyledCircle = styled('circle', {
  cx: 12,
  cy: 12,
  r: 12,
});

const LabelHolder = styled('span', {
  fontSize: '0.875rem',
  lineHeight: '1.43',
  display: 'block',
});

const LabelWrapper = styled('span', {
  width: '100%',
  color: '$hiContrast',
});

const StepLabelWrapper = styled('span', {
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',
  padding: '$1 0',
});

interface StepLabelProps {
  readonly step: number;
  readonly active: boolean;
  readonly completed: boolean;
}

export const VerticalStepLabel: React.FC<StepLabelProps> = ({
  children,
  step,
  active,
  completed,
}) => {
  return (
    <>
      {step != 1 && (
        <DividerWrapper>
          <StepDivider />
        </DividerWrapper>
      )}
      <StepLabelWrapper>
        <StepNumber>
          {completed ? (
            <FontAwesomeIcon icon={['fas', `check-circle`]} />
          ) : (
            <NumberHolder variant={`${active ? 'active' : 'inactive'}`}>
              <StyledCircle />
              <TextHolder x="12" y="16" textAnchor="middle">
                {step}
              </TextHolder>
            </NumberHolder>
          )}
        </StepNumber>
        <LabelWrapper>
          <LabelHolder>{children}</LabelHolder>
        </LabelWrapper>
      </StepLabelWrapper>
    </>
  );
};

export const VerticalStep = AccordionPrimitive.Item;

const StyledHeader = styled(AccordionPrimitive.Header, {
  all: 'unset',
  display: 'flex',
});

const StyledTrigger = styled(AccordionPrimitive.Trigger, {
  all: 'unset',
  paddingTop: '$3',
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '$3',
  fontSize: 15,
});

export const Trigger = React.forwardRef<
  HTMLButtonElement,
  AccordionPrimitive.AccordionTriggerProps
  // HACK to get other typechecks working. Something weird is going on with the @emotion deps
  // If you want to test this, remove the following lines and run `yarn typecheck`
  // on core or `yarn typecheck:jest here
  // eslint-disable-next-line
  // @ts-ignore HACK
>(({ children, css: _, ...props }, forwardedRef) => (
  <StyledHeader>
    <StyledTrigger {...props} ref={forwardedRef}>
      {children}
    </StyledTrigger>
  </StyledHeader>
));

const StyledContent = styled(AccordionPrimitive.Content, {
  $$animationLength: '250ms',
  overflow: 'hidden',
  fontSize: 15,
  marginLeft: '12px',
  paddingX: '$1',
  borderLeft: '$borderWidths$1 $borderStyles$1 $colors$gray9',
  '&[data-state="open"]': {
    animation: `${slideDown} $$animationLength linear`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} $$animationLength linear`,
  },
});

const StyledContentText = styled('div', {
  padding: '$3',
});

export const StepContent = React.forwardRef<
  HTMLDivElement,
  AccordionPrimitive.AccordionContentProps
  // eslint-disable-next-line
  // @ts-ignore HACK to get other typechecks working. Something weird is going on with @emotion
>(({ children, css: _, ...props }, forwardedRef) => (
  <StyledContent {...props} ref={forwardedRef}>
    <StyledContentText>{children}</StyledContentText>
  </StyledContent>
));
