const format = require('@b7hio/core-lib/i18next-format');

module.exports = {
  defaultLocale: 'en',
  locales: ['en'],
  serializeConfig: false,
  interpolation: {
    escapeValue: false,
    format: format,
  },
};
