// TODO: Merge these methods with useAuth and reduce complexity
import { Auth } from 'aws-amplify';
import { useRouter } from 'next/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ForgotPasswordCompleteForm, ForgotPasswordForm } from '../components';

type ForgotPasswordCompleteFormProps = React.ComponentProps<
  typeof ForgotPasswordCompleteForm
>;
type ForgotPasswordFormProps = React.ComponentProps<typeof ForgotPasswordForm>;

export function useAmplifyForgotPassword() {
  const { t } = useTranslation(['login']);
  const [error, setError] = React.useState<string>();
  const [username, setUsername] = React.useState('');
  const [destination, setDestination] = React.useState('');
  const router = useRouter();
  const challengeSent = Boolean(username);

  const handleForgotPasswordSubmit: ForgotPasswordFormProps['onSubmit'] =
    async ({ email }) => {
      setError('');

      try {
        const {
          CodeDeliveryDetails: { Destination },
        } = await Auth.forgotPassword(email);
        setDestination(Destination);
        setUsername(email);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Forgot error', e);
      }
    };

  const handleForgotPasswordCompleteSubmit: ForgotPasswordCompleteFormProps['onSubmit'] =
    async ({ code, password }, setFieldError) => {
      setError('');

      try {
        await Auth.forgotPasswordSubmit(username, code, password);
        await router.push('/');
      } catch (e) {
        // @ts-expect-error TODO io-ts decode type
        if (e.name === 'CodeMismatchException') {
          setFieldError('code', { message: t('login:error.invalidCode') });
        }
      }
    };

  return {
    error,
    destination,
    challengeSent,
    handleForgotPasswordSubmit,
    handleForgotPasswordCompleteSubmit,
  };
}
