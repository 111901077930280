import React from 'react';
import NumberFormat from 'react-number-format';

interface CustomProps {
  readonly onChange: (event: {
    readonly target: { readonly name: string; readonly value: string };
  }) => void;
  readonly name: string;
}

export const CurrencyFormat = React.forwardRef<NumberFormat, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="£"
      />
    );
  }
);
