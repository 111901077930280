import { Logo, LoadingSpinner } from '@b7hio/core-lib/components';
import { CSSObject, keyframes, Theme, useTheme } from '@emotion/react';
import AccountCircleRounded from '@mui/icons-material/AccountCircleRounded';
import ChevronLeftRounded from '@mui/icons-material/ChevronLeftRounded';
import DescriptionOutlined from '@mui/icons-material/DescriptionOutlined';
import HomeOutlined from '@mui/icons-material/HomeOutlined';
import LocalMallOutlined from '@mui/icons-material/LocalMallOutlined';
import MenuIcon from '@mui/icons-material/MenuRounded';
import PeopleOutlined from '@mui/icons-material/PeopleOutlined';
import StorefrontOutlined from '@mui/icons-material/StorefrontOutlined';
import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Box,
  Drawer as MuiDrawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Suspense, useEffect, useState } from 'react';
import type React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { UserProvider } from '../../hooks/useUser';
import { NavItem, NavItemProps } from './components/nav-item';
import { useAuthLogin } from './use-auth-login';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const routes: NavItemProps[] = [
  { name: 'Home', route: '/', icon: <HomeOutlined /> },
  { name: 'Products', route: '/products', icon: <LocalMallOutlined /> },
  { name: 'Insights', route: '/insights', icon: <StorefrontOutlined /> },
  {
    name: 'Users',
    route: '/users',
    icon: <PeopleOutlined />,
  },
  {
    name: 'Files',
    route: '/files',
    icon: <DescriptionOutlined />,
  },
];

export const Shell: React.FC = ({ children }) => {
  const { t } = useTranslation(['shell']);
  const router = useRouter();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = !isDesktop;
  // If we're on a desktop we default the layout to have a persistent navigation
  const [openNavMenu, setOpenNavMenu] = useState(isDesktop);
  const [userMenuRef, setUserMenuRef] = useState<null | HTMLElement>(null);
  const [user, handleLogout] = useAuthLogin();

  useEffect(() => {
    if (isDesktop && !openNavMenu) {
      setOpenNavMenu(true);
    }
    if (!isDesktop && openNavMenu) {
      setOpenNavMenu(false);
    }
  }, [isDesktop]);

  const handleDrawerOpen = () => setOpenNavMenu(true);
  const handleDrawerClose = () => setOpenNavMenu(false);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setUserMenuRef(event.currentTarget);
  };
  const handleCloseUserMenu = () => setUserMenuRef(null);

  useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleLogout,
    crossTab: true,
    debounce: 500,
  });

  if (!user) {
    return (
      <Box
        data-testid="loading-logo"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
          height: '100vh',
          p: 2,
        }}>
        <Logo
          variant="primary"
          iconOnly
          sx={{
            height: '128px',
            width: '128px',
            animation: (theme: Theme) =>
              `${slowDelayedPulse} 4s infinite ${theme.transitions.easing.easeInOut}`,
          }}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexGrow: 1 }}>
      <Drawer
        id="navigationDrawer"
        data-testid="navigationDrawer"
        variant={isDesktop ? 'permanent' : 'temporary'}
        anchor="left"
        open={openNavMenu}
        onClose={isMobile ? handleDrawerClose : undefined}>
        <DrawerHeader
          sx={{
            backgroundColor: 'background.paper',
          }}>
          {openNavMenu && (
            <>
              <Logo
                variant="primary"
                sx={{ height: 23, width: 128, ml: 2.5 }}
              />
              <IconButton data-testid="closeNav" onClick={handleDrawerClose}>
                <ChevronLeftRounded />
              </IconButton>
            </>
          )}
          {!openNavMenu && (
            <IconButton
              color="inherit"
              aria-controls="navigationDrawer"
              aria-label={t('shell:actions.openDrawer')}
              aria-expanded={false}
              onClick={handleDrawerOpen}
              data-testid="openNav"
              edge="start"
              sx={{
                mr: 1,
                ml: 1,
                color: theme.palette.primary.main,
              }}>
              <MenuIcon />
            </IconButton>
          )}
        </DrawerHeader>
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="nested-list-subheader">
          {routes.map((props) => (
            <NavItem key={props.route} open={openNavMenu} {...props} />
          ))}
        </List>
        <div style={{ flexGrow: 1 }} />
        <List>
          <ListItemButton
            aria-label="account of current user"
            aria-controls="user-menu"
            aria-haspopup="true"
            sx={{ mx: 1, borderRadius: 1 }}
            onClick={handleOpenUserMenu}>
            <NavIcon>
              <AccountCircleRounded />
            </NavIcon>
            <ListItemText
              primary={
                user.attributes.given_name + ' ' + user.attributes.family_name
              }
              primaryTypographyProps={{
                variant: 'body1',
                color: 'primary',
                sx: { fontWeight: 600 },
              }}
            />
          </ListItemButton>
        </List>
        <Menu
          id="user-menu"
          anchorEl={userMenuRef}
          keepMounted
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          open={Boolean(userMenuRef)}
          onClose={handleCloseUserMenu}>
          <MenuItem onClick={() => router.push('/settings')}>Settings</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Drawer>
      {/* Having this toolbar just pads the height of the above toolbar */}
      {isMobile && (
        <AppBar position="absolute" open={openNavMenu} data-testid="header">
          <Toolbar sx={{ display: 'grid', gridTemplateColumns: '32px 1fr' }}>
            <IconButton
              aria-controls="navigationDrawer"
              aria-label={t('shell:actions.openDrawer')}
              aria-expanded={false}
              onClick={handleDrawerOpen}
              data-testid="openNav"
              edge="start"
              sx={{
                color: theme.palette.background.default,
              }}>
              <MenuIcon />
            </IconButton>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexGrow: 1,
                flexShrink: 0,
              }}>
              <Logo
                variant="white"
                sx={{ height: '32px', width: '128px', ml: -5 }}
              />
            </Box>
          </Toolbar>
        </AppBar>
      )}
      <MainContainer
        open={openNavMenu}
        sx={{
          width: {
            xs: '100%',
            md: `calc(100% - ${
              openNavMenu ? expandedDrawerWidth : contractedDrawerWidth
            }px)`,
          },
        }}>
        <Main
          sx={{
            p: 3,
            minHeight: '100vh',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <UserProvider user={user}>
            <Suspense fallback={<LoadingSpinner />}>{children}</Suspense>
          </UserProvider>
        </Main>
      </MainContainer>
    </Box>
  );
};

const expandedDrawerWidth = 240;
const contractedDrawerWidth = 72;

const openedMixin = (theme: Theme): CSSObject => ({
  width: expandedDrawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: contractedDrawerWidth,
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
});

const AppBar = styled(MuiAppBar)<AppBarProps>(() => ({
  boxShadow: 'none',
  background: 'transparent',
}));

const Drawer = styled(MuiDrawer)(({ theme, open, variant }) => ({
  ...(variant === 'permanent' && {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    boxShadow: theme.shadows[4],
    zIndex: theme.zIndex.appBar + 1,
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  borderBottom: `1px solid ${theme.palette.divider}`,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const NavIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const Main = styled('main')({});

const MainContainer = styled('div')<{ open: boolean }>(({ theme, open }) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: open
      ? theme.transitions.duration.enteringScreen
      : theme.transitions.duration.leavingScreen,
  }),
}));

const slowDelayedPulse = keyframes`
  from, 20% {
    transform: scale(100%);
  }
  60% {
    transform: scale(60%);
  }
  to {
    transform: scale(100%);
  }
`;
