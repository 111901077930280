import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '../../stitches.config';

const StepperWrapper = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
});
const StepWrapper = styled('div', {
  paddingLeft: '$2',
  paddingRight: '$2',
  flex: 1,
  position: 'relative',
});
const StepInner = styled('span', {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});
const StepNumber = styled('span', {
  flexShrink: 0,
  display: 'flex',
  paddingRight: 0,
  fill: '$colors$primary',
  '& > *': {
    color: '$colors$primary',
    fontSize: '$fontSizes$3',
  },
  transition: 'all 350s linear',
});

const LabelHolder = styled('span', {
  width: '100%',
  color: '$hiContrast',
});
const NumberHolder = styled('svg', {
  $$fillColor: '$colors$gray11',
  userSelect: 'none',
  width: '1em',
  height: '1em',
  display: 'inline-block',
  fill: '$$fillColor',
  flexShrink: 0,
  fontSize: '$fontSizes$3',
  variants: {
    variant: {
      active: {
        $$fillColor: '$colors$primary',
      },
      inactive: {
        $$fillColor: '$colors$gray11',
      },
    },
  },
  defaultVariants: {
    variant: 'inactive',
  },
});
const TextHolder = styled('text', {
  fill: '$loContrast',
  fontSize: '0.75rem',
  fontWeight: '$fontWeights$3',
});
const DividerWrapper = styled('div', {
  flex: '1 1 auto',
  position: 'absolute',
  top: '12px',
  left: 'calc(-50% + 20px)',
  right: 'calc(50% + 20px)',
});
const StepDivider = styled('span', {
  $$background: '$colors$gray9',
  display: 'block',
  borderTopStyle: '$borderStyles$1',
  borderTopWidth: '$borderWidths$1',
  borderColor: '$$background',
  variants: {
    variant: {
      check: {
        $$background: '$colors$primary',
      },
      error: {
        $$background: '$colors$error',
      },
    },
  },
});

const StyledCircle = styled('circle', {
  cx: 12,
  cy: 12,
  r: 12,
});

export interface StepperProps {
  readonly activeStep?: number;
  readonly children: React.ReactNode;
}

export const HorizontalStepper = ({ children }: StepperProps): JSX.Element => {
  return <StepperWrapper>{children}</StepperWrapper>;
};

export const HorizontalStepLabel = styled('span', {
  textAlign: 'center',
  marginTop: '$3',
  display: 'block',
});

interface StepProps {
  readonly step: number;
  readonly completed?: boolean;
  readonly active?: boolean;
  readonly children: React.ReactNode;
}
export const HorizontalStep = ({
  step,
  children,
  completed,
  active,
}: StepProps): JSX.Element => {
  return (
    <StepWrapper>
      {step != 1 && (
        <DividerWrapper>
          <StepDivider></StepDivider>
        </DividerWrapper>
      )}
      <StepInner>
        <StepNumber>
          {completed ? (
            <FontAwesomeIcon
              icon={['fas', `check-circle`]}
              style={{ fontSize: '24px' }}
            />
          ) : (
            <NumberHolder variant={`${active ? 'active' : 'inactive'}`}>
              <StyledCircle></StyledCircle>
              <TextHolder x="12" y="16" textAnchor="middle">
                {step}
              </TextHolder>
            </NumberHolder>
          )}
        </StepNumber>
        <LabelHolder>
          <HorizontalStepLabel>{children}</HorizontalStepLabel>
        </LabelHolder>
      </StepInner>
    </StepWrapper>
  );
};
