import Axios from 'axios';
import qs from 'qs';
import { Address, FindItem } from './types';

const LOQATE_KEY = 'TU42-WH95-WZ67-ED18';
// const LOQATE_KEY = 'BJ23-BX33-AH88-FB69';

const http = Axios.create({
  baseURL: 'https://api.addressy.com/Capture/Interactive',
  params: {
    Key: LOQATE_KEY,
  },
  paramsSerializer: (params) =>
    qs.stringify(params, {
      arrayFormat: 'comma',
    }),
});

enum Endpoints {
  FIND = 'Find/v1.10/json3.ws',
  RETRIEVE = 'Retrieve/v1/json3.ws',
}

export interface FindQuery {
  readonly text: string;
  readonly language: string;
  readonly countries?: readonly string[];
  readonly limit?: number;
  readonly containerId?: string;
}

export async function retrieveAddress(id: string) {
  const response = await http.get<{ readonly Items: readonly Address[] }>(
    Endpoints.RETRIEVE,
    {
      params: {
        Id: id,
      },
    }
  );

  return response.data.Items[0];
}

export async function findAddresses({
  text,
  countries = [],
  containerId,
  language,
  limit,
}: FindQuery) {
  const response = await http.get<{ readonly Items: readonly FindItem[] }>(
    Endpoints.FIND,
    {
      params: {
        Text: text,
        Countries: countries,
        Container: containerId,
        limit,
        language,
      },
    }
  );

  return response.data.Items;
}
