import { Alert, Typography } from '@mui/material';
import { NextSeo } from 'next-seo';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ForgotPasswordCompleteForm, ForgotPasswordForm } from './components';

type ForgotPasswordCompleteFormProps = React.ComponentProps<
  typeof ForgotPasswordCompleteForm
>;
type ForgotPasswordFormProps = React.ComponentProps<typeof ForgotPasswordForm>;

interface Props {
  readonly error?: string;
  readonly destination?: string;
  readonly reason?: 'resetRequired';
  readonly challengeSent?: boolean;
  readonly onForgotPasswordSubmit: ForgotPasswordFormProps['onSubmit'];
  readonly onForgotPasswordCompleteSubmit: ForgotPasswordCompleteFormProps['onSubmit'];
}

export const ForgotPasswordPage = ({
  error,
  destination,
  reason,
  challengeSent,
  onForgotPasswordSubmit,
  onForgotPasswordCompleteSubmit,
}: Props): JSX.Element => {
  const { t } = useTranslation(['login']);

  return (
    <>
      <NextSeo title={t('login:page.forgotTitle')} />
      <Typography variant="h5" sx={{ mb: 3 }}>
        Forgot Password
      </Typography>

      {destination && (
        <Alert severity="info" sx={{ mb: 3 }}>
          {t('login:content.codeSent', { destination })}
        </Alert>
      )}

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}
      {reason === 'resetRequired' && (
        <Alert severity="warning" sx={{ mb: 3 }}>
          {t('login:content.resetRequired')}
        </Alert>
      )}

      {!challengeSent ? (
        <ForgotPasswordForm onSubmit={onForgotPasswordSubmit} />
      ) : (
        <ForgotPasswordCompleteForm onSubmit={onForgotPasswordCompleteSubmit} />
      )}
    </>
  );
};
