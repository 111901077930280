import { BondsmithUser } from '@b7hio/core-lib/src/features/auth/hooks/useAuth';
import React, { useContext } from 'react';

const UserContext = React.createContext<BondsmithUser | undefined>(undefined);

export const UserProvider: React.FC<{ user: BondsmithUser }> = ({
  user,
  children,
}) => {
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export const useUser = (): [
  user: BondsmithUser,
  partnerUid: string,
  userType: string,
  role: string
] => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('No user found in context');
  }

  const partnerUid = (context.attributes as any)['custom:b7h:partner_uid'];
  const userType = (context.attributes as any)['custom:b7h:user_type'];
  const role = (context.attributes as any)['custom:b7h:role'];

  if (!partnerUid) {
    throw new Error('No Partner Uid found.');
  }

  return [context, partnerUid, userType, role];
};
