import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as RA from 'fp-ts/ReadonlyArray';
import { styled } from '../../stitches.config';
import { colorsHsla } from '../../theme/colors';

const StepperWrapper = styled('div', {
  alignItems: 'center',
  display: 'flex',
  gap: '$4',
});

type HorizontalArrowStep = {
  readonly label?: string;
  // TODO Tentative Content component for customisable labels
};

export interface CircularStepperProps {
  readonly activeStep: number;
  readonly steps: readonly HorizontalArrowStep[];
}
const strokeWidth = 14;
const size = 120;
const center = size / 2;
const radius = center - strokeWidth / 2;

export const CircularStepper = ({
  activeStep,
  steps,
}: CircularStepperProps): JSX.Element => {
  const stepsLength = steps.length;
  const cappedStep = Math.max(0, Math.min(activeStep, stepsLength - 1));
  const stepNumber = cappedStep + 1;
  const progress = stepNumber / stepsLength;

  const pathLength = 100;

  const stepO = pipe(steps, RA.lookup(cappedStep));
  const nextStepO = pipe(steps, RA.lookup(cappedStep + 1));
  return (
    <StepperWrapper>
      <svg width="5em" height="5em" viewBox={`0 0 ${size} ${size}`}>
        {/* TODO svg-specific CircularProgress component */}
        <linearGradient
          // deg="180deg"
          id="linearColors1"
          x1="0"
          y1="0"
          x2="1"
          y2="1">
          <stop offset="0%" stopColor={colorsHsla('brandGradientEnd')} />
          <stop offset="100%" stopColor={colorsHsla('brandGradientStart')} />
        </linearGradient>
        <text
          textAnchor="middle"
          alignmentBaseline="middle"
          // TODO Proper fontSize reference
          fontSize="1.5rem"
          y={center}
          x={center}>
          {`${stepNumber} / ${stepsLength}`}
        </text>
        <circle
          cx={center}
          cy={center}
          r={radius}
          fill="none"
          stroke={colorsHsla('brand3')}
          strokeWidth={strokeWidth}
        />
        <circle
          transform="rotate(-90)"
          style={{ transformOrigin: 'center' }}
          strokeDasharray={100}
          strokeDashoffset={pathLength - pathLength * progress}
          cx={center}
          cy={center}
          r={radius}
          fill="none"
          stroke="url(#linearColors1)"
          strokeWidth={strokeWidth}
          pathLength={pathLength}
        />
      </svg>
      <div>
        <div>
          <Label>
            {pipe(
              stepO,
              O.fold(
                () => <br />,
                ({ label }) => (label ? label : <br />)
              )
            )}
          </Label>
        </div>
        <div>
          <NextStep>
            {pipe(
              nextStepO,
              O.fold(
                () => <>Last step</>,
                ({ label }) =>
                  label ? (
                    <>
                      <strong>Next step:</strong> {label}
                    </>
                  ) : (
                    <br />
                  )
              )
            )}
          </NextStep>
        </div>
      </div>
    </StepperWrapper>
  );
};

const Label = styled(`span`, {
  fontSize: '$2',
  color: '$navyBlue9',
  fontWeight: '$5',
});

const NextStep = styled(`span`, {
  fontSize: '$1',
  color: '$gray11',
});
