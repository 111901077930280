import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faWandMagic,
  faXmark,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCalendar,
  faInfoCircle,
  faCheck,
  faCheckCircle,
  faHouse,
  faHome,
  faBagShopping,
  faCartShopping,
  faMoneyCheckDollar,
  faMoneyCheckAlt,
  faBank,
  faUser,
  faUserPlus,
  faPlus,
  faUserCircle,
  faBell,
  faPlusCircle,
  faPen,
  faEdit,
  faDownload,
  faEllipsisV,
  faFileAlt,
  faSearch,
  faBars,
  faHandHoldingUsd,
  faWallet,
  faIdBadge,
  faComments,
  faCog,
  faEnvelope,
  faPowerOff,
  faPaperclip,
  faTrashCan,
  faLandmark,
  faCalendarDay,
  faAngleRight,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faWandMagic,
  faXmark,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCalendar,
  faInfoCircle,
  faCheck,
  faCheckCircle,
  faHouse,
  faHome,
  faBagShopping,
  faCartShopping,
  faMoneyCheckDollar,
  faMoneyCheckAlt,
  faBank,
  faUser,
  faUserPlus,
  faPlus,
  faUserCircle,
  faBell,
  faPlusCircle,
  faPen,
  faEdit,
  faDownload,
  faEllipsisV,
  faFileAlt,
  faSearch,
  faBars,
  faHandHoldingUsd,
  faWallet,
  faIdBadge,
  faComments,
  faCog,
  faEnvelope,
  faPowerOff,
  faPaperclip,
  faTrashCan,
  faLandmark,
  faCalendarDay,
  faAngleRight,
  faCircle
);
