import { styled } from '../../stitches.config';

export const IconButton = styled('button', {
  $$hoverBgColor: 'transparent',
  $$focusShadow: 'none',
  color: '$$textColor',
  background: '$$bgColor',
  fontWeight: '$$fontWeight',
  borderRadius: '$round',
  height: '2rem',
  width: '2rem',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '$$fontSize',
  border: 'none',
  transition: 'box-shadow $1, background $1, transform $1',
  outline: 'none',
  boxShadow: '$$shadow',
  cursor: 'pointer',

  // Interactive
  '&:hover, &:focus': {
    boxShadow: '$$hoverShadow',
    background: '$$hoverBgColor',
    color: '$$hoverTextColor',
  },

  '&:active': {
    boxShadow: '$$focusShadow',
    transform: 'translateY(0.08rem)',
  },
  '&:disabled': {
    pointerEvents: 'none',
    // $$bgColor: '$colors$gray5',
    $$textColor: '$colors$gray10',
  },
  variants: {
    color: {
      primary: {
        $$textColor: '$colors$loContrast',
        $$bgColor: '$colors$primary',
        $$borderColor: '$colors$brand6',
        $$shadow: '$shadows$containedButton $colors$brand4',

        // Interactive
        $$hoverTextColor: '$colors$loContrast',
        $$hoverBgColor: '$colors$brand10',
        $$hoverShadow: '0 0px 4px 3px $colors$brand4',
        $$focusBgColor: '$colors$brand7',
      },
      gray: {
        $$textColor: '$colors$loContrast',
        $$bgColor: '$colors$gray9',
        $$borderColor: '$colors$gray6',
        $$shadow: '$shadows$containedButton $colors$gray4',

        // Interactive
        $$hoverTextColor: '$colors$loContrast',
        $$hoverBgColor: '$colors$gray10',
        $$hoverShadow: '0 0px 4px 3px $colors$gray4',
        $$focusBgColor: '$colors$gray7',
      },
      gradient: {
        $$textColor: '$colors$loContrast',
        $$bgColor: '$gradients$buttonBlueGradient',
        $$borderColor: 'transparent',
        $$shadow: '$shadows$containedButton $colors$gray7',
        // Interactive
        $$hoverTextColor: '$colors$loContrast',
        $$hoverBgColor: '$gradients$buttonDarkBlueGradient',
        $$hoverShadow: '0 0px 4px 3px $colors$gray7',
        $$focusBgColor: '$gradients$buttonDarkBlueGradient',
      },
      special: {
        $$textColor: '$colors$primary',
        $$hoverTextColor: '$colors$navyBlue9',
      },
      tonal: {
        $$textColor: '$colors$primary',
        $$bgColor: '$colors$brand3',
        $$borderColor: 'none',
        $$shadow: 'none',

        // Interactive
        $$hoverTextColor: '$colors$brand10',

        $$hoverBgColor: '$colors$brand4',
        $$hoverShadow: 'none',
        $$focusBgColor: '$colors$brand5',
      },
    },
    static: {
      true: {
        '&:hover, &:focus': {
          transform: 'none',
          boxShadow: '$$shadow',
          background: '$$hoverBgColor',
        },

        '&:active': {
          transform: 'none',
        },
      },
    },
    align: {
      right: {
        float: 'right',
      },
      left: {
        float: 'left',
      },
    },
    variant: {
      circle: {
        borderRadius: '$round',
      },
      squarcicle: {
        borderRadius: '$2',
      },
      noShape: {
        $$bgColor: 'transparent',
        $$borderColor: 'none',
        $$shadow: 'none',
        $$hoverBgColor: 'transparent',
        $$hoverShadow: 'none',
        $$focusBgColor: 'transparent',
      },
    },
    fontWeight: {
      bold: {
        $$fontWeight: '$fontWeights$4',
      },
      semiBold: {
        textShadow: '0.5px 0 0 currentColor',
        letterSpacing: '$letterSpacings$1',
      },
    },
    navbar: {
      true: {
        height: '1.6rem',
        width: '1.6rem',
        $$fontSize: 'calc($fontSizes$1 - 4px)',
        $$textColor: '$colors$loContrast',
        $$hoverTextColor: '$colors$loContrast',
        $$bgColor: '$colors$red9',
        $$borderColor: 'none',
        $$shadow: 'none',
        $$hoverBgColor: '$colors$red10',
        $$hoverShadow: '$colors$brand3 0px 0px 0px 6px',
        $$focusBgColor: '$colors$red10',
        $$focusShadow: '$colors$brand4 0px 0px 0px 6px',
      },
      false: {},
    },
    size: {
      small: {
        $$fontSize: 'calc($fontSizes$1 - 1px)',
      },
      medium: {
        $$fontSize: '$fontSizes$1',
      },
      large: {
        $$fontSize: 'calc($fontSizes$1 + 3px)',
      },
      xLarge: {
        $$fontSize: '$fontSizes$2',
      },
    },
  },
  compoundVariants: [
    {
      variant: 'noShape',
      color: 'primary',
      css: {
        $$bgColor: 'transparent',
        $$borderColor: 'none',
        $$shadow: 'none',
        $$hoverBgColor: 'transparent',
        $$hoverShadow: 'none',
        $$focusBgColor: 'transparent',
        $$textColor: '$colors$primary',

        // Interactive
        $$hoverTextColor: '$colors$brand10',
      },
    },
    {
      variant: 'noShape',
      navbar: true,
      css: {
        $$bgColor: 'transparent',
        $$borderColor: 'none',
        $$shadow: 'none',
        $$hoverBgColor: '$colors$brand3',
        $$focusBgColor: '$colors$brand4',
        $$hoverShadow: '$colors$brand3 0px 0px 0px 4px',
        $$focusShadow: '$colors$brand4 0px 0px 0px 4px',
      },
    },
  ],
  defaultVariants: {
    variant: 'circle',
  },
});
