import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import type { TextFieldProps } from '@mui/material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useState } from 'react';
import type { UseControllerProps } from 'react-hook-form';
import { useController } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';
import type { TFunction } from 'react-i18next';
import * as NumberFormat from 'react-number-format';
import { getFieldErrors } from '../../../form';
import { CustomMuiTextFieldCss } from '../../../utils/CustomMuiTextFieldCss';
import { CurrencyFormat } from './components/CurrencyFormat';

type Props<T extends FieldValues> = UseControllerProps<T> & {
  readonly label: string;
  readonly type?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly t: TFunction<any>;
  readonly disabled?: boolean;
  readonly size?: TextFieldProps['size'];
  readonly fullWidth?: boolean;
  readonly margin?: TextFieldProps['margin'];
  readonly transform?: (value: string) => string;
  readonly helperText?: string;
  readonly getErrorPath?: (type: string) => string | undefined;
  //TODO: separate currency format to different formfield component
  readonly currencyFormat?: boolean;
  readonly multiline?: boolean;
  readonly minRows?: number;
  readonly customMuiTextField?: boolean;
  readonly numberInputProps?: NumberFormat.NumberFormatProps;
};

export function FormTextField<T extends FieldValues = FieldValues>(
  props: Props<T>
) {
  const {
    name,
    label,
    helperText,
    t,
    type,
    disabled,
    size,
    fullWidth,
    margin,
    transform,
    getErrorPath,
    currencyFormat,
    multiline,
    minRows,
    customMuiTextField,
    numberInputProps,
  } = props;
  const {
    field: { ref, ...field },
    formState,
  } = useController(props);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = transform?.(e.target.value) ?? e.target.value;
    field.onChange(newValue);
  };

  const errors = getFieldErrors(field.name, formState, t, getErrorPath);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword: () => void = () =>
    setShowPassword(!showPassword);

  if (currencyFormat) {
    return (
      <TextField
        sx={customMuiTextField ? CustomMuiTextFieldCss : {}}
        type={type}
        id={name}
        label={label}
        inputRef={ref}
        helperText={helperText || errors.helperText}
        margin={margin}
        {...field}
        error={errors.error}
        onChange={handleChange}
        size={size}
        fullWidth={fullWidth}
        multiline={multiline}
        minRows={minRows}
        disabled={disabled || errors.disabled}
        InputProps={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          inputComponent: CurrencyFormat as any,
          inputProps: numberInputProps,
        }}
        variant={customMuiTextField ? 'filled' : 'outlined'}
      />
    );
  }

  const inputProps: { endAdornment?: JSX.Element } =
    type === 'password'
      ? {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }
      : {};

  const displayType =
    type === 'password' && !showPassword ? 'password' : 'text';

  return (
    <TextField
      sx={customMuiTextField ? CustomMuiTextFieldCss : {}}
      type={displayType}
      id={name}
      label={label}
      inputRef={ref}
      margin={margin}
      helperText={helperText || errors.helperText}
      {...field}
      error={errors.error}
      onChange={handleChange}
      size={size}
      fullWidth={fullWidth}
      disabled={disabled || errors.disabled}
      variant={customMuiTextField ? 'filled' : 'outlined'}
      InputProps={inputProps}
    />
  );
}
