import { DateRangePicker as MuiDateRangePicker } from '@mui/lab';
import type { TextFieldProps } from '@mui/material';
import { Box, TextField } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { useController } from 'react-hook-form';
import { Control, FieldPath, FieldValues } from 'react-hook-form/dist/types';
import type { TFunction } from 'react-i18next';
import { getFieldErrors } from '../../../form';

export interface DateRangePickerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  readonly nameFrom: TName;
  readonly nameTo: TName;
  readonly labelFrom: string;
  readonly labelTo: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly t: TFunction<any>;
  readonly control?: Control<TFieldValues>;
  readonly size?: TextFieldProps['size'];
  readonly hideMiddle?: boolean;
  readonly gap?: number;
  readonly getErrorPath?: (type: string) => string | undefined;
}

export const FormDateRangePicker = <T extends FieldValues>({
  t,
  nameFrom,
  nameTo,
  labelFrom,
  labelTo,
  control,
  size,
  hideMiddle,
  gap,
  getErrorPath,
}: DateRangePickerProps<T>): JSX.Element => {
  const {
    field: { ref: fromFieldRef, ...fromField },
    formState,
  } = useController({ name: nameFrom, control });
  const {
    field: { ref: toFieldRef, ...toField },
  } = useController({ name: nameTo, control });

  let fromValue: string | null | DateTime = fromField.value || null;

  let toValue: string | null | DateTime = toField.value || null;

  if (fromValue) {
    fromValue = DateTime.fromISO(fromField.value);
  }

  if (toValue) {
    toValue = DateTime.fromISO(toField.value);
  }

  return (
    <MuiDateRangePicker<DateTime>
      startText={labelFrom}
      endText={labelTo}
      renderInput={(startProps, endProps) => (
        <Box
          display="grid"
          gap={gap ?? 2}
          flexGrow={1}
          alignItems="baseline"
          gridTemplateColumns={hideMiddle ? '1fr 1fr' : '1fr 16px 1fr'}>
          <TextField
            {...startProps}
            {...getFieldErrors(fromField.name, formState, t, getErrorPath)}
            id={fromField.name}
            name={fromField.name}
            onBlur={fromField.onBlur}
            ref={fromFieldRef}
            size={size}
          />
          {!hideMiddle && (
            <Box display="flex" justifyContent="center" alignItems="center">
              &le;
            </Box>
          )}
          <TextField
            {...endProps}
            {...getFieldErrors(toField.name, formState, t, getErrorPath)}
            id={toField.name}
            name={toField.name}
            onBlur={toField.onBlur}
            ref={toFieldRef}
            size={size}
          />
        </Box>
      )}
      value={[fromValue, toValue]}
      disabled={formState.isSubmitting}
      onChange={(dates) => {
        fromField.onChange(dates[0]?.toISODate());
        toField.onChange(dates[1]?.toISODate());
      }}
    />
  );
};
