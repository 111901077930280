import { blackA, whiteA } from '@radix-ui/colors';

export const brand = {
  brand1: 'hsla(192, 33%, 97%, 1)',
  brand2: 'hsla(202, 52%, 96%, 1)',
  brand3: 'hsla(205, 66%, 94%, 1)',
  brand4: 'hsla(207, 68%, 91%, 1)',
  brand5: 'hsla(208, 80%, 88%, 1)',
  brand6: 'hsla(208, 73%, 83%, 1)',
  brand7: 'hsla(207, 72%, 75%, 1)',
  brand8: 'hsla(208, 68%, 70%, 1)',
  brand9: 'hsla(210, 53%, 53%, 1)',
  brand10: 'hsla(211, 61%, 43%, 1)',
  brand11: 'hsla(211, 57%, 35%, 1)',
  brand12: 'hsla(210, 41%, 17%, 1)',
  brandGradientStart: 'hsla(210, 53%, 53%, 1)',
  brandGradientEnd: 'hsla(211, 57%, 35%, 1)',
} as const;

export const navyBlue = {
  navyBlue1: 'hsla(207, 48%, 95%, 1)',
  navyBlue2: 'hsla(213, 47%, 93%, 1)',
  navyBlue3: 'hsla(211, 42%, 88%, 1)',
  navyBlue4: 'hsla(213, 45%, 85%, 1)',
  navyBlue5: 'hsla(214, 45%, 76%, 1)',
  navyBlue6: 'hsla(212, 46%, 68%, 1)',
  navyBlue7: 'hsla(212, 37%, 54%, 1)',
  navyBlue8: 'hsla(214, 33%, 35%, 1)',
  navyBlue9: 'hsla(225, 27%, 26%, 1)',
  navyBlue10: 'hsla(212, 37%, 41%, 1)',
  navyBlue11: 'hsla(222, 30%, 18%, 1)',
  navyBlue12: 'hsla(221, 33%, 11%, 1)',
  navyBlueGradientStart: 'hsla(225, 27%, 26%, 1)',
  navyBlueGradientEnd: 'hsla(212, 37%, 41%, 1)',
  //TODO: update gradients!!!
  navyBlueGradient2Start: 'hsla(233, 37%, 33%, 1)',
  navyBlueGradient2End: 'hsla(233, 37%, 27%, 1)',
} as const;

export const plum = {
  plum1: 'hsla(315, 50%, 98%, 1)',
  plum2: 'hsla(322, 44%, 96%, 1)',
  plum3: 'hsla(320, 46%, 95%, 1)',
  plum4: 'hsla(320, 45%, 92%, 1)',
  plum5: 'hsla(321, 47%, 88%, 1)',
  plum6: 'hsla(320, 45%, 82%, 1)',
  plum7: 'hsla(321, 45%, 74%, 1)',
  plum8: 'hsla(321, 45%, 67%, 1)',
  plum9: 'hsla(321, 45%, 51%, 1)',
  plum10: 'hsla(321, 43%, 46%, 1)',
  plum11: 'hsla(321, 43%, 41%, 1)',
  plum12: 'hsla(322, 42%, 10%, 1)',
  plum13: 'hsla(321, 45%, 57%, 1)',
  plum14: 'hsla(319, 28%, 46%, 1)',
  plumGradientStart: 'hsla(321, 45%, 51%, 1)',
  plumGradientEnd: 'hsla(250, 94%, 72%, 1)',
} as const;

export const neutral = {
  white: 'hsla(0, 0%, 100%, 1)',
  black: 'hsla(0, 0%, 0%, 1)',
} as const;

export const gray = {
  gray1: 'hsla(210, 33%, 99%, 1)',
  gray2: 'hsla(210, 17%, 98%, 1)',
  gray3: 'hsla(210, 17%, 95%, 1)',
  gray4: 'hsla(210, 12%, 93%, 1)',
  gray5: 'hsla(216, 11%, 91%, 1)',
  gray6: 'hsla(206, 12%, 89%, 1)',
  gray7: 'hsla(210, 11%, 86%, 1)',
  gray8: 'hsla(205, 11%, 78%, 1)',
  gray9: 'hsla(206, 6%, 56%, 1)',
  gray10: 'hsla(206, 6%, 52%, 1)',
  gray11: 'hsla(206, 6%, 44%, 1)',
  gray12: 'hsla(202, 24%, 9%, 1)',
  gray13: 'hsla(205, 7%, 65%, 1)',
  gray14: 'hsla(0, 0%, 38%, 1)',
  gray15: 'hsla(202, 24%, 9%, 0.14)',
  gray16: 'hsla(202, 24%, 9%, 0.87)',
  gray17: 'hsla(210, 16%, 97%, 1)',
  gray18: 'hsla(202, 24%, 9%, 0.2)',
  gray19: 'hsla(205, 6%, 43%, 1)',
  gray20: 'hsla(230, 29%, 92%, 1)',
} as const;

export const green = {
  green1: 'hsla(140, 60%, 99%, 1)',
  green2: 'hsla(138, 63%, 97%, 1)',
  green3: 'hsla(139, 57%, 95%, 1)',
  green4: 'hsla(139, 48%, 91%, 1)',
  green5: 'hsla(141, 44%, 86%, 1)',
  green6: 'hsla(142, 40%, 79%, 1)',
  green7: 'hsla(146, 38%, 69%, 1)',
  green8: 'hsla(151, 40%, 54%, 1)',
  green9: 'hsla(151, 55%, 42%, 0.9)',
  green10: 'hsla(152, 57%, 38%, 0.95)',
  green11: 'hsla(153, 67%, 28%, 1)',
  green12: 'hsla(155, 41%, 14%, 1)',
  green13: 'hsla(151, 40%, 54%, 1)',
  green14: 'hsla(152, 51%, 41%, 1)',
  green15: 'hsla(111, 62%, 84%, 1)',
} as const;

export const blue = {
  blue1: 'hsla(210, 100%, 99%, 1)',
  blue2: 'hsla(210, 100%, 98%, 1)',
  blue3: 'hsla(210, 100%, 96%, 1)',
  blue4: 'hsla(210, 100%, 94%, 1)',
  blue5: 'hsla(209, 96%, 90%, 1)',
  blue6: 'hsla(209, 82%, 85%, 1)',
  blue7: 'hsla(208, 78%, 77%, 1)',
  blue8: 'hsla(206, 82%, 65%, 1)',
  blue9: 'hsla(206, 100%, 53%, 1)',
  blue10: 'hsla(208, 100%, 47%, 1)',
  blue11: 'hsla(211, 99%, 43%, 1)',
  blue12: 'hsla(211, 100%, 15%, 1)',
  blue13: 'hsla(206, 100%, 60%, 1)',
} as const;

export const yellow = {
  yellow1: 'hsla(60, 50%, 98%, 1)',
  yellow2: 'hsla(51, 100%, 96%, 1)',
  yellow3: 'hsla(51, 95%, 92%, 1)',
  yellow4: 'hsla(50, 97%, 87%, 1)',
  yellow5: 'hsla(49, 98%, 82%, 1)',
  yellow6: 'hsla(46, 90%, 76%, 1)',
  yellow7: 'hsla(42, 80%, 68%, 1)',
  yellow8: 'hsla(46, 100%, 46%, 1)',
  yellow9: 'hsla(49, 92%, 50%, 1)',
  yellow10: 'hsla(46, 95%, 48%, 1)',
  yellow11: 'hsla(42, 100%, 29%, 1)',
  yellow12: 'hsla(36, 56%, 13%, 1)',
  yellow13: 'hsla(46, 85%, 57%, 1)',
} as const;

export const red = {
  red1: 'hsla(0, 27%, 98%, 1)',
  red2: 'hsla(0, 47%, 97%, 1)',
  red3: 'hsla(0, 57%, 95%, 1)',
  red4: 'hsla(0, 68%, 93%, 1)',
  red5: 'hsla(0, 69%, 90%, 1)',
  red6: 'hsla(0, 60%, 84%, 1)',
  red7: 'hsla(359, 60%, 78%, 1)',
  red8: 'hsla(359, 60%, 71%, 1)',
  red9: 'hsla(0, 59%, 61%, 1)',
  red10: 'hsla(0, 48%, 51%, 1)',
  red11: 'hsla(0, 60%, 38%, 1)',
  red12: 'hsla(0, 72%, 7%, 1)',
  red13: 'hsla(359, 53%, 61%, 1)',
} as const;

export const semantic = {
  success: 'hsla(151, 54%, 41%, 1)',
  info: 'hsla(205, 100%, 50%, 1)',
  warning: 'hsla(46, 95%, 48%, 1)',
  error: 'hsla(358, 75%, 59%, 1)',
} as const;
export const semanticTonal = {
  tonalSuccess: 'hsla(141, 44%, 86%, 1)',
  tonalInfo: 'hsla(209, 96%, 90%, 1)',
  tonalWarning: 'hsla(49, 98%, 82%, 1)',
  tonalError: 'hsla(0, 69%, 90%, 1)',
} as const;

export const charts = {
  chartBlue: 'hsla(201, 73%, 74%, 1)',
  chartNavyBlue: 'hsla(214, 33%, 35%, 1)',
  chartBrand: 'hsla(210, 53%, 53%, 1)',
  chartRed: 'hsla(0, 66%, 66%, 1)',
  chartGreen: 'hsla(70, 33%, 61%, 1)',
  chartTeal: 'hsla(169, 41%, 73%, 1)',
  chartYellow: 'hsla(40, 78%, 68%, 1)',
  chartPurple: 'hsla(319, 27%, 51%, 1)',
  chartGrey: 'hsla(212, 19%, 83%, 1)',
  chartPeach: 'hsla(14, 77%, 78%, 1)',
  chartOrange: 'hsl(32, 72%, 48%, 1)',
  chartGrey2: 'hsla(204, 6%, 65%, 1)',
};

export const ultramarineBlue = 'hsla(231, 89%, 62%, 1)';

export const shadows = {
  shadow1: 'hsla(201, 24%, 8%, 0.101)',
  shadow2: 'hsla(202, 24%, 9%, 0.2)',
  shadow3: 'hsla(202, 24%, 9%, 0.1)',
  grayFloatingButton: 'hsla(233, 40%, 28%, 0.2)',
  grayFloatingButtonHover: 'hsla(233, 40%, 28%, 0.25)',
  brandContainedButton: 'hsla(231, 89%, 62%, 0.5)',
  brandContainedButtonHover: 'hsla(214, 46%, 55%, 0.7)',
  brandContainedButtonActive: 'hsla(214, 39%, 39%, 0.5)',
  brandFloatingButtonHover: 'hsla(215, 48%, 46%, 0.7)',
  brandFloatingButtonActive: 'hsla(215, 45%, 37%, 0.7)',
};

export const colors = {
  ultramarineBlue,
  // brand
  ...brand,
  ...navyBlue,
  ...plum,

  // neutral
  ...neutral,
  ...gray,

  // contextual
  ...green,
  ...red,
  ...blue,
  ...yellow,

  // overlays only
  ...blackA,
  ...whiteA,

  // semantic
  ...semantic,
  ...semanticTonal,

  // shadows
  ...shadows,

  //charts colors
  ...charts,
} as const;

export type Colors = typeof colors;

/**
 * Gives back the hsla formatted color corresponding to the given name
 * @param color Name of the color
 * @returns Hsla formatted color `white ~> hsla(255, 100%, 100%, 1)`
 */
export function colorsHsla<C extends keyof Colors>(color: C): Colors[C] {
  return colors[color];
}

// export const brand = {
//   brand1: 'hsla(216, 45%, 98%, 1)',
//   brand2: 'hsla(227, 100%, 98%, 1)',
//   /** Hex #EDF1FF */
//   brand3: 'hsla(227, 100%, 96%, 1)',
//   /** Hex #E2E8FF */
//   brand4: 'hsla(228, 100%, 94%, 1)',
//   brand5: 'hsla(227, 100%, 92%, 1)',
//   brand6: 'hsla(227, 100%, 88%, 1)',
//   brand7: 'hsla(227, 100%, 83%, 1)',
//   brand8: 'hsla(227, 100%, 77%, 1)',
//   brand9: 'hsla(231, 89%, 62%, 1)',
//   /** Hex #4158DC */
//   brand10: 'hsla(231, 68%, 56%, 1)',
//   brand11: 'hsla(231, 55%, 49%, 1)',
//   brand12: 'hsla(230, 56%, 12%, 1)',
//   brand13: 'hsla(228, 100%, 69%, 1)',
//   brand14: 'hsla(233, 69%, 54%, 1)',
//   /** Hex #4862F4 */
//   brandGradientStart: 'hsla(231, 89%, 62%, 1)',
//   /** Hex #8972FB */
//   brandGradientEnd: 'hsla(250, 94%, 72%, 1)',
//   brand15: 'hsla(230, 100%, 98%, 1)',
// } as const;
// export const navyBlue = {
//   navyBlue1: 'hsla(220, 27%, 98%, 1)',
//   /** Hex #F3F3F7 */
//   navyBlue2: 'hsla(240, 20%, 96%, 1)',
//   navyBlue3: 'hsla(233, 21%, 93%, 1)',
//   navyBlue4: 'hsla(235, 20%, 89%, 1)',
//   navyBlue5: 'hsla(229, 20%, 84%, 1)',
//   navyBlue6: 'hsla(232, 20%, 78%, 1)',
//   navyBlue7: 'hsla(232, 19%, 70%, 1)',
//   navyBlue8: 'hsla(232, 20%, 56%, 1)',
//   /** Hex #333C77 */
//   navyBlue9: 'hsla(232, 40%, 33%, 1)',
//   navyBlue10: 'hsla(233, 40%, 28%, 1)',
//   navyBlue11: 'hsla(233, 39%, 20%, 1)',
//   navyBlue12: 'hsla(231, 41%, 7%, 1)',
//   navyBlue13: 'hsla(232, 30%, 40%, 1)',
//   navyBlueGradientStart: 'hsla(233, 40%, 28%, 1)',
//   navyBlueGradientEnd: 'hsla(219, 36%, 42%, 1)',
//   /** Hex #353C73 */
//   navyBlueGradient2Start: 'hsla(233, 37%, 33%, 1)',
//   /** Hex #2C3260 */
//   navyBlueGradient2End: 'hsla(233, 37%, 27%, 1)',
// } as const;

// export const red = {
//   red1: 'hsla(0, 100%, 99%, 1)',
//   red2: 'hsla(0, 100%, 99%, 1)',
//   red3: 'hsla(0, 100%, 97%, 1)',
//   red4: 'hsla(0, 100%, 95%, 1)',
//   red5: 'hsla(0, 90%, 92%, 1)',
//   red6: 'hsla(0, 81%, 88%, 1)',
//   red7: 'hsla(359, 74%, 82%, 1)',
//   red8: 'hsla(359, 69%, 74%, 1)',
//   red9: 'hsla(358, 75%, 63%, 1)',
//   red10: 'hsla(358, 69%, 57%, 1)',
//   red11: 'hsla(358, 64%, 48%, 1)',
//   red12: 'hsla(355, 49%, 15%, 1)',
//   red13: 'hsla(358, 75%, 67%, 1)',
// } as const;
