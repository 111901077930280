import { alpha, Switch } from '@mui/material';
import { styled } from '@stitches/react';
import { colorsHsla } from '../../theme/colors';
import { customShadow } from './CustomShadow';

export const MuiGreenSwitch = styled(Switch, {
  '& .MuiSwitch-switchBase.Mui-checked': {
    '& .MuiSwitch-thumb': {
      color: colorsHsla('green9'),
      boxShadow: customShadow('green9'),
    },
    '&:hover': {
      backgroundColor: alpha(colorsHsla('green4'), 0.3),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: colorsHsla('green7'),
  },
});
