import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  styled,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useState, SyntheticEvent } from 'react';
import { MfaLoginAskProps, MfaOptionsSubmitProps } from '../../auth.interface';

export enum MfaOptionsEnum {
  Sms = 'SMS_MFA',
  AuthApp = 'SOFTWARE_TOKEN_MFA',
  NoMfa = 'NOMFA',
}

interface Props {
  readonly onSubmit: ({ options }: MfaOptionsSubmitProps) => void;
  readonly onGoBack: () => void;
}

interface MfaOptionsLoginProps {
  readonly mfaMethods: readonly MfaOptionsEnum[];
  readonly onSubmit: ({ value }: MfaLoginAskProps) => void;
  readonly onGoBack: () => void;
}

export const MfaOptionsSetup = ({ onSubmit, onGoBack }: Props): JSX.Element => {
  const { t } = useTranslation(['login']);

  const [options, setOptions] = useState<MfaOptionsEnum[]>([]);

  const handleSetOption = (option: MfaOptionsEnum) => {
    if (options.includes(option)) {
      setOptions(options.filter((o) => o !== option));
    } else {
      setOptions([...options, option]);
    }
  };

  return (
    <FormContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1rem',
          justifyContent: 'space-between',
        }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox onChange={() => handleSetOption(MfaOptionsEnum.Sms)} />
            }
            label={t('login:stage.mfaOptions.content.sms')}
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => handleSetOption(MfaOptionsEnum.AuthApp)}
              />
            }
            label={t('login:stage.mfaOptions.content.authApp')}
          />
        </FormGroup>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1rem',
            justifyContent: 'center',
          }}>
          <Button
            color="primary"
            variant="contained"
            disabled={options.length === 0}
            onClick={() => onSubmit({ options })}>
            {t('login:form.confirm')}
          </Button>
          <Button color="primary" variant="text" onClick={onGoBack}>
            {t('login:form.back')}
          </Button>
        </Box>
      </Box>
    </FormContainer>
  );
};

export const MfaOptionsLogin = ({
  mfaMethods,
  onSubmit,
  onGoBack,
}: MfaOptionsLoginProps): JSX.Element => {
  const { t } = useTranslation(['login']);

  const [option, setOption] = useState<MfaOptionsEnum>(
    mfaMethods.includes(MfaOptionsEnum.Sms) &&
      !mfaMethods.includes(MfaOptionsEnum.AuthApp)
      ? MfaOptionsEnum.Sms
      : MfaOptionsEnum.AuthApp
  );

  const handleChange = (event: SyntheticEvent) => {
    setOption((event.target as HTMLInputElement).value as MfaOptionsEnum);
  };

  return (
    <FormContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1rem',
          justifyContent: 'space-between',
        }}>
        <RadioGroup
          defaultValue={
            mfaMethods.includes(MfaOptionsEnum.Sms) &&
            !mfaMethods.includes(MfaOptionsEnum.AuthApp)
              ? MfaOptionsEnum.Sms
              : MfaOptionsEnum.AuthApp
          }>
          <FormControlLabel
            value={MfaOptionsEnum.Sms}
            control={<Radio />}
            label={t('login:stage.mfaLoginAsk.content.sms')}
            onChange={handleChange}
          />
          {mfaMethods.includes(MfaOptionsEnum.AuthApp) && (
            <FormControlLabel
              value={MfaOptionsEnum.AuthApp}
              control={<Radio />}
              label={t('login:stage.mfaLoginAsk.content.authApp')}
              onChange={handleChange}
            />
          )}
        </RadioGroup>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1rem',
            justifyContent: 'center',
          }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => onSubmit({ value: option, resend: false })}>
            {t('login:form.confirm')}
          </Button>
          <Button color="primary" variant="text" onClick={onGoBack}>
            {t('login:form.back')}
          </Button>
        </Box>
      </Box>
    </FormContainer>
  );
};

const FormContainer = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '> div': {
    marginBottom: theme.spacing(3),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));
