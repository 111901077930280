type PathImpl<T, K extends keyof T> = K extends string
  ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
    T[K] extends Record<string, any>
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      T[K] extends ArrayLike<any>
      ? // TODO review types
        K | `${K}.${PathImpl<T[K], Exclude<keyof T[K], keyof any[]>>}`
      : K | `${K}.${PathImpl<T[K], keyof T[K]>}`
    : K
  : never;

type Path<T> = PathImpl<T, keyof T> | keyof T;

type PathValue<T, P extends Path<T>> = P extends `${infer K}.${infer Rest}`
  ? K extends keyof T
    ? Rest extends Path<T[K]>
      ? PathValue<T[K], Rest>
      : never
    : never
  : P extends keyof T
  ? T[P]
  : never;

export function get<T, P extends Path<T>>(obj: T, path: P): PathValue<T, P> {
  // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
  // Regex explained: https://regexr.com/58j0k
  const pathArray = (path as string).match(/([^[.\]])+/g);
  // Find value
  const result = pathArray?.reduce(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (prevObj, key) => prevObj && (prevObj as any)[key],
    obj
  );
  // If found value is undefined return default value; otherwise return the value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return result as any;
}
