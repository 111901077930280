import { bondsmithTheme } from '@b7hio/core-lib/theme';
import { createContext, useContext, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ThemeContext = createContext((theme: typeof bondsmithTheme) => {});

export const ThemeToggler: React.FC = () => {
  const setTheme = useContext(ThemeContext);

  const updateTheme = (theme: string | undefined | null) => {
    switch (theme) {
      default:
        setTheme(bondsmithTheme);
    }

    if (window?.localStorage && theme) {
      window.localStorage.setItem('theme', theme);
    }
  };

  useEffect(() => {
    let listener: any;
    let theme = '';

    if (!theme && window?.localStorage) {
      theme = window.localStorage.getItem('theme') || '';
    }

    updateTheme(theme);

    if (document) {
      listener = (e: KeyboardEvent) => {
        if (e.metaKey && e.key === 'b') {
          e.preventDefault();

          updateTheme(window.prompt('theme'));
        }
      };
      document.addEventListener('keydown', listener);
    }

    return () => {
      if (listener) {
        document.removeEventListener('keydown', listener);
      }
    };
  }, []);

  return null;
};
