import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { BondsmithUser } from '../../features/auth/hooks/useAuth';
import { MfaOptionsEnum } from './components/MfaOptions/MfaOptions';

export enum AuthStage {
  Initial = 'Initial',
  // Create New Password form
  CreateNewPassword = 'CREATE_NEW_PASSWORD',
  // Stage to ask if user wants to setup MFA
  MfaAsk = 'MFA_ASK',
  // Stage to choose authentication method (SMS or SMS + Auth App)
  MfaOptions = 'MFA_OPTIONS',
  // Stage to enter Mobile Number
  MfaSmsNumber = 'MFA_SMS_NUMBER',
  // Stage to enter SMS code
  MfaSmsCode = 'MFA_SMS_CODE',
  // Stage to enter QR code
  MfaAuthApp = 'MFA_AUTH_APP',
  // Stage for successful MFA setup
  MfaSuccess = 'MFA_SUCCESS',
  // Stage for MFA login option
  MfaLoginAsk = 'MFA_LOGIN_ASK',
  // Stage for MFA Login - SMS Option
  MfaLoginSms = 'MFA_LOGIN_SMS',
  // Stage for MFA Login - Auth App Option
  MfaLoginAuthApp = 'MFA_LOGIN_AUTH_APP',
  // Stage to ask if user wants to remove MFA method
  MfaRemoveAsk = 'MFA_REMOVE_ASK',
  // Stage to confirm user password before removing MFA method
  MfaRemoveConfirm = 'MFA_REMOVE_CONFIRM',
}

export interface AuthState {
  // Current stage of the auth flow
  readonly stage: AuthStage;
  // Cognito User object
  readonly user?: BondsmithUser;
  // MFA setup options (if 1 selected, SMS only, if 2 selected, SMS + Auth App)
  readonly mfaSetupOptions?: MfaOptionsEnum[];
  // MFA methods that user has set up (SMS or Auth App or both)
  readonly mfaUserMethodsAvailable?: MfaOptionsEnum[];
  // MFA method that user has selected to log in with at this time
  readonly mfaUserSelectedLoginMethod?: MfaOptionsEnum;
  // MFA secret that user generated to validate prepare to validate with Auth App
  readonly mfaSecret?: string;
  // MFA method that user wants to disable
  readonly mfaUserMethodToDisable?: MfaOptionsEnum;
  readonly passwordChangeReason?: string;
  // Mobile number that user has entered
  readonly phoneNumber?: string;
  // Last time user requested a new code
  readonly lastResubmit?: Date;
}

interface AuthChallengePayload {
  readonly user: BondsmithUser | any;
  readonly mfaSetupOptions?: MfaOptionsEnum[];
  readonly mfaUserMethodsAvailable?: MfaOptionsEnum[];
  readonly mfaUserSelectedLoginMethod?: MfaOptionsEnum;
  readonly mfaSecret?: string;
  readonly mfaUserMethodToDisable?: MfaOptionsEnum;
  readonly phoneNumber?: string;
  readonly lastResubmit?: Date;
}

export const initialState: AuthState = {
  stage: AuthStage.Initial,
};

export const authSlice = createSlice({
  name: 'authState',
  initialState,
  reducers: {
    authChallenge(
      state,
      {
        payload: {
          user,
          mfaSetupOptions,
          mfaUserMethodsAvailable,
          mfaUserSelectedLoginMethod,
          mfaUserMethodToDisable,
          mfaSecret,
          phoneNumber,
          lastResubmit,
        },
      }: PayloadAction<AuthChallengePayload>
    ) {
      switch (user.challengeName) {
        case AuthStage.CreateNewPassword:
          state.stage = AuthStage.CreateNewPassword;
          break;
        case AuthStage.MfaAsk:
          state.stage = AuthStage.MfaAsk;
          break;
        case AuthStage.MfaOptions:
          state.stage = AuthStage.MfaOptions;
          break;
        case AuthStage.MfaSmsNumber:
          state.stage = AuthStage.MfaSmsNumber;
          state.mfaSetupOptions = mfaSetupOptions;
          state.mfaSecret = mfaSecret;
          state.mfaUserMethodsAvailable = mfaUserMethodsAvailable;
          break;
        case AuthStage.MfaSmsCode:
          state.stage = AuthStage.MfaSmsCode;
          state.phoneNumber = phoneNumber;
          state.lastResubmit = lastResubmit;
          break;
        case AuthStage.MfaAuthApp:
          state.stage = AuthStage.MfaAuthApp;
          state.mfaSetupOptions = mfaSetupOptions;
          state.mfaSecret = mfaSecret;
          state.mfaUserMethodsAvailable = mfaUserMethodsAvailable;
          break;
        case AuthStage.MfaSuccess:
          state.stage = AuthStage.MfaSuccess;
          state.mfaUserMethodToDisable = mfaUserMethodToDisable;
          break;
        case AuthStage.MfaLoginAsk:
          state.stage = AuthStage.MfaLoginAsk;
          state.mfaUserSelectedLoginMethod = undefined;
          state.mfaUserMethodsAvailable =
            state.mfaUserMethodsAvailable &&
            state.mfaUserMethodsAvailable.length > 0
              ? state.mfaUserMethodsAvailable
              : mfaUserMethodsAvailable;
          break;
        case AuthStage.MfaLoginSms:
          state.stage = AuthStage.MfaLoginSms;
          state.phoneNumber = phoneNumber;
          state.lastResubmit = lastResubmit;
          state.mfaUserSelectedLoginMethod = mfaUserSelectedLoginMethod;
          break;
        case AuthStage.MfaLoginAuthApp:
          state.stage = AuthStage.MfaLoginAuthApp;
          state.mfaUserSelectedLoginMethod = mfaUserSelectedLoginMethod;
          break;
        case AuthStage.MfaRemoveAsk:
          state.stage = AuthStage.MfaRemoveAsk;
          state.mfaUserMethodToDisable = mfaUserMethodToDisable;
          break;
        case AuthStage.MfaRemoveConfirm:
          state.stage = AuthStage.MfaRemoveConfirm;
          state.mfaUserMethodToDisable = mfaUserMethodToDisable;
          state.user = user;
          break;
        default:
          state.stage = AuthStage.Initial;
          break;
      }
    },
    goToStage(
      state,
      {
        payload: {
          stage,
          user,
          mfaSetupOptions,
          mfaUserMethodsAvailable,
          mfaUserSelectedLoginMethod,
          mfaUserMethodToDisable,
          mfaSecret,
          phoneNumber,
          lastResubmit,
        },
      }: PayloadAction<AuthChallengePayload & { readonly stage: AuthStage }>
    ) {
      state.stage = stage;
    },
    sessionTimeout(state) {
      Object.assign(state, initialState);
    },
  },
});
