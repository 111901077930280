import { styled } from '../../stitches.config';

export const ListItem = styled('li', {
  padding: '$2 $3',
  variants: {
    small: {
      true: {
        padding: '0',
        lineHeight: '1.25rem',
      },
    },
  },
});

export const List = styled('ul', {
  listStyle: 'none',
  padding: 0,
  margin: '-$3',
  variants: {
    interactive: {
      true: {
        [`${ListItem}`]: {
          '&:hover': {
            backgroundColor: '$gray4 ',
          },
          '&.Mui-focused': {
            backgroundColor: '$gray5 ',
          },
        },
      },
    },
    passwordValidator: {
      true: {
        margin: '0',
        paddingInlineStart: '40px',
      },
    },
  },
});
