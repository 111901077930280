import { styled } from '../../stitches.config';

export const Panel = styled('div', {
  $$shadow: '$shadows$panel',
  $$border: 'none',
  padding: '$$padding',
  margin: '$$margin',
  background: '$colors$paperBg',
  border: '$$border',
  boxShadow: '$$shadow',
  borderRadius: '$2',
  variants: {
    noShadow: {
      true: {
        $$shadow: 'none',
      },
      false: {},
    },
    border: {
      true: {
        $$border: '$borders$divider',
      },
      false: {},
    },
    padding: {
      1: {
        $$padding: '$space$1',
      },
      2: {
        $$padding: '$space$2',
      },
      3: {
        $$padding: '$space$3',
      },
      4: {
        $$padding: '$space$4',
      },
    },

    margin: {
      1: {
        $$margin: '$space$1',
      },
      2: {
        $$margin: '$space$2',
      },
      3: {
        $$margin: '$space$3',
      },

      4: {
        $$margin: '$space$4',
      },
    },
  },
});
