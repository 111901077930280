import type { AlertProps } from '@mui/material';
import React, { useCallback, useRef } from 'react';
import { v4 } from 'uuid';
import { SnackbarPack, SnackbarPackRef } from './snackbar-pack';
import { SnackbarPackContext } from './snackbar-pack-context';
import {
  SnackbarMessage,
  SnackbarMessageOptionalKey,
  snackbarStackSlice,
} from './snackbar-pack-state';

export const SnackbarPackProvider: React.FC = ({ children }) => {
  const snackpackRef = useRef<SnackbarPackRef>();

  const enqueueSnackbar = useCallback(
    (
      options: SnackbarMessageOptionalKey | string,
      severity?: AlertProps['severity']
    ) => {
      const snackbar: SnackbarMessageOptionalKey =
        typeof options === 'string'
          ? {
              message: options,
              severity,
            }
          : options;

      snackbar.key = snackbar.key ?? v4();
      snackpackRef.current?.dispatch(
        snackbarStackSlice.actions.addItem(snackbar as SnackbarMessage)
      );

      return snackbar.key;
    },
    []
  );

  const closeSnackbar = useCallback((key: string) => {
    snackpackRef.current?.dispatch(snackbarStackSlice.actions.removeItem(key));
  }, []);

  return (
    <SnackbarPackContext.Provider value={{ enqueueSnackbar, closeSnackbar }}>
      {children}
      <SnackbarPack ref={snackpackRef} />
    </SnackbarPackContext.Provider>
  );
};
