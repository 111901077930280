import { Box, Button, styled } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';
import { FormTextField } from '../../../../components';
import { superstructResolver, FormFriendly } from '../../../../form';
import { CreateNewPasswordSubmitProps } from '../../auth.interface';
import {
  CreateNewPasswordFields,
  CreateNewPasswordValidation,
} from './CreateNewPasswordForm.validation';

interface Props {
  onSubmit: ({ password }: CreateNewPasswordSubmitProps) => Promise<void>;
  isSubmitting: boolean;
  onGoBack: () => void;
}

export const CreateNewPasswordForm = ({
  onSubmit,
  isSubmitting,
  onGoBack,
}: Props): JSX.Element => {
  const { t } = useTranslation(['login']);
  const { handleSubmit, control } = useForm<
    FormFriendly<CreateNewPasswordFields>
  >({
    resolver: superstructResolver(CreateNewPasswordValidation),
    defaultValues: {
      password: '',
      passwordConfirm: '',
    },
  });

  return (
    <FormContainer
      onSubmit={handleSubmit((values) =>
        onSubmit({ password: values.password })
      )}>
      <FormTextField
        fullWidth
        name="password"
        type="password"
        label={t('login:form.newPassword')}
        control={control}
        t={t}
      />
      <FormTextField
        fullWidth
        name="passwordConfirm"
        type="password"
        label={t('login:form.passwordConfirm')}
        control={control}
        t={t}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1rem',
          justifyContent: 'center',
        }}>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={isSubmitting}>
          {t('login:form.confirm')}
        </Button>
        <Button
          color="primary"
          variant="text"
          disabled={isSubmitting}
          onClick={onGoBack}>
          {t('login:form.back')}
        </Button>
      </Box>
    </FormContainer>
  );
};

const FormContainer = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '> div': {
    marginBottom: theme.spacing(3),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));
