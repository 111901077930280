// TODO Review
import { colorsHsla } from '@b7hio/007/src/theme/colors';
import type { Theme } from '@emotion/react';
import { styled, useTheme } from '@mui/material';
import type { SxProps } from '@mui/system';
import React from 'react';

export interface AidLogoProps {
  readonly variant?: 'primary' | 'white';
  readonly iconOnly?: boolean;
  readonly sx?: SxProps<Theme>;
  readonly viewBox: string;
}

export const AidLogo: React.FC<AidLogoProps> = ({ variant, sx, iconOnly, viewBox }) => {
  const theme = useTheme();
  const fillColor =
    variant === 'primary' ? colorsHsla('brand9') : 'hsla(205, 65%, 94%, 1)';

  if (theme.mixins.logoUrl) {
    return (
      <Container sx={sx} data-testid="logo">
        <img src={theme.mixins.logoUrl} alt="logo" width="100%" height="auto" />
      </Container>
    );
  }

  if (iconOnly) {
    return (
      <Container sx={sx} data-testid="SavingsAidLogoSquare">
        <Svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
          <g id="Group_8170" data-name="Group 8170">
            <path
              id="Path_7851"
              data-name="Path 7851"
              d="M46.634,52.85C41.8,54.519,32.4,56.766,23.8,53.1L10.61,65.578a9.383,9.383,0,0,0,4.345,1.065H49.994a10.088,10.088,0,0,0,1.822-.176Z"
              transform="translate(-5.487 -12.642)"
              fill={fillColor}
            />
            <path
              id="Path_7852"
              data-name="Path 7852"
              d="M49.025,4.74H13.959A9.46,9.46,0,0,0,4.5,14.2V49.268a9.062,9.062,0,0,0,.109,1.355L15.79,41.375a13.576,13.576,0,0,1,14.987-22.36l.385.192-2.818,5.285-.36-.167A7.59,7.59,0,0,0,17.5,33.4a7.452,7.452,0,0,0,3.011,4.072L39.85,21.489a3.35,3.35,0,0,1,4.34,1.7L57.5,53.475a9.47,9.47,0,0,0,1-4.206V14.2A9.46,9.46,0,0,0,49.041,4.74Z"
              transform="translate(-4.5 -4.74)"
              fill={fillColor}
            />
            <path
              id="Path_7853"
              data-name="Path 7853"
              d="M47.745,43.544,44.483,35,31.94,46.109l.1.034a27.587,27.587,0,0,0,4.318.335A21.819,21.819,0,0,0,47.745,43.544Z"
              transform="translate(-9.016 -9.706)"
              fill={fillColor}
            />
          </g>
        </Svg>
      </Container>
    );
  }

  return (
    <Container sx={sx} data-testid="SavingsAidLogo">
      <Svg viewBox="0 0 690 123" focusable="false">
        <g id="b" transform="translate(-10.41 -8.83)">
          <g
            id="Group_8164"
            data-name="Group 8164"
            transform="translate(10.41 8.83)">
            <g id="Group_8162" data-name="Group 8162">
              <rect
                id="Rectangle_6106"
                data-name="Rectangle 6106"
                width="35.046"
                height="35.046"
                rx="11.07"
                fill="#fff"
              />
              <g
                id="Group_8161"
                data-name="Group 8161"
                transform="translate(0 0)">
                <path
                  id="Path_7827"
                  data-name="Path 7827"
                  d="M18.554,32.357l-.238-.213a8.614,8.614,0,0,1,9.507-14.188l-1.588,2.981a5.238,5.238,0,0,0-5.169,9.076l.169.113L33.98,19.581a1.9,1.9,0,0,1,2.481.969l8.563,19.483a5.97,5.97,0,0,0,.431-2.219V14.887A6.074,6.074,0,0,0,39.4,8.83H16.467a6.074,6.074,0,0,0-6.057,6.057V37.826A6.091,6.091,0,0,0,10.529,39Z"
                  transform="translate(-10.41 -8.83)"
                  fill="#4686c6"
                />
                <path
                  id="Path_7828"
                  data-name="Path 7828"
                  d="M34.2,41.626l.35.181.125.05a18.746,18.746,0,0,0,2.888.225,14.667,14.667,0,0,0,7.576-1.956l.194-.119-2.35-6.157L34.2,41.626Z"
                  transform="translate(-19.33 -18.211)"
                  fill="#4686c6"
                />
                <path
                  id="Path_7829"
                  data-name="Path 7829"
                  d="M39.737,49.68l-.25.088c-3.131,1.075-9.213,2.525-14.751.169l-.163-.069L15.46,58.456a5.991,5.991,0,0,0,2.9.744H41.3a5.942,5.942,0,0,0,1.95-.331Z"
                  transform="translate(-12.304 -24.147)"
                  fill="#4686c6"
                />
              </g>
            </g>
            <g
              id="Group_8163"
              data-name="Group 8163"
              transform="translate(48.341 10.588)">
              <path
                id="Path_7830"
                data-name="Path 7830"
                d="M90.563,44.5a7.711,7.711,0,0,1-2.813-1.438l1.056-2.375a8.385,8.385,0,0,0,2.481,1.306,9.011,9.011,0,0,0,2.95.506,5.062,5.062,0,0,0,2.738-.588,1.774,1.774,0,0,0,.906-1.55,1.5,1.5,0,0,0-.506-1.163,3.571,3.571,0,0,0-1.281-.719c-.519-.175-1.225-.369-2.119-.588A24.473,24.473,0,0,1,90.931,37a5.018,5.018,0,0,1-2-1.4,3.777,3.777,0,0,1-.838-2.575,4.4,4.4,0,0,1,.744-2.481,4.994,4.994,0,0,1,2.238-1.775,9.065,9.065,0,0,1,3.656-.656,11.809,11.809,0,0,1,2.963.375,8.2,8.2,0,0,1,2.519,1.081l-.963,2.375a9.4,9.4,0,0,0-2.256-.963,8.462,8.462,0,0,0-2.281-.331,4.781,4.781,0,0,0-2.694.613,1.888,1.888,0,0,0-.881,1.625,1.458,1.458,0,0,0,.506,1.15,3.65,3.65,0,0,0,1.281.706c.519.175,1.225.369,2.119.588a22.177,22.177,0,0,1,3.013.881,5.208,5.208,0,0,1,2.013,1.4,3.687,3.687,0,0,1,.838,2.538,4.333,4.333,0,0,1-.744,2.469,5.034,5.034,0,0,1-2.244,1.763,9.17,9.17,0,0,1-3.669.656,12.5,12.5,0,0,1-3.682-.544Z"
                transform="translate(-87.75 -26.647)"
                fill="#303953"
              />
              <path
                id="Path_7831"
                data-name="Path 7831"
                d="M121.694,35.828a5.14,5.14,0,0,1,1.469,4.013V47.2h-2.775V45.672a3.4,3.4,0,0,1-1.544,1.256,6.073,6.073,0,0,1-2.413.438,5.938,5.938,0,0,1-2.469-.481,3.809,3.809,0,0,1-1.638-1.344,3.385,3.385,0,0,1-.575-1.944,3.328,3.328,0,0,1,1.256-2.719,6.256,6.256,0,0,1,3.963-1.025h3.244v-.188a2.582,2.582,0,0,0-.788-2.025,3.422,3.422,0,0,0-2.344-.706,6.772,6.772,0,0,0-2.081.331,5.136,5.136,0,0,0-1.731.919l-1.15-2.144a7.067,7.067,0,0,1,2.375-1.15,10.49,10.49,0,0,1,2.938-.4,6.115,6.115,0,0,1,4.244,1.331Zm-2.681,8.894a2.756,2.756,0,0,0,1.213-1.444V41.822h-3.031q-2.541,0-2.538,1.669a1.5,1.5,0,0,0,.638,1.269,2.923,2.923,0,0,0,1.763.469,3.746,3.746,0,0,0,1.963-.506Z"
                transform="translate(-96.749 -29.04)"
                fill="#303953"
              />
              <path
                id="Path_7832"
                data-name="Path 7832"
                d="M146.2,34.73l-5.388,12.563h-3.031L132.39,34.73h3.056l3.907,9.313,4.025-9.313H146.2Z"
                transform="translate(-104.488 -29.13)"
                fill="#303953"
              />
              <path
                id="Path_7833"
                data-name="Path 7833"
                d="M157.314,28.789a1.723,1.723,0,0,1,0-2.513,1.89,1.89,0,0,1,1.344-.506,1.957,1.957,0,0,1,1.344.481,1.547,1.547,0,0,1,.544,1.213,1.768,1.768,0,0,1-.531,1.306,1.828,1.828,0,0,1-1.35.531,1.88,1.88,0,0,1-1.344-.506Zm-.144,2.575h2.938V43.928H157.17Z"
                transform="translate(-113.63 -25.77)"
                fill="#303953"
              />
              <path
                id="Path_7834"
                data-name="Path 7834"
                d="M179.232,35.888a5.482,5.482,0,0,1,1.438,4.119v7.2h-2.938V40.388a3.515,3.515,0,0,0-.775-2.481,2.879,2.879,0,0,0-2.213-.838,3.391,3.391,0,0,0-2.563.975,3.9,3.9,0,0,0-.944,2.813v6.35H168.3V34.644h2.8v1.625a4.626,4.626,0,0,1,1.838-1.319,6.568,6.568,0,0,1,2.494-.45,5.258,5.258,0,0,1,3.813,1.388Z"
                transform="translate(-117.953 -29.043)"
                fill="#303953"
              />
              <path
                id="Path_7835"
                data-name="Path 7835"
                d="M206.107,34.648V45.3q0,6.638-6.775,6.632a12.535,12.535,0,0,1-3.432-.456,7.289,7.289,0,0,1-2.681-1.331l1.319-2.213a6.3,6.3,0,0,0,2.081,1.094,8.127,8.127,0,0,0,2.55.413,4.2,4.2,0,0,0,3.038-.944,3.834,3.834,0,0,0,.963-2.869v-.656a4.772,4.772,0,0,1-1.838,1.244,6.473,6.473,0,0,1-2.375.425,6.873,6.873,0,0,1-3.238-.763,5.768,5.768,0,0,1-2.281-2.15,6.037,6.037,0,0,1-.838-3.175,5.92,5.92,0,0,1,.838-3.163,5.722,5.722,0,0,1,2.281-2.131,6.924,6.924,0,0,1,3.238-.75,6.594,6.594,0,0,1,2.481.45,4.576,4.576,0,0,1,1.869,1.363V34.654h2.8Zm-3.963,8.507a3.4,3.4,0,0,0,1.069-2.6,3.3,3.3,0,0,0-1.069-2.563A3.885,3.885,0,0,0,199.407,37a3.948,3.948,0,0,0-2.763.988,3.326,3.326,0,0,0-1.069,2.563,3.426,3.426,0,0,0,1.069,2.6,3.879,3.879,0,0,0,2.763,1A3.818,3.818,0,0,0,202.144,43.154Z"
                transform="translate(-127.064 -29.047)"
                fill="#303953"
              />
              <path
                id="Path_7836"
                data-name="Path 7836"
                d="M220.411,46.97a7.6,7.6,0,0,1-2.331-1.013l1.131-2.238a7.2,7.2,0,0,0,2.038.906,8.58,8.58,0,0,0,2.344.344q2.635,0,2.638-1.388a.934.934,0,0,0-.669-.919,11.369,11.369,0,0,0-2.15-.494,16.982,16.982,0,0,1-2.531-.544,3.954,3.954,0,0,1-1.694-1.069,2.983,2.983,0,0,1-.719-2.131,3.345,3.345,0,0,1,1.494-2.856,6.792,6.792,0,0,1,4.032-1.069,11.778,11.778,0,0,1,2.588.294,7.162,7.162,0,0,1,2.119.788l-1.131,2.238a7,7,0,0,0-3.6-.944,4,4,0,0,0-1.963.388,1.149,1.149,0,0,0-.669,1.025,1.02,1.02,0,0,0,.719,1,11.248,11.248,0,0,0,2.225.55,16.651,16.651,0,0,1,2.469.544,3.868,3.868,0,0,1,1.656,1.038,2.885,2.885,0,0,1,.694,2.069,3.285,3.285,0,0,1-1.531,2.825,7.2,7.2,0,0,1-4.163,1.056,11.178,11.178,0,0,1-2.988-.4Z"
                transform="translate(-136.618 -29.043)"
                fill="#303953"
              />
              <path
                id="Path_7837"
                data-name="Path 7837"
                d="M249.5,41.137h-8.232L239.64,44.95h-3.15L243.9,28.48h3.013l7.432,16.47h-3.2L249.5,41.137Zm-1.013-2.4-3.106-7.2-3.081,7.2Z"
                transform="translate(-143.521 -26.786)"
                fill="#4686c6"
              />
              <path
                id="Path_7838"
                data-name="Path 7838"
                d="M267.854,28.789a1.723,1.723,0,0,1,0-2.513,1.891,1.891,0,0,1,1.344-.506,1.957,1.957,0,0,1,1.344.481,1.547,1.547,0,0,1,.544,1.213,1.768,1.768,0,0,1-.531,1.306,1.828,1.828,0,0,1-1.35.531,1.88,1.88,0,0,1-1.344-.506Zm-.144,2.575h2.938V43.928H267.71Z"
                transform="translate(-155.077 -25.77)"
                fill="#4686c6"
              />
              <path
                id="Path_7839"
                data-name="Path 7839"
                d="M290.508,26.9V44.357h-2.825V42.732a4.616,4.616,0,0,1-1.8,1.344,6.03,6.03,0,0,1-2.363.45,6.633,6.633,0,0,1-3.257-.8A5.741,5.741,0,0,1,278,41.457a6.785,6.785,0,0,1-.825-3.375A6.7,6.7,0,0,1,278,34.719a5.75,5.75,0,0,1,2.269-2.256,6.633,6.633,0,0,1,3.257-.8,5.98,5.98,0,0,1,2.281.425,4.62,4.62,0,0,1,1.763,1.269V26.913H290.5Zm-4.725,14.62a3.512,3.512,0,0,0,1.344-1.375,4.581,4.581,0,0,0,0-4.138,3.471,3.471,0,0,0-1.344-1.375,4.016,4.016,0,0,0-3.813,0,3.512,3.512,0,0,0-1.344,1.375,4.581,4.581,0,0,0,0,4.138,3.471,3.471,0,0,0,1.344,1.375,4.016,4.016,0,0,0,3.813,0Z"
                transform="translate(-158.774 -26.194)"
                fill="#4686c6"
              />
              <path
                id="Path_7840"
                data-name="Path 7840"
                d="M315.821,41.863h-9.857a3.449,3.449,0,0,0,1.35,2.2,4.416,4.416,0,0,0,2.719.813,4.592,4.592,0,0,0,3.413-1.363l1.575,1.813a5.353,5.353,0,0,1-2.144,1.531,7.835,7.835,0,0,1-2.919.519,7.735,7.735,0,0,1-3.644-.825,5.958,5.958,0,0,1-2.438-2.294,6.927,6.927,0,0,1-.019-6.638,5.929,5.929,0,0,1,2.319-2.294,6.742,6.742,0,0,1,3.338-.825,6.52,6.52,0,0,1,3.282.813,5.742,5.742,0,0,1,2.256,2.281,6.892,6.892,0,0,1,.813,3.4c0,.206-.019.494-.05.869Zm-8.694-4.175a3.533,3.533,0,0,0-1.188,2.225h7.125a3.658,3.658,0,0,0-5.938-2.219Z"
                transform="translate(-168.467 -29.043)"
                fill="#4686c6"
              />
            </g>
          </g>
        </g>
      </Svg>
    </Container>
  );
};

const Svg = styled('svg')({
  width: '100%',
  height: '100%',
});

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
});
