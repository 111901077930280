import { isRouteActive } from '@b7hio/core-lib/utils';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Tooltip,
} from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

export interface NavItemProps {
  name: string;
  route: string;
  children?: NavItemProps[];
  icon?: React.ReactNode;
  open?: boolean;
}

export const NavItem: React.FC<NavItemProps> = ({
  name,
  route,
  children,
  icon,
  open: navBarOpen, // eslint-disable-line @typescript-eslint/no-unused-vars
}: NavItemProps) => {
  const router = useRouter();

  const isActive = isRouteActive(
    route,
    router.pathname,
    route === '/' || (children?.length ?? 0) > 0
  );
  const isParentOpen = isRouteActive(route, router.pathname, route === '/');

  const [open, setOpen] = useState(isParentOpen);

  const handleToggle = () => {
    setOpen((value) => !value);
  };

  const button = (
    <ListItemButton
      sx={{ m: 1, borderRadius: 1 }}
      component="a"
      selected={isActive}
      onClick={children && handleToggle}>
      <NavIcon>{icon}</NavIcon>
      <ListItemText
        primary={name}
        primaryTypographyProps={{
          variant: 'body1',
          color: 'primary',
          sx: { fontWeight: 600 },
        }}
      />
      {children && (open ? <ExpandLess /> : <ExpandMore />)}
    </ListItemButton>
  );

  if (!children) {
    return (
      <Link href={route} passHref>
        {button}
      </Link>
    );
  }

  return (
    <>
      {open ? button : <Tooltip title={name}>{button}</Tooltip>}
      {children.map((navigationItem) => (
        <Collapse
          key={navigationItem.route}
          in={open}
          timeout="auto"
          unmountOnExit>
          <List component="div" disablePadding>
            <NavItem {...navigationItem} />
          </List>
        </Collapse>
      ))}
    </>
  );
};

const NavIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));
