import { keyframes } from '@stitches/react';
import React from 'react';
import { Icon } from '../../icon';
import { styled } from '../../stitches.config';

const fadeOut = keyframes({
  from: { opacity: 1 },
  to: { opacity: 0, display: 'none' },
});

const AlertWrapper = styled('div', {
  display: 'flex',
  gap: '$3',
  alignItems: 'center',
  color: '$$textColor',
  backgroundColor: '$$backgroundColor',
  fontSize: '$1',
  fontWeight: '$2',
  borderWidth: '$1',
  borderStyle: '$2',
  borderRadius: '$1',
  padding: '$2 $3',
  margin: '$3 $2',
  variants: {
    alertType: {
      success: {
        $$backgroundColor: '$colors$green3',
        $$textColor: '$colors$green11',
      },
      fail: { $$backgroundColor: '$colors$red3', $$textColor: '$colors$red11' },
      info: {
        $$backgroundColor: '$colors$blue3',
        $$textColor: '$colors$blue11',
      },
      warning: {
        $$backgroundColor: '$colors$yellow3',
        $$textColor: '$colors$yellow11',
      },
      primary: {
        $$backgroundColor: '$colors$info',
        $$textColor: '$colors$loContrast',
      },
      primaryLight: {
        $$backgroundColor: '$colors$brand3',
        $$textColor: '$colors$brand11',
      },
    },
    timeOut: {
      true: {
        animation: `${fadeOut} 1s ease 7s forwards`,
      },
      false: {},
    },
    widthStyle: {
      fitContent: {
        width: 'fit-content',
      },
    },
  },
});

export const FontAwesomeWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$$iconColor',
  variants: {
    alertType: {
      success: {
        $$iconColor: '$colors$green11',
      },
      fail: { $$iconColor: '$colors$red11' },
      info: {
        $$iconColor: '$colors$blue11',
      },
      warning: {
        $$iconColor: '$colors$yellow11',
      },
      primary: {
        $$iconColor: '$colors$loContrast',
      },
    },

    timeOut: {
      true: {
        animation: `${fadeOut} 1s ease 7s forwards`,
      },
      false: {},
    },
  },
});

interface Props {
  readonly color: 'success' | 'fail' | 'info' | 'warning' | 'primary';
  readonly timeOut?: boolean;
  readonly widthStyle?: 'fitContent';
}

export const Alert: React.FC<Props> = ({
  color,
  children,
  timeOut,
  widthStyle,
}) => {
  return (
    <AlertWrapper alertType={color} timeOut={timeOut} widthStyle={widthStyle}>
      <FontAwesomeWrapper alertType={color} timeOut={timeOut}>
        <Icon name="InfoOutlined" color="currentColor" />
      </FontAwesomeWrapper>
      {children}
    </AlertWrapper>
  );
};
